import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { commentQueries } from 'hooks/backend/comments';
import { Question, SurveyVariable } from '../../types/domainModels';
import { questionBlockQueries } from 'hooks/backend/questionBlocks';
import { questionQueries, useReorderQuestions } from 'hooks/backend/questions';
import { showErrorMessage } from '../../util/notifications';
import { SurveyFlowStep, SurveyFlowSteps } from '../../types/internal';
import { surveyFlowMachine } from './machines/surveyFlow';
import { surveyQueries } from 'hooks/backend/surveys';
import { useRouteBlocker } from 'contexts/routeBlocker';
import { variableQueries } from 'hooks/backend/surveyVariables';

import AudienceStep from './AudienceStep';
import Comments from './Comments';
import CreateQuestionBlocks from './CreateQuestionBlocks';
import ErrorDisplay from '../common/ErrorDisplay';
import SurveyBuildWorkspaceSidebar, {
  MenuName,
} from '../common/SurveyBuildWorkspaceSidebar';
import OverviewStep from './OverviewStep';
import QuestionsStep from './QuestionsStep';
import ReviewStep from './ReviewStep';
import SidebarNavBuild from './SidebarNavBuild';
import SurveyVariablesPage from './SurveyVariablesPage';
import SurveyWithSidebar from 'components/layout/SurveyWithSidebar';
import CustomizeStep from './CustomizeStep';

const SurveyEditPage = () => {
  const {
    id: surveyId,
    resourceId,
    step: currentStep,
  } = useParams<{
    id: string;
    resourceId?: string;
    step: SurveyFlowStep;
  }>();

  if (!surveyId) {
    return (
      <div className="p-6">
        <ErrorDisplay message="Missing URL variables for editing a survey." />
      </div>
    );
  }

  return (
    <SurveyEditPageLoaded
      currentStep={currentStep}
      resourceId={resourceId}
      surveyId={Number(surveyId)}
    />
  );
};

const SurveyEditPageLoaded = ({
  currentStep,
  resourceId,
  surveyId,
}: {
  currentStep?: SurveyFlowStep;
  resourceId?: string;
  surveyId: number;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const questionId =
    currentStep === SurveyFlowSteps.questions ? resourceId : undefined;
  const variableId =
    currentStep === SurveyFlowSteps.variables ? resourceId : undefined;

  const {
    data: survey,
    error: loadSurveyError,
    isError: hasLoadSurveyError,
    isLoading: isLoadingSurvey,
  } = useQuery(surveyQueries.survey({ surveyId }));

  const {
    data: questions = [],
    error: getQuestionsError,
    isLoadingError: hasLoadQuestionsError,
    isLoading: isLoadingQuestions,
  } = useQuery({
    ...questionQueries.forSurvey({ surveyId }),
    refetchOnWindowFocus: false,
  });
  const question = questions.find(({ id }) => `${id}` === questionId);

  const {
    data: variables = [],
    error: getVariablesError,
    isLoadingError: hasLoadVariablesError,
    isLoading: isLoadingVariables,
  } = useQuery({
    ...variableQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });
  const variable = variables.find(({ id }) => `${id}` === variableId);

  const {
    data: questionBlocks = [],
    error: getQuestionBlocksError,
    isLoadingError: hasLoadQuestionBlocksError,
    isLoading: isLoadingQuestionBlocks,
  } = useQuery({
    ...questionBlockQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });

  const { data: questionComments = [] } = useQuery(
    commentQueries.questions({ surveyId }),
  );
  const { data: surveyComments = [] } = useQuery(
    commentQueries.survey({ surveyId }),
  );
  const comments = [...questionComments, ...surveyComments];

  const { mutate: reorderQuestions } = useReorderQuestions({
    onError: (err) => {
      showErrorMessage(
        `There was an error adjusting the order of the questions. Error: ${err.message}`,
      );
    },
  });

  const { cancelBlock, isBlocked, performRedirect, shouldBlock } =
    useRouteBlocker();

  const [surveyFlowState, surveyFlowSend] = useMachine(
    surveyFlowMachine.provide({
      actions: {
        cancelBlock: () => {
          cancelBlock();
        },
        navigateToBlocked: () => {
          performRedirect();
        },
        navigateToNewQuestion: () => {
          navigate(`/surveys/${surveyId}/build/questions`);
        },
        navigateToSpecificResource: (_, params) => {
          if (currentStep === SurveyFlowSteps.questions) {
            const resourceId = params.newResourceId
              ? `/${params.newResourceId}`
              : '';

            navigate(`/surveys/${surveyId}/build/questions${resourceId}`);
          } else if (currentStep === SurveyFlowSteps.variables) {
            const resourceId = params.newResourceId
              ? `/${params.newResourceId}`
              : '';

            navigate(`/surveys/${surveyId}/build/variables${resourceId}`);
          }
        },
        shouldBlock: () => {
          shouldBlock();
        },
        updateRouteForLaunched: () => {
          navigate(`/surveys/${surveyId}/analyze`);
        },
        updateRouteForNewVariable: () => {
          navigate(`/surveys/${surveyId}/build/variables`);
        },
        updateRouteForNewBlock: () => {
          navigate(`/surveys/${surveyId}/build/question-blocks`);
        },
        updateRouteForStep: (_, params) => {
          const surveyIdToUse = params.surveyId ?? surveyId;

          let questionIdPart = '';
          if (
            params.step === SurveyFlowSteps.questions &&
            questions.filter((q) => !q.isDemographic).length > 0
          ) {
            questionIdPart = `/${questions[0].id}`;
          }

          navigate(
            `/surveys/${surveyIdToUse}/build/${params.step}${questionIdPart}`,
          );
        },
      },
      guards: {
        shouldNavigateAfterSave: (_, params) => {
          if (
            currentStep === SurveyFlowSteps.questions ||
            currentStep === SurveyFlowSteps.variables
          ) {
            return !params.existingResourceId;
          }

          return false;
        },
      },
    }),
  );
  const { formDataToDuplicate } = surveyFlowState.context;
  const isShowingUnsavedChanges = surveyFlowState.matches('unsavedChanges');

  const onDiscardChanges = useCallback(() => {
    surveyFlowSend({ type: 'DISCARD_CHANGES' });
  }, [surveyFlowSend]);

  const onDismissUnsavedChanges = useCallback(() => {
    surveyFlowSend({ type: 'DISMISS_UNSAVED_CHANGES' });
  }, [surveyFlowSend]);

  const onFormDirtyChanged = useCallback(
    (isDirty: boolean) => {
      if (isDirty) {
        surveyFlowSend({ type: 'FORM_DIRTY' });
      } else {
        surveyFlowSend({ type: 'FORM_NOT_DIRTY' });
      }
    },
    [surveyFlowSend],
  );

  const onHasFormError = useCallback(() => {
    surveyFlowSend({ type: 'HAS_FORM_ERROR' });
  }, [surveyFlowSend]);

  useEffect(() => {
    if (isBlocked) {
      surveyFlowSend({ type: 'BLOCKED_NAVIGATION' });
    }
  }, [isBlocked, surveyFlowSend]);

  const [openMenus, setOpenMenus] = useState<Record<MenuName, boolean>>({
    demos: true,
    questionBlocks: true,
    questions: true,
    variables: true,
  });

  function onMenuToggled(menuName: MenuName) {
    setOpenMenus((openMenus) => {
      return {
        ...openMenus,
        [menuName]: !openMenus[menuName],
      };
    });
  }

  if (hasLoadSurveyError) {
    return (
      <SurveyWithSidebar sidebarNav={<SidebarNavBuild surveyId={surveyId} />}>
        <div className="pt-6">
          <ErrorDisplay
            message={`Failed to load survey. Error: ${loadSurveyError?.message}`}
          />
        </div>
      </SurveyWithSidebar>
    );
  }

  const demographicQuestions = questions.filter((q) => q.isDemographic);
  const questionsSidebar = (
    <SurveyBuildWorkspaceSidebar
      comments={comments}
      curQuestion={question}
      curVariable={variable}
      demographicQuestions={demographicQuestions}
      onBlockCloned={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
        queryClient.invalidateQueries(questionBlockQueries.list({ surveyId }));
      }}
      onClickNewBlock={() => {
        surveyFlowSend({ type: 'enterNewBlock' });
      }}
      onClickNewQuestion={() => {
        surveyFlowSend({ type: 'enterNewQuestion' });
      }}
      onClickNewVariable={() => {
        surveyFlowSend({ type: 'enterNewVariable' });
      }}
      onMenuToggled={onMenuToggled}
      onQuestionsReordered={(newQuestions) => {
        queryClient.setQueryData(
          questionQueries.forSurvey({ surveyId }).queryKey,
          (oldData) => {
            if (!oldData) {
              return;
            }

            return [...demographicQuestions, ...newQuestions];
          },
        );

        reorderQuestions({
          data: newQuestions.map((q) => {
            return {
              blockId: q.blockId,
              id: q.id,
              monadicId: q.monadicId ?? null,
              sort: q.sort,
            };
          }),
          surveyId,
        });
      }}
      onTemplateQuestionsAdded={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
      }}
      openMenus={openMenus}
      questionBlocks={questionBlocks}
      questions={questions?.filter((q) => !q.isDemographic)}
      survey={survey}
      variables={variables}
    />
  );

  return (
    <SurveyWithSidebar
      sidebarNav={<SidebarNavBuild surveyId={surveyId} />}
      sidebarRight={
        currentStep === SurveyFlowSteps.variables ? null : (
          <Comments
            comments={comments}
            questionId={question?.id}
            surveyId={surveyId}
          />
        )
      }
      sidebarWorkspace={
        currentStep === SurveyFlowSteps.questions ||
        currentStep === SurveyFlowSteps.questionBlocks ||
        currentStep === SurveyFlowSteps.variables
          ? questionsSidebar
          : null
      }
    >
      {currentStep === SurveyFlowSteps.overview ? (
        <OverviewStep
          isLoadingSurvey={isLoadingSurvey}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasFormError}
          onOverviewDirtyChanged={onFormDirtyChanged}
          onOverviewSaved={() => {
            surveyFlowSend({ type: 'RESOURCE_SAVED' });
          }}
          questions={questions}
          survey={survey}
        />
      ) : currentStep === SurveyFlowSteps.audience ? (
        <AudienceStep
          demographicQuestions={demographicQuestions}
          isLoadingSurvey={isLoadingSurvey}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onAudienceSaved={() => {
            surveyFlowSend({ type: 'RESOURCE_SAVED' });
          }}
          onDirtyChanged={onFormDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasFormError}
          survey={survey}
        />
      ) : currentStep === SurveyFlowSteps.variables ? (
        <SurveyVariablesPage
          key={variable?.id || 'newVariable'}
          demographicQuestions={demographicQuestions}
          isLoadingDemographicQuestions={false}
          isLoadingQuestions={isLoadingQuestions}
          isLoadingSurvey={isLoadingSurvey}
          isLoadingVariables={isLoadingVariables}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          loadVariablesError={
            // Errors that occur after loading are handled on an individual basis (e.g. showing
            // an error toast or something else depending on the context).
            hasLoadVariablesError && getVariablesError instanceof Error
              ? getVariablesError
              : null
          }
          onDirtyChanged={onFormDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasFormError}
          onVariableDeleted={() => {
            const currentVariableIndex = variables.findIndex((variable) => {
              return `${variable.id}` === variableId;
            });

            let nextVariable: SurveyVariable | null = null;
            if (currentVariableIndex !== -1) {
              // Try to use the previous question for the next question to show but if there is no
              // previous question (e.g. the user deleted the first question), try to use the next
              // question.
              nextVariable =
                variables[currentVariableIndex - 1] ??
                variables[currentVariableIndex + 1] ??
                null;
            }

            surveyFlowSend({
              newResourceId: nextVariable?.id,
              type: 'RESOURCE_DELETED',
            });
          }}
          onVariableSaved={(data) => {
            surveyFlowSend({
              existingResourceId: variable?.id,
              newResourceId: data.id,
              type: 'RESOURCE_SAVED',
            });
          }}
          questions={questions}
          questionsSidebar={questionsSidebar}
          survey={survey}
          surveyId={surveyId}
          variable={variable}
        />
      ) : currentStep === SurveyFlowSteps.questions ? (
        <QuestionsStep
          key={question?.id || 'newQuestion'}
          initialFormData={formDataToDuplicate}
          isLoadingQuestions={isLoadingQuestions}
          isLoadingSurvey={isLoadingSurvey}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          loadQuestionsError={
            // Errors that occur after loading are handled on an individual basis (e.g. showing
            // an error toast or something else depending on the context).
            hasLoadQuestionsError && getQuestionsError instanceof Error
              ? getQuestionsError
              : null
          }
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onDuplicate={(formData) => {
            surveyFlowSend({ formData, type: 'duplicateQuestion' });
          }}
          onHasError={onHasFormError}
          onQuestionDeleted={() => {
            const currentQuestionIndex = questions.findIndex((question) => {
              return `${question.id}` === questionId;
            });

            let nextQuestion: Question | null = null;
            if (currentQuestionIndex !== -1) {
              // Try to use the previous question for the next question to show but if there is no
              // previous question (e.g. the user deleted the first question), try to use the next
              // question.
              nextQuestion =
                questions[currentQuestionIndex - 1] ??
                questions[currentQuestionIndex + 1] ??
                null;
            }

            surveyFlowSend({
              newResourceId: nextQuestion?.id,
              type: 'RESOURCE_DELETED',
            });
          }}
          onQuestionDirtyChanged={onFormDirtyChanged}
          onQuestionSaved={(data) => {
            surveyFlowSend({
              existingResourceId: question?.id,
              newResourceId: data.id,
              type: 'RESOURCE_SAVED',
            });
          }}
          question={question}
          questionBlocks={questionBlocks}
          questions={questions}
          survey={survey}
          variables={variables}
        />
      ) : currentStep === SurveyFlowSteps.questionBlocks ? (
        <CreateQuestionBlocks
          isLoadingDemographicQuestions={false}
          isLoadingQuestionBlocks={isLoadingQuestionBlocks}
          isLoadingQuestions={isLoadingQuestions}
          isLoadingSurvey={isLoadingSurvey}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          loadQuestionBlocksError={
            // Errors that occur after loading are handled on an individual basis (e.g. showing
            // an error toast or something else depending on the context).
            hasLoadQuestionBlocksError &&
            getQuestionBlocksError instanceof Error
              ? getQuestionBlocksError
              : null
          }
          onBlockDirtyChanged={onFormDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasFormError}
          onQuestionBlockDeleted={() => {
            queryClient.invalidateQueries(
              questionQueries.forSurvey({ surveyId }),
            );
            queryClient.invalidateQueries(
              questionBlockQueries.list({ surveyId }),
            );
          }}
          onQuestionBlocksSaved={() => {
            queryClient.invalidateQueries(
              questionQueries.forSurvey({ surveyId }),
            );
            queryClient.invalidateQueries(
              questionBlockQueries.list({ surveyId }),
            );

            surveyFlowSend({ type: 'RESOURCE_SAVED' });
          }}
          questionBlocks={questionBlocks}
          questions={questions}
          survey={survey}
          surveyId={surveyId}
        />
      ) : currentStep === SurveyFlowSteps.customize ? (
        <CustomizeStep
          isLoadingSurvey={isLoadingSurvey}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onCustomizeDirtyChanged={onFormDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasFormError}
          onSurveySaved={() => {
            surveyFlowSend({ type: 'RESOURCE_SAVED' });
          }}
          survey={survey}
        />
      ) : currentStep === SurveyFlowSteps.review ? (
        <ReviewStep
          isLoadingSurvey={isLoadingSurvey}
          onHasError={onHasFormError}
          onStepCompleted={() => {
            surveyFlowSend({
              type: 'launched',
            });
          }}
          questions={questions}
          survey={survey}
          surveyId={surveyId}
          surveyVariables={variables}
        />
      ) : (
        <Navigate to={`/surveys/${surveyId}/build/overview`} />
      )}
    </SurveyWithSidebar>
  );
};

export default SurveyEditPage;
