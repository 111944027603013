import Icon from 'components/common/Icon';
import { Sidebar, SidebarPageLink } from 'components/layout/SurveyWithSidebar';

import { useHasRole } from 'hooks/users';

const SidebarNavAnalytics = ({ surveyId }: { surveyId: number }) => {
  const isAdmin = useHasRole('admin');

  return (
    <Sidebar
      links={
        <>
          <SidebarPageLink
            icon={<Icon id="bar-chart-10" />}
            to={`/surveys/${surveyId}/analyze`}
          >
            Analytics
          </SidebarPageLink>
          <SidebarPageLink
            icon={<Icon id="table" />}
            to={`/surveys/${surveyId}/analyze/summary-reports`}
          >
            Summary Reports
          </SidebarPageLink>
          <SidebarPageLink
            icon={<Icon id="table" />}
            to={`/surveys/${surveyId}/analyze/crosstab`}
          >
            Build Crosstab
          </SidebarPageLink>
          {isAdmin && (
            <SidebarPageLink
              icon={<Icon id="user-check-01" />}
              to={`/surveys/${surveyId}/analyze/manage-responses`}
            >
              Manage Responses
            </SidebarPageLink>
          )}
          <SidebarPageLink
            icon={<Icon id="download-cloud-01" />}
            to={`/surveys/${surveyId}/analyze/exports`}
          >
            Exports
          </SidebarPageLink>
        </>
      }
    />
  );
};

export default SidebarNavAnalytics;
