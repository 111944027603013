import { cloneDeep, forEach, compact, orderBy, sortBy } from 'lodash-es';
import { clsx } from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldArray, Form, Formik, useField, useFormikContext } from 'formik';
import { Fragment, ReactNode, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Banner as IBanner,
  ExportFilterModifier,
  QUESTION_TYPE,
  Question,
  QuestionConcept,
  Survey,
  SurveyVariable,
  SurveyWave,
} from '../../types/domainModels';
import {
  canCalculateAverage,
  canCalculateTopBottomBox,
  canChangeBase,
  ConstraintWithNumber as IConstraintWithNumber,
  ConstraintWithRange,
  ConstraintWithStatement as IConstraintWithStatement,
  ExportFilter as IExportFilter,
  ExportFormData,
  ExportFormDataValidated,
  formDataToApiExcelExportData,
  formDataToSaveExportData,
  getEmptyConstraintWithConcepts,
  getEmptyConstraintWithNumber,
  getEmptyConstraintWithOptions,
  getEmptyConstraintWithRange,
  getEmptyConstraintWithSegments,
  getEmptyConstraintWithStatement,
  getEmptyConstraintWithWaves,
  getEmptyExportFilter,
  getFormDataFromExistingExport,
  getInitialExportFormData,
  HardcodedWavesQuestion,
  isSurveyVariableFilterQuestion,
  isWaveFilterQuestion,
  MODIFIER_OPTION_WITHIN,
  MODIFIER_OPTIONS_DEFAULT,
  QUESTION_BASE_OPTIONS,
  STAT_TESTING_OPTIONS,
  validateExportData,
} from '../../util/exports';
import {
  generateQuestionsSection,
  isIdeaPresenterQuestion,
  partitionQuestionsForDemographics,
} from '../../util/questions';
import {
  getActiveQuestionOptions,
  getConceptOptions,
  getOptionOptions,
  getQuestionOptions,
  getSurveyWaveOption,
} from '../../util/formOptions';
import {
  getSurveyVariableOption,
  getSurveyVariableSegmentOption,
} from '../../util/surveyVariables';
import { QuestionGroup } from '../../types/internal';
import { ReactSelectValue } from '../../types/forms';
import { savedCrosstabsQueries } from 'hooks/backend/savedCrosstabs';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { surveyQueries } from 'hooks/backend/surveys';
import {
  useCreateExcelExport,
  useDeleteSavedExport,
  useSaveExportBanners,
} from 'hooks/backend/exports';
import { variableQueries } from 'hooks/backend/surveyVariables';

import AddButton from '../common/forms/AddButton';
import AdjustmentsIcon from '../common/icons/AdjustmentsIcon';
import Badge from 'components/common/Badge';
import ButtonLoading from '../common/forms/ButtonLoading';
import Checkbox from 'components/common/forms/Checkbox';
import CrosstabQuickAdd from './CrosstabQuickAdd';
import DragIcon from '../common/icons/DragIcon';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Hyperlink from '../common/Hyperlink';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import InlineDeleteOverlay from '../common/InlineDeleteOverlay';
import Input from '../common/forms/Input';
import Listbox, {
  ListboxButton,
  ListboxOption,
} from 'components/common/Listbox';
import Popover from '../common/Popover';
import FormCheckbox from '../common/forms/FormCheckbox';
import Select from '../common/forms/Select';
import Tab from '../common/Tab';
import Tooltip from '../common/Tooltip';
import TrashIconWithConfirmation from '../common/TrashIconWithConfirmation';
import WordSeparator from '../common/WordSeparator';
import XButton from '../common/forms/XButton';

type ExportTab = 'banners' | 'exportFeatures';

const CrosstabBuilder = ({
  questions,
  survey,
  waves = [],
}: {
  questions: Question[];
  survey: Survey;
  waves?: SurveyWave[];
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState<ExportTab>('banners');
  const surveyId = survey.id;

  const questionsOptions = getQuestionOptions({
    includeInactiveQuestions: true,
    questions,
  });

  const { data: surveyVariables = [] } = useQuery(
    variableQueries.list({ surveyId }),
  );

  const [demographicQuestions, surveyQuestions] =
    partitionQuestionsForDemographics(questionsOptions);

  const orderedQuestions = compact([
    generateQuestionsSection({
      questions: getActiveQuestionOptions(demographicQuestions),
      title: 'Demographic Questions',
    }),
    generateQuestionsSection({
      questions: getActiveQuestionOptions(surveyQuestions),
      title: 'Survey Questions',
    }),
    {
      label: 'Variables',
      options: surveyVariables.map(getSurveyVariableOption),
    },
    // Every survey has at least one wave. This selection is only useful if a survey has
    // more than one wave.
    waves.length > 1
      ? {
          label: 'Waves',
          // Waves are slightly different than the other groups of things to choose from because
          // for waves we just display a hardcoded "Wave" option and then the user can add
          // specific waves via the constraints. For example, it would read like:
          // "'Waves' is either Wave 1, Wave 2, or Wave 3".
          options: [
            {
              label: 'Waves',
              value: {
                waves: waves.map((wave) => getSurveyWaveOption(wave)),
                type: 'Waves' as const,
              },
            },
          ],
        }
      : null,
  ]);

  const { isPending: isCreatingExcelExport, mutate: createExcelExport } =
    useCreateExcelExport({
      onError: (err) => {
        showErrorMessage(`Failed to generate export. Error: ${err.message}`);
      },
      onSuccess: () => {
        showSuccessMessage(
          <div>
            Your export has been requested. You can{' '}
            <a
              className="font-semibold underline text-green"
              href={`/surveys/${surveyId}/analyze/exports`}
              rel="noopener noreferrer"
              target="_blank"
            >
              download your file here
            </a>
            .
          </div>,
        );
      },
    });

  return (
    <Formik<ExportFormData>
      initialValues={getInitialExportFormData({ questions: questionsOptions })}
      onSubmit={(formData) => {
        return createExcelExport({
          data: formDataToApiExcelExportData({
            formData: formData as ExportFormDataValidated,
          }),
          surveyId,
        });
      }}
      validate={validateExportData}
      validateOnChange={false}
    >
      <Form>
        <div className="pt-6 sticky top-0 bg-white z-10">
          <ExportModalHeader
            activeTab={activeTab}
            isCreatingExcelExport={isCreatingExcelExport}
            onClickTab={setActiveTab}
            questions={questionsOptions}
            survey={survey}
            surveyVariables={surveyVariables}
            surveyWaves={waves ?? []}
          />
        </div>

        {activeTab === 'banners' && (
          <div className="space-y-4">
            <Filter questions={orderedQuestions} />
            <Banners questions={orderedQuestions} />
          </div>
        )}
        {activeTab === 'exportFeatures' && (
          <div>
            <ExportFeatures surveyQuestions={questionsOptions} />
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default CrosstabBuilder;

const ExportModalHeader = ({
  activeTab,
  isCreatingExcelExport,
  onClickTab,
  questions,
  survey,
  surveyVariables,
  surveyWaves,
}: {
  activeTab: ExportTab;
  isCreatingExcelExport: boolean;
  onClickTab: React.Dispatch<React.SetStateAction<ExportTab>>;
  questions: ReactSelectValue<Question>[];
  survey: Survey;
  surveyVariables: SurveyVariable[];
  surveyWaves: SurveyWave[];
}) => {
  const queryClient = useQueryClient();
  const { resetForm, values } = useFormikContext<ExportFormData>();

  const surveyId = survey.id;

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const [{ value: exportId }, , exportIdHelpers] =
    useField<ExportFormData['exportId']>('exportId');

  function onExportDeleted(exportIdDeleted: number | null) {
    // Saved exports are returned in the API results request so we need to invalidate it
    // to have the deleted saved export no longer show up.
    queryClient.invalidateQueries({
      queryKey: surveyQueries.results({ surveyId }),
    });

    if (exportIdDeleted === exportId) {
      resetForm();
    }

    showSuccessMessage('Crosstab deleted successfully.');
  }

  const { data: savedCrosstabs = [], isLoading: isLoadingSavedCrosstabs } =
    useQuery(savedCrosstabsQueries.listForSurvey({ surveyId }));

  const { isPending: isSavingExport, mutate: saveExportBanners } =
    useSaveExportBanners({
      onError: (error) => {
        showErrorMessage(
          `There was an error saving the crosstab. Error: ${error.message}`,
        );
      },
      onSuccess: (savedBanners, { data }) => {
        // Saved exports are returned in the API results request so we need to invalidate it
        // to have the newly saved export show up in the list of available exports to choose from.
        queryClient.invalidateQueries({
          queryKey: surveyQueries.results({ surveyId }),
        });

        // The API response is currently a list of banners so we need to find the newest ID.
        // I've put in a ticket so we only return the new banner on creation so we don't have to do this.
        if (!data.bannerId) {
          const newBannerId =
            orderBy(savedBanners, (b) => b.id, 'desc')[0]?.id ?? null;
          exportIdHelpers.setValue(newBannerId);
        }

        showSuccessMessage('Crosstab saved successfully.');
      },
    });

  const { isPending: isDeleting, mutate: deleteSavedExport } =
    useDeleteSavedExport({
      onError: (error) => {
        showErrorMessage(
          `There was an error deleting the saved crosstab. Error: ${error.message}`,
        );
      },
      onSuccess: (_data, { savedExportId }) => {
        setIsConfirmDeleteOpen(false);

        onExportDeleted(savedExportId);
      },
    });

  let monadicConcepts: ReactSelectValue<QuestionConcept>[] = [];
  if (questions.find((q) => q.value.monadicId)) {
    const monadicQuestions = questions?.filter((q) => q.value.monadicId);
    if (monadicQuestions && monadicQuestions.length > 0) {
      const monadicConceptQuestion = monadicQuestions.reduce((prev, curr) => {
        return prev.value.sort < curr.value.sort ? prev : curr;
      });

      monadicConcepts =
        (monadicConceptQuestion.value as Question).concepts?.map((ctm) => ({
          label: ctm.description,
          value: ctm,
        })) || [];
    }
  }

  return (
    <div className="flex justify-between mb-4 border-b border-light-grey">
      <ExportModalTabs activeTab={activeTab} onClickTab={onClickTab} />

      <div className="flex items-end mb-2 space-x-4">
        <div className="flex items-end space-x-2">
          <div className="w-72">
            <CrosstabTitle
              existingExports={savedCrosstabs}
              exportId={exportId}
              isLoadingExistingExports={isLoadingSavedCrosstabs}
              monadicConcepts={monadicConcepts}
              onClickNew={() => {
                resetForm();
              }}
              onExportDeleted={onExportDeleted}
              questions={questions}
              surveyId={surveyId}
              surveyVariables={surveyVariables}
              surveyWaves={surveyWaves}
            />
          </div>
          <div className="mb-1">
            <CrosstabSave
              isNewExport={!exportId}
              isSavingExport={isSavingExport}
              onSave={({ isSaveAsNew }) => {
                return saveExportBanners({
                  data: formDataToSaveExportData({
                    bannerId: isSaveAsNew ? null : exportId,
                    formData: values,
                    survey,
                  }),
                  surveyId,
                });
              }}
            />
          </div>
          <div className="mb-1">
            <TrashIconWithConfirmation
              disabled={!exportId}
              isConfirmOpen={isConfirmDeleteOpen}
              isDeleting={isDeleting}
              name="delete-crosstab"
              onConfirmDelete={() => {
                if (!exportId) {
                  throw new Error('Failed to load crosstab to delete.');
                }

                deleteSavedExport({
                  savedExportId: exportId,
                  surveyId,
                });
              }}
              prompt="Are you sure you want to delete this crosstab?"
              setIsConfirmOpen={setIsConfirmDeleteOpen}
            />
          </div>
        </div>

        <ButtonLoading
          hierarchy="primary"
          isLoading={isCreatingExcelExport}
          size="lg"
          type="submit"
        >
          Export
        </ButtonLoading>
      </div>
    </div>
  );
};

const CrosstabTitle = ({
  existingExports,
  exportId,
  isLoadingExistingExports,
  monadicConcepts,
  onClickNew,
  onExportDeleted,
  questions,
  surveyId,
  surveyVariables,
  surveyWaves,
}: {
  existingExports: IBanner[];
  exportId: number | null;
  isLoadingExistingExports: boolean;
  monadicConcepts: ReactSelectValue<QuestionConcept>[];
  onClickNew(): void;
  onExportDeleted(exportId: number): void;
  questions: ReactSelectValue<Question>[];
  surveyId: number;
  surveyVariables: SurveyVariable[];
  surveyWaves: SurveyWave[];
}) => {
  const { setValues, values } = useFormikContext<ExportFormData>();
  const [isLoadSavedOpen, setIsLoadSavedOpen] = useState(false);
  const [filter, setFilter] = useState('');

  const filteredExports = existingExports.filter(({ title }) => {
    return title.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
  });

  return (
    <FormInput
      label={
        <div className="flex justify-between w-full">
          <div className="flex space-x-2">
            <span>Crosstab Title</span>
            <Tooltip>
              Give this crosstab a title and save it for future use.
            </Tooltip>
          </div>
          <div className="flex">
            <div className="mr-2 pr-2 border-r border-light-grey">
              <Hyperlink onClick={onClickNew}>New</Hyperlink>
            </div>
            <Popover
              isOpen={isLoadSavedOpen}
              name="load-saved-crosstab"
              setIsOpen={setIsLoadSavedOpen}
              trigger={(triggerProps) => (
                <div {...triggerProps}>
                  <Hyperlink>Load Saved</Hyperlink>
                </div>
              )}
            >
              <div className="w-72 text-base">
                <div className="p-2 border-b border-light-grey">
                  <Input
                    name="filter"
                    onChange={(event) => {
                      setFilter(event.target.value);
                    }}
                    placeholder="Filter"
                    size="lg"
                    value={filter}
                  />
                </div>
                <div className="max-h-72 py-1 overflow-auto">
                  {isLoadingExistingExports && filteredExports.length === 0 && (
                    <div className="h-72 pt-1 animate-pulse">
                      <div className="w-3/4 h-4 mx-2 bg-light-grey" />
                      <div className="w-1/2 h-4 mt-2 mx-2 bg-light-grey" />
                    </div>
                  )}
                  {!isLoadingExistingExports &&
                    filteredExports.length === 0 && (
                      <p className="px-2 text-dark-grey text-sm">
                        No results found
                      </p>
                    )}
                  {sortBy(filteredExports, (e) => e.title).map(
                    (existingExport, i) => {
                      return (
                        <CrosstabTitleSavedExport
                          key={i}
                          existingExport={existingExport}
                          isSelected={exportId === existingExport.id}
                          onDeleted={onExportDeleted}
                          onSelectExport={() => {
                            setValues(
                              {
                                ...values,
                                ...getFormDataFromExistingExport({
                                  // Questions is passed in twice here for both these parameters since the function
                                  // is used by the CrosstabBuilderModal component, which is the v1 version of this
                                  // component where not all questions were configurable.
                                  allQuestions: questions,
                                  configurableQuestions: questions,
                                  existingExport,
                                  monadicConcepts,
                                  surveyVariables,
                                  surveyWaves,
                                }),
                              },
                              true,
                            );
                            setIsLoadSavedOpen(false);
                            setFilter('');
                          }}
                          surveyId={surveyId}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            </Popover>
          </div>
        </div>
      }
      name="exportTitle"
      size="md"
      type="text"
    />
  );
};

const CrosstabTitleSavedExport = ({
  existingExport,
  isSelected,
  onDeleted,
  onSelectExport,
  surveyId,
}: {
  existingExport: IBanner;
  isSelected: boolean;
  onDeleted(exportId: number): void;
  onSelectExport(): void;
  surveyId: number;
}) => {
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const { isPending: isDeleting, mutate: deleteSavedExport } =
    useDeleteSavedExport({
      onError: (error) => {
        showErrorMessage(
          `There was an error deleting the saved crosstab. Error: ${error.message}`,
        );
      },
      onSuccess: (_data, { savedExportId }) => {
        onDeleted(savedExportId);
      },
      onSettled: () => {
        setIsConfirmDelete(false);
      },
    });

  return (
    <div className="relative text-sm cursor-pointer">
      <div
        className={clsx('py-1 pr-8 pl-2 ', {
          'hover:bg-light-grey': !isConfirmDelete,
        })}
        onClick={onSelectExport}
      >
        {existingExport.title || '(unlabeled)'}
        {isSelected && (
          <span className="ml-2 text-primary-d-600 text-xs uppercase">
            Selected
          </span>
        )}
      </div>
      <div>
        {isConfirmDelete ? (
          <InlineDeleteOverlay
            isDeleting={isDeleting}
            onClickCancel={() => {
              setIsConfirmDelete(false);
            }}
            onClickDelete={() => {
              deleteSavedExport({ savedExportId: existingExport.id, surveyId });
            }}
          />
        ) : (
          <div className="absolute top-1/2 right-2 transform -translate-y-1/2 hover:text-red">
            <IconBackground
              onClick={() => {
                setIsConfirmDelete(true);
              }}
              size="small"
              title="Delete"
            >
              <div className="w-3 h-3">
                <Icon id="trash" />
              </div>
            </IconBackground>
          </div>
        )}
      </div>
    </div>
  );
};

const CrosstabSave = ({
  isNewExport,
  isSavingExport,
  onSave,
}: {
  isNewExport: boolean;
  isSavingExport: boolean;
  onSave(opts: { isSaveAsNew: boolean }): void;
}): JSX.Element => {
  if (isNewExport || isSavingExport) {
    return (
      <IconBackground
        disabled={isSavingExport}
        isLoading={isSavingExport}
        onClick={() => {
          onSave({ isSaveAsNew: false });
        }}
        title="Save Crosstab"
      >
        <div className="w-3 h-3">
          <Icon id="save-01" />
        </div>
      </IconBackground>
    );
  }

  return (
    <Popover
      name="save-crosstab"
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <IconBackground title="Save Crosstab">
            <div className="w-3 h-3">
              <Icon id="save-01" />
            </div>
          </IconBackground>
        </div>
      )}
    >
      <div className="w-40">
        <div
          className="flex items-center justify-between py-1 px-2 text-sm hover:bg-light-grey cursor-pointer"
          onClick={() => {
            onSave({ isSaveAsNew: false });
          }}
        >
          Update
        </div>
        <div
          className="flex items-center justify-between py-1 px-2 text-sm hover:bg-light-grey cursor-pointer"
          onClick={() => {
            onSave({ isSaveAsNew: true });
          }}
        >
          Save as new
        </div>
      </div>
    </Popover>
  );
};

const ExportModalTabs = ({
  activeTab,
  onClickTab,
}: {
  activeTab: ExportTab;
  onClickTab: React.Dispatch<React.SetStateAction<ExportTab>>;
}): JSX.Element => {
  return (
    <div className="flex items-end space-x-4 text-sm">
      <Tab
        isActive={activeTab === 'banners'}
        onClick={() => {
          onClickTab('banners');
        }}
      >
        <div className="flex items-center space-x-1">
          <div className="w-4 h-4">
            <AdjustmentsIcon />
          </div>
          <span>Banner &amp; Filters</span>
        </div>
      </Tab>
      <Tab
        isActive={activeTab === 'exportFeatures'}
        onClick={() => {
          onClickTab('exportFeatures');
        }}
      >
        <div className="flex items-center space-x-1">
          <div className="w-4 h-4">
            <Icon id="settings-01" />
          </div>
          <span>Crosstab Configuration</span>
        </div>
      </Tab>
    </div>
  );
};

const Filter = ({
  questions,
}: {
  questions: QuestionGroup<
    Question | SurveyVariable | HardcodedWavesQuestion
  >[];
}) => {
  const [{ value: baseFilter }, , baseFilterHelpers] =
    useField<ExportFormData['baseFilter']>('baseFilter');

  return (
    <div>
      <h2 className="flex items-center mb-2 space-x-2">
        <span>Filter</span>
        <Tooltip>
          Optionally apply a filter to all the data in the crosstab.
        </Tooltip>
      </h2>
      {baseFilter ? (
        <div className="p-4">
          <div className="flex mb-4 space-x-2">
            <div className="w-80">
              <FormInput
                name="baseFilter.name"
                placeholder="Filter name..."
                size="md"
              />
            </div>
            <div className="mt-1">
              <IconBackground
                onClick={() => {
                  baseFilterHelpers.setValue(null);
                }}
                title="Remove"
              >
                <div className="w-3 h-3">
                  <Icon id="trash" />
                </div>
              </IconBackground>
            </div>
          </div>

          <div className="w-full">
            <FieldArray
              name="baseFilter.filters"
              render={(arrayHelpers) => {
                return (
                  <>
                    <div className="space-y-4">
                      {baseFilter.filters.map((_filter, i) => {
                        return (
                          <ExportFilter
                            key={i}
                            fieldName={`baseFilter.filters.${i}`}
                            onClickRemove={() => {
                              arrayHelpers.remove(i);
                            }}
                            questions={questions}
                          />
                        );
                      })}
                    </div>
                    <div className="flex mt-2">
                      <AddButton
                        label="and"
                        onClick={() => {
                          arrayHelpers.push(getEmptyExportFilter());
                        }}
                      />
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex">
          <AddButton
            label="Add Filter"
            onClick={() => {
              baseFilterHelpers.setValue({
                filters: [getEmptyExportFilter()],
                name: '',
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

const DraggableBanner = ({
  index,
  onClickRemove,
  questions,
}: {
  index: number;
  onClickRemove(): void;
  questions: QuestionGroup<
    Question | SurveyVariable | HardcodedWavesQuestion
  >[];
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Draggable draggableId={`option-${index}`} index={index}>
      {(provided, snapshot) => {
        const isDragIconVisible = isHovering || snapshot.isDragging;

        return (
          <div
            ref={provided.innerRef}
            className="relative"
            {...provided.draggableProps}
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            <div
              {...provided.dragHandleProps}
              className="flex absolute top-1/2 left-0 flex-col items-center justify-center transform -translate-y-1/2 -translate-x-full h-full"
              tabIndex={-1}
            >
              <div
                className={clsx('w-4 h-4 text-dark-grey', {
                  visible: isDragIconVisible,
                  invisible: !isDragIconVisible,
                })}
              >
                <DragIcon />
              </div>
            </div>
            <div key={index} className="p-4 border-b border-light-grey">
              <Banner
                index={index}
                onClickRemove={onClickRemove}
                questions={questions}
              />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

const Banners = ({
  questions,
}: {
  questions: QuestionGroup<
    Question | SurveyVariable | HardcodedWavesQuestion
  >[];
}) => {
  const [{ value: banners }, , bannersHelpers] =
    useField<ExportFormData['banners']>('banners');

  return (
    <div>
      <h2 className="flex items-center mb-2 space-x-2">
        <span>Banner</span>
        <Tooltip>
          The banner consists of the subgroups for data analysis. The banner is
          made up of banner points, which are the individual columns in the
          crosstab. Typical banner points are demographic breakdowns or other
          classification questions.
        </Tooltip>
      </h2>
      <FieldArray
        name="banners"
        render={(arrayHelpers) => {
          return (
            <>
              <DragDropContext
                onDragEnd={({ destination, source }) => {
                  if (!destination || source.index === destination.index) {
                    return;
                  }

                  arrayHelpers.move(source.index, destination.index);
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {banners.map((_option, index) => {
                        return (
                          <DraggableBanner
                            key={index}
                            index={index}
                            onClickRemove={() => arrayHelpers.remove(index)}
                            questions={questions}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="flex mt-4 divide-x divide-light-grey">
                <AddButton
                  label="Add Banner Point"
                  onClick={() => {
                    arrayHelpers.push({
                      filters: [getEmptyExportFilter()],
                      name: '',
                    });
                  }}
                />
                <div className="ml-2 pl-2">
                  <CrosstabQuickAdd
                    existingBannerPoints={banners}
                    onAddBannerPoints={(bannerPoints, { shouldReplace }) => {
                      if (shouldReplace) {
                        bannersHelpers.setValue(bannerPoints);
                      } else {
                        bannerPoints.forEach((bannerPoint) => {
                          arrayHelpers.push(bannerPoint);
                        });
                      }
                    }}
                    questions={questions}
                  />
                </div>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

const Banner = ({
  index,
  onClickRemove,
  questions,
}: {
  index: number;
  onClickRemove(): void;
  questions: QuestionGroup<
    Question | SurveyVariable | HardcodedWavesQuestion
  >[];
}) => {
  const [{ value: filters }] = useField<
    ExportFormData['banners'][number]['filters']
  >(`banners.${index}.filters`);

  return (
    <div>
      <div className="flex mb-4 space-x-2">
        <div className="w-80">
          <FormInput
            name={`banners.${index}.name`}
            placeholder="Banner point name..."
            size="md"
          />
        </div>
        <div className="mt-1">
          <IconBackground onClick={onClickRemove} title="Remove">
            <div className="w-3 h-3">
              <Icon id="trash" />
            </div>
          </IconBackground>
        </div>
      </div>
      <div className="relative pl-4 w-full">
        {filters.length > 0 && (
          <div className="absolute top-0 left-0 w-0.5 h-full bg-dark-grey" />
        )}
        <FieldArray
          name={`banners.${index}.filters`}
          render={(arrayHelpers) => {
            return (
              <>
                <div className="space-y-4">
                  {filters.map((_filter, i) => {
                    return (
                      <Fragment key={i}>
                        <ExportFilter
                          fieldName={`banners.${index}.filters.${i}`}
                          onClickRemove={() => {
                            arrayHelpers.remove(i);
                          }}
                          questions={questions}
                        />
                        {i !== filters.length - 1 && (
                          <WordSeparator word="and" />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
                <div className="flex mt-2">
                  <AddButton
                    label="and"
                    onClick={() => {
                      arrayHelpers.push(getEmptyExportFilter());
                    }}
                  />
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

const QuestionConfigRow = ({
  question,
}: {
  question: Question;
}): JSX.Element => {
  const fieldPrefix = `questionsConfig.${question.id}`;

  const [{ value: include }] = useField<
    ExportFormData['questionsConfig'][number]['include']
  >(`${fieldPrefix}.include`);
  const [{ value: topXBox }, , topXBoxHelpers] = useField<
    ExportFormData['questionsConfig'][number]['topXBox']
  >(`${fieldPrefix}.topXBox`);
  const [{ value: bottomXBox }, , bottomXBoxHelpers] = useField<
    ExportFormData['questionsConfig'][number]['bottomXBox']
  >(`${fieldPrefix}.bottomXBox`);

  const { isDemographic, sort, title } = question;

  function handleXBoxToggle(
    isEnabled: boolean,
    type: 'bottomXBox' | 'topXBox',
  ): void {
    const helpers = type === 'bottomXBox' ? bottomXBoxHelpers : topXBoxHelpers;

    if (isEnabled) {
      helpers.setValue({
        enabled: isEnabled,
        value: 2,
      });
    } else {
      helpers.setValue({
        enabled: isEnabled,
        value: '',
      });
    }
  }

  return (
    <div className="flex items-center justify-between p-2 text-sm">
      <div className="pr-8 flex gap-4 items-center">
        <FormCheckbox
          checkboxLabel={`${isDemographic ? 'D' : 'Q'}${sort}) ${title}`}
          name={`${fieldPrefix}.include`}
        />
        {!question.isActive && (
          <Badge color="gray" size="sm">
            Inactive
          </Badge>
        )}
      </div>

      <div className="flex items-center space-x-4">
        {canCalculateAverage(question) && (
          <div className="w-[110px]">
            <FormCheckbox
              checkboxLabel="Average"
              disabled={!include}
              name={`${fieldPrefix}.average`}
            />
          </div>
        )}
        {canCalculateTopBottomBox(question) && (
          <>
            <div className="flex items-center space-x-4 w-[110px]">
              <FormCheckbox
                checkboxLabel="TxB"
                disabled={!include}
                name={`${fieldPrefix}.topXBox.enabled`}
                onChange={(value) => handleXBoxToggle(value, 'topXBox')}
              />
              <div className="w-10">
                <FormInput
                  disabled={!include || !topXBox.enabled}
                  name={`${fieldPrefix}.topXBox.value`}
                  size="md"
                  type="number"
                />
              </div>
            </div>
            <div className="flex items-center space-x-4 w-[110px]">
              <FormCheckbox
                checkboxLabel="BxB"
                disabled={!include}
                name={`${fieldPrefix}.bottomXBox.enabled`}
                onChange={(value) => handleXBoxToggle(value, 'bottomXBox')}
              />
              <div className="w-10">
                <FormInput
                  disabled={!include || !bottomXBox.enabled}
                  name={`${fieldPrefix}.bottomXBox.value`}
                  size="md"
                  type="number"
                />
              </div>
            </div>
          </>
        )}
        {canChangeBase(question) && (
          <div className="w-[175px]">
            <FormSearchSelectInput
              isDisabled={!include}
              name={`${fieldPrefix}.base`}
              options={QUESTION_BASE_OPTIONS}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ConstraintWithStatement = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: ExportFilterModifier | undefined;
  namePrefix: string;
  question: Question;
}) => {
  const [{ value: constraints }] =
    useField<IConstraintWithStatement[]>(namePrefix);

  const options = orderBy(question.matrixOptions, (o) => o.sort).map(
    (option) => {
      return {
        label: option.title,
        value: option,
      };
    },
  );

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_constraintWithStatement, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="w-1/2">
                      <FormSearchSelectInput
                        name={`${constraintFieldName}.statement`}
                        options={getOptionOptions({
                          options: question.options,
                        })}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormSearchSelectInput
                        isMulti={logicalModifier !== 'is'}
                        name={`${constraintFieldName}.options`}
                        options={options}
                      />
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-2">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex mt-2">
              <AddButton
                label={logicalModifier === 'is' ? 'and' : 'or'}
                onClick={() => {
                  arrayHelpers.push(getEmptyConstraintWithStatement());
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const ExportFeatures = ({
  surveyQuestions,
}: {
  surveyQuestions: ReactSelectValue<Question>[];
}) => {
  const [{ value: statTesting }] = useField<
    ExportFormData['features']['statTesting']
  >('features.statTesting');

  return (
    <div>
      <div>
        <div className="flex flex-wrap items-center gap-4">
          <FormCheckbox
            checkboxLabel="Stack question labels"
            name="features.stackQuestionLabels"
            tooltip='When checked, matrix or scale statements will be "stacked" in the first column of the crosstab, with question options nested below each statement. When unchecked, statements are listed horizontally across the crosstab, with banner points nested below each statement.'
          />
          <div className="flex items-center space-x-4">
            <FormCheckbox
              checkboxLabel="Include stat testing"
              name="features.statTesting.enabled"
              tooltip="Applies relevant parametric statistical tests, such as two-proportion z-tests or t-tests, for comparing proportions and averages between banner points."
            />
            {statTesting.enabled && (
              <div className="w-36">
                <FormSearchSelectInput
                  name="features.statTesting.confidenceLevel"
                  options={STAT_TESTING_OPTIONS.filter(
                    (option) => option.value !== 80,
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 pt-4 border-t border-light-grey">
        <div className="mb-2">
          <h2 className="mb-2">Question Configuration</h2>
          <div className="flex justify-between px-2">
            <QuestionsSelection surveyQuestions={surveyQuestions} />

            <div className="flex text-sm text-gray-600 space-x-4">
              <div className="w-[110px]">Top Box</div>
              <div className="w-[110px]">Bottom Box</div>
              <div className="w-[175px] flex items-start space-x-2">
                <span>Base</span>
                <Tooltip>
                  Sets the denominator when calculating proportions for each
                  cell within a banner point.
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div>
          {surveyQuestions.map(({ value }) => {
            return (
              <div
                key={value.id}
                className="border-t border-light-grey last:border-b even:bg-gray-d-50"
              >
                <QuestionConfigRow question={value} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SELECT_DROPDOWN_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Exclude Demographics', value: 'exclude-demographics' },
  { label: 'None', value: 'none' },
] as const;
type SelectDropdownType = (typeof SELECT_DROPDOWN_OPTIONS)[number]['value'];

const QuestionsSelection = ({
  surveyQuestions,
}: {
  surveyQuestions: ReactSelectValue<Question>[];
}) => {
  const [{ value: questionsConfig }, , questionsConfigHelper] =
    useField<ExportFormData['questionsConfig']>('questionsConfig');

  let numIncludedQuestions = 0;
  forEach(questionsConfig, (config) => {
    if (config.include) {
      numIncludedQuestions += 1;
    }
  });
  const areAllQuestionsIncluded =
    numIncludedQuestions >= surveyQuestions.length;

  return (
    <div className="flex items-center">
      <div className="py-1">
        <Checkbox
          checked={numIncludedQuestions > 0}
          name="toggle-config-questions"
          onChange={() => {
            const newConfig = cloneDeep(questionsConfig);

            forEach(newConfig, (config) => {
              // If any questions are currently selected, we first de-select all questions.
              // The next click will select all questions. This is modeled off of GMail's email
              // selection checkbox.
              config.include = numIncludedQuestions === 0;
            });

            questionsConfigHelper.setValue(newConfig);
          }}
          partial={numIncludedQuestions > 0 && !areAllQuestionsIncluded}
        />
      </div>

      <Listbox<SelectDropdownType | null>
        button={
          <ListboxButton className="flex gap-2 text-sm pl-2">
            <span>Include in export</span>

            <div className="w-5 h-5">
              <Icon id="chevron-down" />
            </div>
          </ListboxButton>
        }
        onChange={(value) => {
          const newConfig = cloneDeep(questionsConfig);

          if (value === 'active') {
            forEach(newConfig, (config, questionId) => {
              const question = surveyQuestions.find(
                (q) => q.value.id === Number(questionId),
              );

              config.include = !!question?.value.isActive;
            });
          } else if (value === 'all') {
            forEach(newConfig, (config) => {
              config.include = true;
            });
          } else if (value === 'exclude-demographics') {
            forEach(newConfig, (config, questionId) => {
              const question = surveyQuestions.find(
                (q) => q.value.id === Number(questionId),
              );

              config.include = !question?.value.isDemographic;
            });
          } else if (value === 'inactive') {
            forEach(newConfig, (config, questionId) => {
              const question = surveyQuestions.find(
                (q) => q.value.id === Number(questionId),
              );

              config.include = !question?.value.isActive;
            });
          } else if (value === 'none') {
            forEach(newConfig, (config) => {
              config.include = false;
            });
          }

          questionsConfigHelper.setValue(newConfig);
        }}
        placement="bottom-start"
        value={null}
      >
        {SELECT_DROPDOWN_OPTIONS.map(({ label, value }) => {
          return (
            <ListboxOption key={value} value={value}>
              {label}
            </ListboxOption>
          );
        })}
      </Listbox>
    </div>
  );
};

const ExportFilter = ({
  fieldName,
  onClickRemove,
  questions,
}: {
  fieldName: string;
  onClickRemove(): void;
  questions: QuestionGroup<
    Question | SurveyVariable | HardcodedWavesQuestion
  >[];
}) => {
  const modifierFieldName = `${fieldName}.modifier`;
  const constraintsFieldName = `${fieldName}.constraints`;

  const [{ value: question }] = useField<IExportFilter['question']>(
    `${fieldName}.question`,
  );
  const [{ value: logicalModifier }, , logicalModifierHelpers] =
    useField<IExportFilter['modifier']>(modifierFieldName);
  const [, , constraintsHelpers] =
    useField<IExportFilter['constraints']>(constraintsFieldName);

  let constraintsContent: ReactNode = null;
  let filteredModifiers = MODIFIER_OPTIONS_DEFAULT;
  let isNumberQuestion = false;

  let monadicConcepts: { label: string; value: QuestionConcept | null }[] = [];

  if (!question) {
    // This empty select is just decoration before a user chooses a question. It looks
    // better to have this than an empty space.
    constraintsContent = (
      <Select
        onChange={() => {
          // pass
        }}
        options={[]}
        value={null}
      />
    );
  } else if (isSurveyVariableFilterQuestion(question.value)) {
    constraintsContent = (
      <ConstraintWithSegments
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        surveyVariable={question.value}
      />
    );
  } else if (isWaveFilterQuestion(question.value)) {
    constraintsContent = (
      <ConstraintWithWaves
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        waveQuestion={question.value}
      />
    );
  } else {
    isNumberQuestion = question?.value.questionTypeId === QUESTION_TYPE.NUMBER;

    if (isIdeaPresenterQuestion(question.value)) {
      constraintsContent = (
        <ConstraintWithConcepts
          logicalModifier={logicalModifier?.value}
          namePrefix={constraintsFieldName}
          question={question.value}
        />
      );
    } else if (
      question.value.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE
    ) {
      console.log(question);
      constraintsContent = (
        <ConstraintWithOptions
          logicalModifier={logicalModifier?.value}
          namePrefix={constraintsFieldName}
          question={question.value}
        />
      );
    } else if (question.value.questionTypeId === QUESTION_TYPE.SCALE) {
      constraintsContent = (
        <ConstraintWithRanges
          namePrefix={constraintsFieldName}
          optionRangeSeparator={
            <span className="text-xs">
              with a value
              <br />
              between
            </span>
          }
          question={question.value}
        />
      );
    } else if (question.value.questionTypeId === QUESTION_TYPE.RANKING) {
      constraintsContent = (
        <ConstraintWithRanges
          namePrefix={constraintsFieldName}
          optionRangeSeparator={
            <span className="text-xs">
              with a rank
              <br />
              value between
            </span>
          }
          question={question.value}
        />
      );
    } else if (question.value.questionTypeId === QUESTION_TYPE.MATRIX) {
      constraintsContent = (
        <ConstraintWithStatement
          logicalModifier={logicalModifier?.value}
          namePrefix={constraintsFieldName}
          question={question.value}
        />
      );
    } else if (isNumberQuestion) {
      constraintsContent = (
        <ConstraintWithNumber namePrefix={constraintsFieldName} />
      );
    }

    if (
      question?.value.questionTypeId === QUESTION_TYPE.RANKING ||
      question?.value.questionTypeId === QUESTION_TYPE.SCALE
    ) {
      // The BE does not currently support "either" for ranking or scale questions so we remove
      // it as a modifier option.
      filteredModifiers = MODIFIER_OPTIONS_DEFAULT.filter(
        ({ value }) => value !== 'should',
      );
    } else if (isNumberQuestion) {
      // Number questions currently only support a single modifier.
      filteredModifiers = [MODIFIER_OPTION_WITHIN];
    }

    if (question.value.monadicId) {
      const surveyQuestions = questions.find(
        (q) => q.label === 'Survey Questions',
      )?.options;
      const monadicQuestions = surveyQuestions?.filter(
        (q): q is ReactSelectValue<Question> => {
          return !!(
            !isSurveyVariableFilterQuestion(q.value) &&
            !isWaveFilterQuestion(q.value) &&
            q.value.monadicId
          );
        },
      );

      if (monadicQuestions && monadicQuestions.length > 0) {
        const monadicConceptQuestion = monadicQuestions.reduce((prev, curr) => {
          return prev.value.sort < curr.value.sort ? prev : curr;
        });

        monadicConcepts =
          (monadicConceptQuestion.value as Question).concepts?.map((ctm) => ({
            label: ctm.description,
            value: ctm,
          })) || [];

        monadicConcepts = [
          { label: 'Any concept', value: null },
          ...monadicConcepts,
        ];
      }
    }
  }

  return (
    <>
      <div className="flex w-full space-x-4">
        <div className="flex-shrink-0 w-72">
          <FormSearchSelectInput<
            ReactSelectValue<Question | SurveyVariable | HardcodedWavesQuestion>
          >
            name={`${fieldName}.question`}
            onChange={(newQuestion) => {
              // If the user selected the same question as is currently selected, we don't want to
              // overwrite their selection with empty data. In the case of waves, we use a single
              // "Waves" option here, so if anything is selected, then it's the only option that we
              // don't want to overwrite.
              const selectedSameQuestion =
                isWaveFilterQuestion(newQuestion?.value) ||
                isWaveFilterQuestion(question?.value)
                  ? newQuestion
                  : newQuestion?.value.id === question?.value.id;

              if (!newQuestion || selectedSameQuestion) {
                return;
              }

              constraintsHelpers.setError('');

              if (isSurveyVariableFilterQuestion(newQuestion.value)) {
                constraintsHelpers.setValue([getEmptyConstraintWithSegments()]);
              } else if (isWaveFilterQuestion(newQuestion.value)) {
                constraintsHelpers.setValue([getEmptyConstraintWithWaves()]);
              } else if (
                newQuestion.value.questionTypeId === QUESTION_TYPE.RANKING ||
                newQuestion.value.questionTypeId === QUESTION_TYPE.SCALE
              ) {
                constraintsHelpers.setValue([getEmptyConstraintWithRange()]);
              } else if (
                newQuestion.value.questionTypeId === QUESTION_TYPE.MATRIX
              ) {
                constraintsHelpers.setValue([
                  getEmptyConstraintWithStatement(),
                ]);
              } else if (
                newQuestion.value.questionTypeId === QUESTION_TYPE.NUMBER
              ) {
                logicalModifierHelpers.setValue(MODIFIER_OPTION_WITHIN);
                constraintsHelpers.setValue([getEmptyConstraintWithNumber()]);
              } else if (isIdeaPresenterQuestion(newQuestion.value)) {
                constraintsHelpers.setValue([getEmptyConstraintWithConcepts()]);
              } else {
                constraintsHelpers.setValue([getEmptyConstraintWithOptions()]);
              }
            }}
            options={questions}
            placeholder="Question, variable or wave..."
          />
        </div>
        <span className="flex-shrink-0 mt-2 text-xs">is</span>
        <div className="flex-shrink-0 w-24">
          <FormSearchSelectInput
            // Number questions currently one have one option for the modifier (which is set during
            // selection of the number question).
            isDisabled={isNumberQuestion}
            name={`${fieldName}.modifier`}
            options={filteredModifiers}
          />
        </div>
        {/*
          Constraints need some width (hence the w-px) or else they'll grow without bound if the option
          titles are long.
        */}
        <div className="flex-grow w-px">{constraintsContent}</div>
        <div className="mt-2">
          <IconBackground onClick={onClickRemove} size="small" title="Remove">
            <div className="w-3 h-3">
              <Icon id="x" />
            </div>
          </IconBackground>
        </div>
      </div>
      {question && question.value && (question.value as Question).monadicId && (
        <>
          <div className="flex w-full ">
            <span className="flex-shrink-0 mt-2 mr-2 text-xs">
              Concept seen is
            </span>
            <div className="flex-shrink-0 w-64">
              <FormSearchSelectInput
                name={`${fieldName}.concept`}
                options={monadicConcepts}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ConstraintWithConcepts = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: ExportFilterModifier | undefined;
  namePrefix: string;
  question: Question;
}): JSX.Element => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.concepts`}
      options={getConceptOptions({ concepts: question.concepts ?? [] })}
    />
  );
};

const ConstraintWithOptions = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: ExportFilterModifier | undefined;
  namePrefix: string;
  question: Question;
}): JSX.Element => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.options`}
      options={getOptionOptions({ options: question.options })}
    />
  );
};

const ConstraintWithRanges = ({
  namePrefix,
  optionRangeSeparator,
  question,
}: {
  namePrefix: string;
  optionRangeSeparator?: ReactNode;
  question: Question;
}): JSX.Element => {
  const [{ value: constraints }] = useField<ConstraintWithRange[]>(namePrefix);

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_range, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="flex-grow">
                      <FormSearchSelectInput
                        name={`${constraintFieldName}.option`}
                        options={getOptionOptions({
                          options: question.options,
                        })}
                      />
                    </div>
                    {optionRangeSeparator}
                    <div className="flex space-x-2">
                      <div className="w-16">
                        <FormInput
                          name={`${constraintFieldName}.range.start`}
                          size="md"
                          type="number"
                        />
                      </div>
                      <span className="mt-2 text-xs">and</span>
                      <div className="w-16">
                        <FormInput
                          name={`${constraintFieldName}.range.end`}
                          size="md"
                          type="number"
                        />
                      </div>
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-2">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

const ConstraintWithNumber = ({
  namePrefix,
}: {
  namePrefix: string;
}): JSX.Element => {
  const [{ value: constraints }] =
    useField<IConstraintWithNumber[]>(namePrefix);

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_range, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="flex-grow">
                      <FormInput
                        name={`${constraintFieldName}.range.start`}
                        size="md"
                        type="number"
                      />
                    </div>
                    <span className="mt-2 text-xs">and</span>
                    <div className="flex-grow">
                      <FormInput
                        name={`${constraintFieldName}.range.end`}
                        size="md"
                        type="number"
                      />
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-2">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

const ConstraintWithSegments = ({
  logicalModifier,
  namePrefix,
  surveyVariable,
}: {
  logicalModifier: ExportFilterModifier | undefined;
  namePrefix: string;
  surveyVariable: SurveyVariable;
}): JSX.Element => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.segments`}
      options={surveyVariable.segments.map(getSurveyVariableSegmentOption)}
    />
  );
};

const ConstraintWithWaves = ({
  logicalModifier,
  namePrefix,
  waveQuestion,
}: {
  logicalModifier: ExportFilterModifier | undefined;
  namePrefix: string;
  waveQuestion: HardcodedWavesQuestion;
}): JSX.Element => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.waves`}
      options={waveQuestion.waves}
    />
  );
};
