const SkeletonSurveyCard = () => {
  return (
    <div className="h-80 animate-pulse pt-6">
      <div className="w-1/4 h-6 mb-4 bg-light-grey" />
      <div className="space-y-4 py-6">
        <div className="w-1/2 h-4 bg-light-grey" />
        <div className="w-1/2 h-4 bg-light-grey" />
        <div className="w-1/2 h-4 bg-light-grey" />
        <div className="w-1/2 h-4 bg-light-grey" />
      </div>
    </div>
  );
};

export default SkeletonSurveyCard;
