import { clsx } from 'clsx';
import { ComponentProps } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { formatDollars } from 'util/currency';
import { getLastDashboardRoute } from 'util/routes';
import { getTestLinks } from 'util/surveys';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import { surveyQueries } from 'hooks/backend/surveys';
import { useModal } from 'hooks/modals';

import Badge from 'components/common/Badge';
import Button from 'components/common/forms/Button';
import Icon from 'components/common/Icon';
import LogoCollapsed from 'components/common/LogoCollapsed';
import { useQuery } from '@tanstack/react-query';
import SaveWaveModal from 'components/common/SaveWaveModal';
import SurveyStatusBadge from 'components/common/SurveyStatusBadge';

const SurveyHeader = ({ surveyId }: { surveyId: number }) => {
  const navigate = useNavigate();

  const { data: survey } = useQuery(surveyQueries.survey({ surveyId }));

  const {
    isOpen: isSaveWaveModalOpen,
    onCloseModal: onCloseSaveWaveModal,
    setIsOpen: setSaveWaveModalOpen,
  } = useModal();

  return (
    <header className="flex h-full items-center pr-6 space-x-6 bg-primary-d-700">
      <NavLink
        className="flex items-center h-14 justify-center w-[82px]"
        to={getLastDashboardRoute()}
      >
        <LogoCollapsed />
      </NavLink>

      <h1 className="w-survey-page-sidebar font-medium truncate shrink-0 text-white">
        {survey?.title}
      </h1>

      <div className="h-full flex space-x-6 items-center text-white">
        <nav className="h-full">
          <ul className="flex items-center h-full space-x-6">
            <li className="h-full">
              <HeaderLink to={`/surveys/${surveyId}/build`}>
                Build Survey
              </HeaderLink>
            </li>
            <div className="w-4 h-4">
              <Icon id="chevron-right" />
            </div>
            <li className="h-full">
              <HeaderLink to={`/surveys/${surveyId}/analyze`}>
                Analyze Results
              </HeaderLink>
            </li>
          </ul>
        </nav>

        {survey &&
          !survey.isBringYourOwnAudience &&
          survey.estimatedBalance && (
            <Badge color="primary" size="sm">
              {formatDollars(parseInt(survey.estimatedBalance, 10))}
            </Badge>
          )}
      </div>

      {survey && (
        <div className="flex space-x-6 grow justify-end items-center">
          {survey.wave.completes > 0 &&
            survey.status.name === SURVEY_STATUSES.COMPLETED.name && (
              <Button
                hierarchy="secondary-gray"
                icon={<Icon id="wave" />}
                iconPlacement="leading"
                onClick={() => {
                  setSaveWaveModalOpen(true);
                }}
                size="sm"
              >
                Add wave
              </Button>
            )}

          <a
            className="w-5 h-5 text-white"
            href={getTestLinks({ survey }).testLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon id="eye" />
          </a>
          <SurveyStatusBadge survey={survey} />
        </div>
      )}

      {isSaveWaveModalOpen && (
        <SaveWaveModal
          onCloseModal={onCloseSaveWaveModal}
          onWaveSaved={() => {
            onCloseSaveWaveModal();

            // The user is brought to the survey editing flow so they can make any necessary adjustments
            // for the newly created wave.
            navigate(`/surveys/${surveyId}/build/overview`);
          }}
          surveyId={surveyId}
        />
      )}
    </header>
  );
};

export default SurveyHeader;

const HeaderLink = (props: ComponentProps<typeof NavLink>) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        clsx(
          'flex border-white items-center h-full cursor-pointer text-sm font-semibold',
          {
            'border-b-2 -mb-2': isActive,
          },
        )
      }
      end={false}
    />
  );
};
