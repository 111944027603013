import { clsx } from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import {
  ChildBlock,
  Comment,
  Question,
  QuestionBlock,
  Survey,
  SurveyVariable,
} from '../../types/domainModels';
import { showErrorMessage } from '../../util/notifications';
import { useCloneQuestionBlock } from 'hooks/backend/questionBlocks';

import AddQuestionFromTemplate from '../surveyEdit/AddQuestionFromTemplate';
import AdjustmentsIcon from './icons/AdjustmentsIcon';
import BorderedButton from './BorderedButton';
import ChatIcon from './icons/ChatIcon';
import DragIcon from './icons/DragIcon';
import Dropdown, { DropdownButton, DropdownItem } from './Dropdown';
import Icon from './Icon';
import IconBackground from './icons/IconBackground';
import IndexCard from './IndexCard';
import Input from './forms/Input';
import Popover from './Popover';
import Tooltip from './Tooltip';

export type MenuName = 'demos' | 'questionBlocks' | 'questions' | 'variables';

const MenuHeaderRow = ({
  isMenuOpen,
  label,
  onToggleMenu,
}: {
  isMenuOpen: boolean;
  label: string;
  onToggleMenu(): void;
}) => {
  return (
    <div className="text-xs leading-6 font-semibold px-4 py-1 text-gray-600 flex place-content-between items-center">
      {label}

      <div
        className="cursor-pointer flex items-center justify-center w-4 h-4"
        onClick={() => {
          onToggleMenu();
        }}
      >
        {!isMenuOpen && <Icon id="chevron-down" />}
        {isMenuOpen && <Icon id="chevron-up" />}
      </div>
    </div>
  );
};

const SurveyBuildWorkspaceSidebar = ({
  comments,
  demographicQuestions,
  openMenus,
  onBlockCloned,
  onClickNewBlock,
  onClickNewQuestion,
  onClickNewVariable,
  onQuestionsReordered,
  onTemplateQuestionsAdded,
  onMenuToggled,
  curQuestion,
  questionBlocks,
  questions,
  survey,
  curVariable,
  variables,
}: {
  comments?: Comment[];
  curQuestion?: Question;
  demographicQuestions?: Question[];
  onBlockCloned?(): void;
  onClickNewQuestion?(): void;
  onClickNewBlock?(): void;
  onClickNewVariable?(): void;
  onMenuToggled(menuName: MenuName): void;
  onQuestionsReordered?(questions: Question[]): void;
  onTemplateQuestionsAdded?(): void;
  openMenus: Record<MenuName, boolean>;
  questionBlocks?: QuestionBlock[];
  questions?: Question[];
  survey?: Survey | null;
  curVariable?: SurveyVariable | undefined;
  variables?: SurveyVariable[];
}): JSX.Element => {
  const navigate = useNavigate();

  const questionsInBlocks: Question[][] = [];
  let curBlock: Question[] = [];
  // Assume question blocks and monadic blocks do not overlap
  if (questions) {
    for (let i = 0; i < questions.length; i++) {
      if (i === 0) {
        curBlock.push(questions[i]);
        continue;
      } else if (
        questions[i].blockId === questions[i - 1].blockId &&
        questions[i].monadicId === questions[i - 1].monadicId
      ) {
        curBlock.push(questions[i]);
      } else {
        questionsInBlocks.push(curBlock);
        curBlock = [questions[i]];
      }
    }
  }

  if (curBlock.length > 0) {
    questionsInBlocks.push(curBlock);
  }

  let questionMenuItems = [
    <MenuHeaderRow
      key={0}
      isMenuOpen={openMenus.questions}
      label="Questions"
      onToggleMenu={() => {
        onMenuToggled('questions');
      }}
    />,
  ];

  let demographicMenuItems = [
    <MenuHeaderRow
      key={0}
      isMenuOpen={openMenus.demos}
      label="Demographics"
      onToggleMenu={() => {
        onMenuToggled('demos');
      }}
    />,
  ];

  let variableMenuItems = [
    <MenuHeaderRow
      key={0}
      isMenuOpen={openMenus.variables}
      label="Variables"
      onToggleMenu={() => {
        onMenuToggled('variables');
      }}
    />,
  ];

  let questionBlockMenuItems = [
    <MenuHeaderRow
      key={0}
      isMenuOpen={openMenus.questionBlocks}
      label="Question Blocks"
      onToggleMenu={() => {
        onMenuToggled('questionBlocks');
      }}
    />,
  ];

  if (openMenus.questions && questions && survey) {
    questionMenuItems = [
      ...questionMenuItems,
      <div key={1}>
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            if (!destination) {
              return;
            }

            const sourceBlock = Number(
              source.droppableId.split('droppable-')[1],
            );
            const destinationBlock = Number(
              destination.droppableId.split('droppable-')[1],
            );

            let sourceIndex = 0;
            let curBlock = sourceBlock - 1;
            while (curBlock >= 0) {
              sourceIndex = sourceIndex + questionsInBlocks[curBlock].length;
              curBlock = curBlock - 1;
            }
            sourceIndex = sourceIndex + source.index;

            let destIndex = 0;
            curBlock = destinationBlock - 1;
            while (curBlock >= 0) {
              destIndex = destIndex + questionsInBlocks[curBlock].length;
              curBlock = curBlock - 1;
            }
            destIndex = destIndex + destination.index;
            if (destinationBlock > sourceBlock) {
              destIndex = destIndex - 1;
            }

            const newQuestions = [...questions];
            const [removed] = newQuestions.splice(sourceIndex, 1);
            newQuestions.splice(destIndex, 0, removed);

            if (
              newQuestions[destIndex - 1] &&
              newQuestions[destIndex + 1] &&
              newQuestions[destIndex - 1].blockId ===
                newQuestions[destIndex + 1].blockId
            ) {
              newQuestions[destIndex].blockId =
                newQuestions[destIndex - 1].blockId;
            } else {
              newQuestions[destIndex].blockId = null;
            }

            if (
              newQuestions[destIndex - 1] &&
              newQuestions[destIndex + 1] &&
              newQuestions[destIndex - 1].monadicId ===
                newQuestions[destIndex + 1].monadicId
            ) {
              newQuestions[destIndex].monadicId =
                newQuestions[destIndex - 1].monadicId;
            }

            if (onQuestionsReordered) {
              onQuestionsReordered(
                newQuestions.map((question, index) => {
                  return {
                    ...question,
                    sort: index + 1,
                  };
                }),
              );
            }
          }}
        >
          {questionsInBlocks.map((blockQuestions, index) => {
            return (
              <QuestionBlockList
                key={index}
                blockQuestions={blockQuestions}
                comments={comments ?? []}
                index={index}
                onBlockCloned={onBlockCloned}
                question={curQuestion}
                questionBlocks={
                  questionBlocks &&
                  questionBlocks.flatMap((b) => b.questionBlocks)
                }
                surveyId={survey?.id}
              />
            );
          })}
        </DragDropContext>
      </div>,
      <div
        key={2}
        className="px-4 py-1 text-xs text-gray-600 flex items-center justify-between"
      >
        <div
          className="hover:bg-gray-300 rounded px-1 cursor-pointer"
          onClick={onClickNewQuestion}
        >
          <span>New Question</span>
        </div>
        {onTemplateQuestionsAdded && (
          <AddQuestionFromTemplate
            onTemplateQuestionsAdded={onTemplateQuestionsAdded}
            questions={questions}
            survey={survey}
          />
        )}
      </div>,
    ];
  }

  if (openMenus.demos && demographicQuestions && survey) {
    demographicMenuItems = [
      ...demographicMenuItems,
      ...demographicQuestions.map((question) => {
        const formattedTitle =
          question.title.length > 100
            ? `${question.title.substring(0, 100)}...`
            : question.title;
        const isFocusedQuestion = curQuestion && curQuestion.id === question.id;
        const { id, isActive, questionAudiences = [], sort } = question;
        const numCommentsForQuestion = comments
          ? comments.filter((comment) => {
              return !comment.parentId && comment.questionId === id;
            }).length
          : 0;
        return (
          <div
            key={question.id}
            className={clsx(
              'hover:bg-gray-300 cursor-pointer px-4 py-1 space-y-1',
              {
                'bg-mint': curQuestion && curQuestion.id === question.id,
              },
            )}
            onClick={(event) => {
              // The default might have been prevented if the user clicked on the toggle
              // to change the question's active status.
              if (event.defaultPrevented) {
                return;
              }
              navigate(`/surveys/${survey.id}/build/questions/${question.id}`);
            }}
          >
            <div className="text-gray-900 flex items-center justify-between">
              <div
                className={clsx('text-sm text-gray-900', {
                  'text-forest': isFocusedQuestion,
                })}
              >
                Demographic {sort}
              </div>
              <div className="flex items-center space-x-1">
                {questionAudiences.length > 0 && (
                  <Tooltip
                    trigger={
                      <div className="w-4 h-4">
                        <AdjustmentsIcon />
                      </div>
                    }
                  >
                    Display Logic
                  </Tooltip>
                )}
                {numCommentsForQuestion > 0 && (
                  <Tooltip
                    trigger={
                      <div className="flex items-center">
                        <div className="w-4 h-4 mr-1">
                          <ChatIcon />
                        </div>
                        <span>{numCommentsForQuestion}</span>
                      </div>
                    }
                  >
                    Comments
                  </Tooltip>
                )}
                {!isActive && (
                  <Tooltip
                    trigger={
                      <div className="w-4 h-4">
                        <Icon id="slash-circle-01" />
                      </div>
                    }
                  >
                    Inactive
                  </Tooltip>
                )}
              </div>
            </div>
            <div
              className={clsx('break-word overflow-hidden text-gray-600', {
                'text-green': curQuestion && curQuestion.id === question.id,
              })}
            >
              {formattedTitle}
            </div>
          </div>
        );
      }),
    ];
  }

  if (openMenus.variables && variables && survey) {
    variableMenuItems = [
      ...variableMenuItems,
      ...variables.map((variable, variableIdx) => {
        const formattedTitle =
          variable.title.length > 100
            ? `${variable.title.substring(0, 100)}...`
            : variable.title;
        return (
          <div
            key={variable.id}
            className={clsx('hover:bg-gray-300 cursor-pointer px-4 py-1', {
              'bg-mint': curVariable && curVariable.id === variable.id,
            })}
            onClick={(event) => {
              // The default might have been prevented if the user clicked on the toggle
              // to change the question's active status.
              if (event.defaultPrevented) {
                return;
              }
              navigate(`/surveys/${survey.id}/build/variables/${variable.id}`);
            }}
          >
            <div className="text-gray-900">
              <div
                className={clsx('text-gray-900', {
                  'text-forest': curVariable && curVariable.id === variable.id,
                })}
              >
                {`Hidden Variable ${variableIdx + 1}`}
              </div>
            </div>
            <div
              className={clsx('break-word overflow-hidden text-gray-600', {
                'text-green': curVariable && curVariable.id === variable.id,
              })}
            >
              {formattedTitle}
            </div>
          </div>
        );
      }),
      <div
        key="new-variable"
        className="px-4 py-1 text-xs text-gray-600 flex  items-center justify-between"
      >
        <div
          className="hover:bg-gray-300 rounded px-1 cursor-pointer"
          onClick={onClickNewVariable}
        >
          <span>New Variable</span>
        </div>
      </div>,
    ];
  }

  if (openMenus.questionBlocks && questionBlocks && survey) {
    questionBlockMenuItems = [
      ...questionBlockMenuItems,
      ...questionBlocks.flatMap((parentBlock) =>
        parentBlock.questionBlocks
          .sort((q1, q2) => q1.sort - q2.sort)
          .map((questionBlock) => {
            const formattedTitle =
              questionBlock?.title.length > 100
                ? `${questionBlock.title.substring(0, 100)}...`
                : questionBlock.title;
            return (
              <div
                key={questionBlock.id}
                className="hover:bg-gray-300 cursor-pointer px-4 py-1"
                onClick={() => {
                  navigate(`/surveys/${survey.id}/build/question-blocks`);
                }}
              >
                <div className="text-gray-900">Question Block</div>
                <div className="break-word overflow-hidden text-gray-600">
                  {formattedTitle}
                </div>
              </div>
            );
          }),
      ),
    ];
  }

  if (questionBlocks && questionBlocks.length < 1 && openMenus.questionBlocks) {
    questionBlockMenuItems = [
      ...questionBlockMenuItems,
      <div
        key="new-question-block"
        className="px-4 py-1 text-xs text-gray-600 flex items-center justify-between"
      >
        <div
          className="hover:bg-gray-300 rounded px-1 cursor-pointer"
          onClick={onClickNewBlock}
        >
          <span>New Question Block</span>
        </div>
      </div>,
    ];
  }

  return (
    <div>
      <div className="text-gray-900 flex justify-between items-center pb-5 pt-6 sticky top-0 z-10 bg-white">
        <span>Questions</span>
        <Dropdown
          button={
            <DropdownButton as="div">
              <BorderedButton type="button">
                <div className="w-4 h-4">
                  <Icon id="plus" />
                </div>
              </BorderedButton>
            </DropdownButton>
          }
          placement="bottom-start"
        >
          <DropdownItem as="button" onClick={onClickNewQuestion}>
            Question
          </DropdownItem>
          <DropdownItem as="button" onClick={onClickNewBlock}>
            Question Block
          </DropdownItem>
        </Dropdown>
      </div>
      <div className="space-y-6">
        <IndexCard>{demographicMenuItems}</IndexCard>
        <IndexCard>{questionMenuItems}</IndexCard>
        {questionBlocks && questionBlocks.length > 0 && (
          <IndexCard>{questionBlockMenuItems}</IndexCard>
        )}
        <IndexCard>{variableMenuItems}</IndexCard>
      </div>
    </div>
  );
};

export default SurveyBuildWorkspaceSidebar;

const QuestionBlockList = ({
  blockQuestions,
  comments,
  index,
  onBlockCloned,
  question,
  questionBlocks,
  surveyId,
}: {
  blockQuestions: Question[];
  comments: Comment[];
  index: number;
  onBlockCloned?(): void;
  question: Question | undefined;
  questionBlocks: ChildBlock[] | undefined;
  surveyId: number | undefined;
}) => {
  const [isCloneBlockPopoverOpen, setIsCloneBlockPopoverOpen] = useState(false);
  const [cloneBlockTitle, setCloneBlockTitle] = useState('');
  const [areQuestionsDisplayed, setAreQuestionsDisplayed] = useState(true);

  const block = questionBlocks?.find((b) => b.id === blockQuestions[0].blockId);

  const { isPending: isCloning, mutate: cloneQuestionBlock } =
    useCloneQuestionBlock({
      onError: (err) => {
        showErrorMessage(`Failed to clone block. Error: ${err.message}`);
      },
      onSuccess: () => {
        onBlockCloned?.();
        setIsCloneBlockPopoverOpen(false);
        setCloneBlockTitle('');
      },
    });

  const isQuestionBlock = blockQuestions[0].blockId && questionBlocks;
  const isMonadicBlock = blockQuestions[0].monadicId;

  return (
    <>
      {(isQuestionBlock || isMonadicBlock) && (
        <div className="flex items-center space-between cursor-pointer px-4">
          <div
            className="flex-grow flex items-center space-x-2"
            onClick={() => {
              setAreQuestionsDisplayed(
                (areQuestionsDisplayed) => !areQuestionsDisplayed,
              );
            }}
          >
            {isQuestionBlock && <span>{block?.title}</span>}
            {isMonadicBlock && <span>MONADIC MODULE</span>}
            {!areQuestionsDisplayed && (
              <span>
                (Q{blockQuestions[0].sort} - Q
                {blockQuestions[blockQuestions.length - 1].sort})
              </span>
            )}
          </div>

          {isQuestionBlock && (
            <>
              <Link
                key={index}
                className="relative block mb-2 cursor-pointer"
                to={`/surveys/${blockQuestions[0].surveyId}/build/question-blocks`}
              >
                <IconBackground>
                  <div className="w-3 h-3">
                    <Icon id="pencil" />
                  </div>
                </IconBackground>
              </Link>

              <Popover
                isOpen={isCloneBlockPopoverOpen}
                name={`clone-block-name`}
                setIsOpen={setIsCloneBlockPopoverOpen}
                trigger={(triggerProps) => {
                  return (
                    <div {...triggerProps}>
                      <a className="relative block mb-2 ml-2 cursor-pointer">
                        <IconBackground
                          disabled={isCloning}
                          isLoading={isCloning}
                        >
                          <div className="w-3 h-3">
                            <Icon id="copy-02" />
                          </div>
                        </IconBackground>
                      </a>
                    </div>
                  );
                }}
              >
                <div className="w-52 text-base">
                  <div className="p-2 border-b border-light-grey">
                    <Input
                      name="cloneBlockTitle"
                      onChange={(event) => {
                        setCloneBlockTitle(event.target.value);
                      }}
                      // We have to do this hackiness because this is already inside a form
                      // and it's not valid HTML to have nested forms. Eventually, this popover
                      // should be rendered in a portal so it's outside the flow and therefore
                      // not nested within the page form.
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          event.stopPropagation();

                          if (!surveyId || !block) {
                            throw new Error('No block to clone provided.');
                          }

                          return cloneQuestionBlock({
                            blockId: block.id,
                            data: { title: cloneBlockTitle },
                            surveyId,
                          });
                        }
                      }}
                      placeholder="New block title"
                      size="lg"
                      type="text"
                      value={cloneBlockTitle}
                    />
                  </div>
                </div>
              </Popover>
            </>
          )}
        </div>
      )}
      {areQuestionsDisplayed && (
        <QuestionCardsList
          blockIndex={index}
          comments={comments}
          question={question}
          questions={blockQuestions}
        />
      )}
    </>
  );
};

const QuestionCardsList = ({
  blockIndex,
  comments,
  question,
  questions,
}: {
  blockIndex: number;
  comments: Comment[];
  question: Question | undefined;
  questions: Question[];
}): JSX.Element => {
  const focusedQuestionId = question?.id;

  return (
    <Droppable droppableId={`droppable-${blockIndex}`}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {questions.map((question, index) => {
            return (
              <DraggableQuestionCard
                key={question.id}
                comments={comments}
                focusedQuestionId={focusedQuestionId}
                index={index}
                question={question}
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const DraggableQuestionCard = ({
  comments,
  focusedQuestionId,
  index,
  question,
}: {
  comments: Comment[];
  focusedQuestionId?: number;
  index: number;
  question: Question;
}): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false);

  const {
    blockId,
    id,
    isActive,
    questionAudiences = [],
    sort,
    title,
    monadicId,
  } = question;
  const isFocusedQuestion = focusedQuestionId === id;
  const formattedTitle =
    title.length > 100 ? `${title.substring(0, 100)}...` : title;

  const numCommentsForQuestion = comments.filter((comment) => {
    return !comment.parentId && comment.questionId === id;
  }).length;

  return (
    <Draggable draggableId={`draggable-question-card-${id}`} index={index}>
      {(provided, snapshot) => {
        const isDragIconVisible = isHovering || snapshot.isDragging;

        return (
          <Link
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={clsx(`relative block mb-2 cursor-pointer`, {
              'ml-4': blockId || monadicId,
            })}
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
            style={provided.draggableProps.style}
            to={`/surveys/${question.surveyId}/build/questions/${question.id}`}
          >
            <div
              {...provided.dragHandleProps}
              className="flex absolute top-1/2 left-0 flex-col items-center justify-start transform -translate-y-1/2 h-full"
            >
              <div
                className={clsx('w-4 h-4 mt-2 text-dark-grey', {
                  visible: isDragIconVisible,
                  invisible: !isDragIconVisible,
                })}
              >
                <DragIcon />
              </div>
            </div>

            <div
              key={question.id}
              className={clsx('hover:bg-gray-300 cursor-pointer px-4 py-1', {
                'bg-gray-100': !isActive && !isFocusedQuestion,
                'bg-mint': isFocusedQuestion,
              })}
            >
              <div className="text-gray-900 flex items-center justify-between">
                <div
                  className={clsx('text-gray-900', {
                    'text-forest': isFocusedQuestion,
                  })}
                >
                  Question {sort}
                </div>
                <div className="flex items-center space-x-1">
                  {questionAudiences.length > 0 && (
                    <Tooltip
                      trigger={
                        <div className="w-4 h-4">
                          <AdjustmentsIcon />
                        </div>
                      }
                    >
                      Display Logic
                    </Tooltip>
                  )}
                  {numCommentsForQuestion > 0 && (
                    <Tooltip
                      trigger={
                        <div className="flex items-center">
                          <div className="w-4 h-4 mr-1">
                            <ChatIcon />
                          </div>
                          <span>{numCommentsForQuestion}</span>
                        </div>
                      }
                    >
                      Comments
                    </Tooltip>
                  )}
                  {!isActive && (
                    <Tooltip
                      trigger={
                        <div className="w-4 h-4">
                          <Icon id="slash-circle-01" />
                        </div>
                      }
                    >
                      Inactive
                    </Tooltip>
                  )}
                </div>
              </div>
              <div
                className={clsx('break-word overflow-hidden text-gray-600', {
                  'text-green': isFocusedQuestion,
                })}
              >
                {formattedTitle}
              </div>
            </div>
          </Link>
        );
      }}
    </Draggable>
  );
};
