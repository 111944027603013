import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  ExternalHyperlink,
  Header,
  LevelFormat,
  Packer,
  Paragraph,
  ShadingType,
  TabStopPosition,
  TabStopType,
  TextRun,
} from 'docx';
import { castArray, compact, orderBy, sortBy } from 'lodash-es';
import { saveAs } from 'file-saver';

import { DATE_FORMATS, formatDate } from './dates';
import {
  getCarryForwardQuestion,
  getConceptTitle,
  isFeatureEnabled,
  isIdeaPresenterQuestion,
} from './questions';
import {
  getFormDisplayLogic,
  isConstraintWithConcepts,
  isConstraintWithNumber,
  isConstraintWithRanges,
  isConstraintWithStatement,
} from './displayLogic';
import { getOptionTitle, getOptionTitleIndexFromSort } from './options';
import { getTestLinks } from './surveys';
import {
  OPTION_TYPE,
  Question,
  QuestionOption,
  QuestionQuotaLogicalModifiers,
  QUESTION_FEATURE,
  QUESTION_OPTION_FEATURE,
  QUESTION_TYPE,
  Survey,
} from '../types/domainModels';
import { questionAudiencesToAudiences } from './audience';
import { SCALE_TYPES, SCALE_UNITS } from '../constants/scaleComponents';

interface OptionFeatureFormatter {
  (opts: { question: Question }): string | undefined;
}

interface QuestionFeatureFormatter {
  (opts: {
    question: Question;
    questions: Question[];
    survey: Survey;
    total: number;
    value: number | undefined;
  }): string | undefined;
}

interface QuotasByOptionId {
  [optionId: number]: {
    logicalModifier: QuestionQuotaLogicalModifiers;
    value: number;
  }[];
}

const OPTION_FEATURE_DISPLAY_NAMES: Partial<{
  [feature in QUESTION_OPTION_FEATURE]: OptionFeatureFormatter;
}> = {
  [QUESTION_OPTION_FEATURE.ANCHOR]: ({ question }) => {
    if (isFeatureEnabled(question, QUESTION_FEATURE.RANDOMIZE_OPTIONS)) {
      return 'anchor';
    }
  },
  [QUESTION_OPTION_FEATURE.EXCLUSIVE]: ({ question }) => {
    if (
      question.questionTypeId !== QUESTION_TYPE.SCALE &&
      (question.questionTypeId === QUESTION_TYPE.RANKING ||
        isFeatureEnabled(question, QUESTION_FEATURE.MULTIPLE_RESPONSE))
    ) {
      return 'exclusive';
    }
  },
  [QUESTION_OPTION_FEATURE.FREE_TEXT]: ({ question }) => {
    if (
      question.questionTypeId === QUESTION_TYPE.MATRIX ||
      question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE
    ) {
      return 'free text';
    }
  },
  [QUESTION_OPTION_FEATURE.PRESERVE]: ({ question }) => {
    if (isFeatureEnabled(question, QUESTION_FEATURE.DISPLAY_X_OF_Y)) {
      return 'preserve';
    }
  },
  [QUESTION_OPTION_FEATURE.REQUIRE_VIEW]: ({ question }) => {
    if (
      question.questionTypeId !== QUESTION_TYPE.MATRIX &&
      question.contentTypeId === OPTION_TYPE.IMAGE &&
      isFeatureEnabled(question, QUESTION_FEATURE.VIEW_ALL_IMAGES)
    ) {
      return 'require view';
    }
  },
};

const QUESTION_FEATURE_DISPLAY_NAMES: Partial<{
  [feature in QUESTION_FEATURE]: QuestionFeatureFormatter | string;
}> = {
  [QUESTION_FEATURE.CARRY_FORWARD_DISPLAYED]: ({ question, questions }) =>
    getCarryForwardFeatureWording({
      feature: QUESTION_FEATURE.CARRY_FORWARD_DISPLAYED,
      question,
      questions,
    }),
  [QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED]: ({ question, questions }) =>
    getCarryForwardFeatureWording({
      feature: QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED,
      question,
      questions,
    }),
  [QUESTION_FEATURE.CARRY_FORWARD_SELECTED]: ({ question, questions }) =>
    getCarryForwardFeatureWording({
      feature: QUESTION_FEATURE.CARRY_FORWARD_SELECTED,
      question,
      questions,
    }),
  [QUESTION_FEATURE.DISPLAY_X_OF_Y]: ({ total, value }) =>
    `display ${value} of ${total}`,
  [QUESTION_FEATURE.MULTIPLE_OPTION_SELECTIONS]: 'multiple response',
  [QUESTION_FEATURE.MULTIPLE_RESPONSE]: ({ question }) => {
    if (question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
      return 'multiple response';
    }
  },
  [QUESTION_FEATURE.MULTIPLE_RESPONSE_LOWER_LIMIT]: ({ value }) =>
    `min ${value}`,
  [QUESTION_FEATURE.MULTIPLE_RESPONSE_UPPER_LIMIT]: ({ value }) =>
    `max ${value}`,
  [QUESTION_FEATURE.NUMBER_TYPE]: 'number type',
  [QUESTION_FEATURE.OPEN_MATRIX]: 'open matrix',
  [QUESTION_FEATURE.OPEN_MATRIX_INVERTED]: 'inverted',
  [QUESTION_FEATURE.OPEN_MATRIX_SCROLL]: 'scroll',
  [QUESTION_FEATURE.RANDOMIZE_MATRIX_OPTIONS]: 'randomize options',
  [QUESTION_FEATURE.REVERSE_OPTIONS]: 'reverse options',
  [QUESTION_FEATURE.RANDOMIZE_OPTIONS]: ({ question }) =>
    question.questionTypeId === QUESTION_TYPE.MATRIX
      ? 'randomize statements'
      : 'randomize',
  [QUESTION_FEATURE.REQUIRED_SUM]: ({ value }) => `required sum of ${value}`,
};

const QUESTION_TYPE_DISPLAY_NAMES = {
  [QUESTION_TYPE.GABOR_GRANGER]: 'gabor-granger',
  [QUESTION_TYPE.MATRIX]: 'matrix',
  [QUESTION_TYPE.MULTIPLE_CHOICE]: 'multiple choice',
  [QUESTION_TYPE.OPEN_ENDED]: 'open ended',
  [QUESTION_TYPE.RANKING]: 'ranking',
  [QUESTION_TYPE.SCALE]: 'scale',
};

function createAudience({ survey }: { survey: Survey }): Paragraph[] {
  // Survey audience ID represents "Natural Fallout" as opposed to a custom audience.
  if (survey.audienceId === 0) {
    return [];
  }

  const audienceParagraphs = [
    new Paragraph({}),

    new Paragraph({
      children: [
        new TextRun({
          bold: true,
          text: `Audience (${survey.audience.title})`,
        }),
      ],
    }),
    new Paragraph({}),
  ];

  survey.audience.audienceSlices.forEach((audienceSlice) => {
    const sliceQuestion = audienceSlice.audienceSliceCategories[0].question;

    const demographicBreakdownBullets: Paragraph[] = [];
    audienceSlice.audienceSliceCategories.forEach((audienceSliceCategory) => {
      const demographicTextRuns = [
        new TextRun(`${Number(audienceSliceCategory.percentage) * 100}%`),
      ];

      if (audienceSliceCategory.logicalModifier === 'isnt') {
        demographicTextRuns.push(new TextRun({ bold: true, text: ' are not' }));
      }

      const attributes =
        audienceSliceCategory.audienceSliceCategoryAttributes.map(
          (asca) => asca.audienceAttribute,
        );
      attributes.map((attribute, attributeIdx) => {
        if (attributeIdx > 0) {
          if (audienceSliceCategory.logicalModifier === 'should') {
            demographicTextRuns.push(new TextRun(' or'));
          } else if (audienceSliceCategory.logicalModifier === 'is') {
            demographicTextRuns.push(new TextRun(' and'));
          } else if (audienceSliceCategory.logicalModifier === 'isnt') {
            demographicTextRuns.push(new TextRun(' nor'));
          }
        }

        if (sliceQuestion.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
          demographicTextRuns.push(
            new TextRun(
              ` ${
                sliceQuestion.contentTypeId === OPTION_TYPE.TEXT
                  ? attribute.enumValue?.title
                  : attribute.enumValue?.description
              }`,
            ),
          );
        } else if (
          sliceQuestion.questionTypeId === QUESTION_TYPE.NUMBER &&
          attribute.numberRange
        ) {
          demographicTextRuns.push(
            new TextRun(
              ` ${attribute.numberRange.start}-${attribute.numberRange.end}`,
            ),
          );
        }
      });

      demographicBreakdownBullets.push(
        new Paragraph({
          children: demographicTextRuns,
          bullet: { level: 1 },
        }),
      );
    });

    audienceParagraphs.push(
      new Paragraph({
        children: [new TextRun(sliceQuestion.description ?? '')],
        bullet: { level: 0 },
      }),
      ...demographicBreakdownBullets,
    );
  });

  return audienceParagraphs;
}

async function createHeader({
  survey,
}: {
  survey: Survey;
}): Promise<Paragraph[]> {
  return [
    new Paragraph({
      alignment: AlignmentType.RIGHT,
      children: [
        // new ImageRun({
        //   data: await getImageDataUrl(
        //     getImageUrl({
        //       format: 'jpg',
        //       public_id: 'glass-logo-transparent',
        //       resource_type: 'image',
        //       version: import.meta.env.VITE_GLASS_LOGO_VERSION as string,
        //     })
        //   ),
        //   floating: {
        //     horizontalPosition: {
        //       relative: HorizontalPositionRelativeFrom.MARGIN,
        //       offset: 0,
        //     },
        //     margins: {
        //       // This is about .5 inches (but I have no idea what this unit is - docs don't say).
        //       right: 500000,
        //     },
        //     verticalPosition: {
        //       relative: VerticalPositionRelativeFrom.LINE,
        //       offset: 0,
        //     },
        //     wrap: {
        //       side: TextWrappingSide.RIGHT,
        //       type: TextWrappingType.SQUARE,
        //     },
        //   },
        //   transformation: {
        //     width: 175,
        //     height: 37,
        //   },
        // }),
        new TextRun({ style: 'smaller', text: `\t${survey.title}` }),
        new TextRun({
          bold: true,
          break: 1,
          style: 'smaller',
          text: '\tCurrent Date: ',
        }),
        new TextRun({
          style: 'smaller',
          text: `${formatDate(new Date(), {
            format: DATE_FORMATS.READABLE_FULL,
          })}`,
        }),
      ],
      tabStops: [
        {
          position: TabStopPosition.MAX,
          type: TabStopType.RIGHT,
        },
      ],
    }),
  ];
}

function createMatrixOptions({
  question,
}: {
  question: Question;
}): Paragraph[] {
  if (question.questionTypeId !== QUESTION_TYPE.MATRIX) {
    return [];
  }

  const options = orderBy(question.matrixOptions, (o) => o.sort).map(
    (option) => {
      return {
        isActive: option.isActive,
        isAnchored: option.isAnchored,
        isExclusive: option.isExclusive,
        isFreeText: option.isFreeText,
        title: option.title,
      };
    },
  );

  let labelParagraphs = [new Paragraph({})];

  options.forEach(
    ({ isActive, isAnchored, isExclusive, isFreeText, title }) => {
      const labelRuns = [new TextRun(title)];

      if (!isActive) {
        labelRuns.push(
          new TextRun({
            style: 'optionFeature',
            text: ' [inactive]',
          }),
        );
      }

      if (
        isFeatureEnabled(question, QUESTION_FEATURE.RANDOMIZE_MATRIX_OPTIONS) &&
        isAnchored
      ) {
        labelRuns.push(
          new TextRun({
            style: 'optionFeature',
            text: ' [anchor]',
          }),
        );
      }

      if (
        isFeatureEnabled(
          question,
          QUESTION_FEATURE.MULTIPLE_OPTION_SELECTIONS,
        ) &&
        isExclusive
      ) {
        labelRuns.push(
          new TextRun({
            style: 'optionFeature',
            text: ' [exclusive]',
          }),
        );
      }

      if (question.contentTypeId === OPTION_TYPE.TEXT && isFreeText) {
        labelRuns.push(
          new TextRun({
            style: 'optionFeature',
            text: ' [free text]',
          }),
        );
      }

      labelParagraphs = [
        ...labelParagraphs,
        new Paragraph({
          children: labelRuns,
          numbering: {
            instance: question.id,
            level: 0,
            reference: 'label-numbering',
          },
        }),
      ];
    },
  );

  return labelParagraphs;
}

function createOptionDisplayLogic({
  option,
  questions,
}: {
  option: QuestionOption;
  questions: Question[];
}): Paragraph[] {
  const optionAudiences = option.questionOptionAudiences;
  if (optionAudiences.length === 0) {
    return [];
  }

  let displayLogicSentence = [new TextRun('Display if: ')];

  const audiences = questionAudiencesToAudiences(optionAudiences);
  const displayLogic = getFormDisplayLogic({
    audiences,
    questions,
  });
  displayLogic?.forEach((group, groupIdx) => {
    if (groupIdx > 0) {
      displayLogicSentence = [
        ...displayLogicSentence,
        new TextRun({
          bold: true,
          text: ' or ',
        }),
      ];
    }

    group.forEach((andGroup, andGroupIdx) => {
      const { constraints, modifier, question } = andGroup;

      if (andGroupIdx > 0) {
        displayLogicSentence = [
          ...displayLogicSentence,
          new TextRun({
            bold: true,
            text: ' and ',
          }),
        ];
      }

      displayLogicSentence = [
        ...displayLogicSentence,
        new TextRun(`"${question?.label}" is `),
        new TextRun({
          bold: true,
          text: modifier?.label,
        }),
        new TextRun(' '),
      ];

      constraints.forEach((constraint, constraintIdx) => {
        if (constraintIdx > 0) {
          displayLogicSentence = [
            ...displayLogicSentence,
            new TextRun(modifier?.value === 'is' ? ' and ' : ' or '),
          ];
        }

        if (isConstraintWithRanges(constraint)) {
          displayLogicSentence = [
            ...displayLogicSentence,
            new TextRun(
              getOptionTitle({
                index: getOptionTitleIndexFromSort(
                  constraint.option?.value.sort,
                ),
                option: constraint.option?.value,
              }),
            ),
            new TextRun({
              italics: true,
              text: ' with a value between ',
            }),
            new TextRun(
              `${constraint.range.start} and ${constraint.range.end}`,
            ),
          ];
        } else if (isConstraintWithStatement(constraint)) {
          displayLogicSentence = [
            ...displayLogicSentence,
            new TextRun(
              `${getOptionTitle({
                index: getOptionTitleIndexFromSort(
                  constraint.statement?.value.sort,
                ),
                option: constraint.statement?.value,
              })}: ${castArray(constraint.options)
                .map((option) => {
                  return option.label;
                })
                .join(', ')}`,
            ),
          ];
        } else if (isConstraintWithConcepts(constraint)) {
          castArray(constraint.concepts).forEach((concept, conceptIdx) => {
            if (conceptIdx > 0) {
              displayLogicSentence = [
                ...displayLogicSentence,
                new TextRun(' or '),
              ];
            }

            displayLogicSentence = [
              ...displayLogicSentence,
              new TextRun(getConceptTitle({ concept: concept.value })),
            ];
          });
        } else if (!isConstraintWithNumber(constraint)) {
          castArray(constraint.options).forEach((option, optionIdx) => {
            if (optionIdx > 0) {
              displayLogicSentence = [
                ...displayLogicSentence,
                new TextRun(' or '),
              ];
            }

            displayLogicSentence = [
              ...displayLogicSentence,
              new TextRun(
                getOptionTitle({
                  index: getOptionTitleIndexFromSort(option.value.sort),
                  option: option.value,
                }),
              ),
            ];
          });
        }
      });
    });
  });

  return [
    new Paragraph({
      children: displayLogicSentence,
      indent: {
        left: convertInchesToTwip(1),
      },
      style: 'displayLogic',
    }),
  ];
}

function createOptions({
  option,
  question,
  questions,
  quotasByOptionId,
}: {
  option: QuestionOption;
  question: Question;
  questions: Question[];
  quotasByOptionId: QuotasByOptionId;
}): Paragraph[] {
  if (question.questionTypeId === QUESTION_TYPE.GABOR_GRANGER) {
    return [
      new Paragraph({}),
      new Paragraph({
        bullet: { level: 0 },
        children: [
          new TextRun(
            `Range: $${option.rangeMin} - $${option.rangeMax} (Step: $${option.rangeStep})`,
          ),
        ],
        indent: { left: convertInchesToTwip(1) },
      }),
    ];
  }

  const optionRuns: (TextRun | ExternalHyperlink)[] = [];

  const optionTitle = getOptionTitle({ index: option.sort, option });
  if (question.contentTypeId === OPTION_TYPE.IMAGE && option.dataUrl) {
    optionRuns.push(
      new ExternalHyperlink({
        children: [
          new TextRun({
            style: 'Hyperlink',
            text: optionTitle,
          }),
        ],
        link: option.dataUrl.secure_url,
      }),
    );
  } else {
    optionRuns.push(new TextRun(`${optionTitle}`));
  }

  option.questionOptionFeatures.forEach(({ feature }) => {
    const featureFormatter = OPTION_FEATURE_DISPLAY_NAMES[feature.code];
    if (!featureFormatter) {
      return;
    }

    const optionFeatureDisplay = featureFormatter({ question });
    if (optionFeatureDisplay) {
      optionRuns.push(
        new TextRun({
          style: 'optionFeature',
          text: ` [${optionFeatureDisplay}]`,
        }),
      );
    }
  });

  if (option.carryOverParentId) {
    optionRuns.push(
      new TextRun({
        style: 'optionFeature',
        text: ' [carry forward]',
      }),
    );
  }

  const optionQuotas = quotasByOptionId[option.id] ?? [];
  optionQuotas.forEach(({ logicalModifier, value }) => {
    if (logicalModifier === 'all') {
      optionRuns.push(
        new TextRun({
          style: 'qualifyOption',
          text: ' (QUALIFY)',
        }),
      );
    } else if (logicalModifier === 'none') {
      optionRuns.push(
        new TextRun({
          style: 'disqualifyOption',
          text: ' (DISQUALIFY)',
        }),
      );
    } else if (logicalModifier === 'at_least') {
      optionRuns.push(
        new TextRun({
          style: 'qualifyOption',
          text: ` (AT LEAST ${value})`,
        }),
      );
    } else if (logicalModifier === 'at_most') {
      optionRuns.push(
        new TextRun({
          style: 'disqualifyOption',
          text: ` (AT MOST ${value})`,
        }),
      );
    }
  });

  let optionParagraphs = [
    new Paragraph({
      children: optionRuns,
      numbering: {
        instance: question.id,
        level: 0,
        reference: 'option-numbering',
      },
    }),
    ...createOptionDisplayLogic({ option, questions }),
  ];

  if (question.questionTypeId === QUESTION_TYPE.SCALE) {
    const {
      rangeMin,
      rangeMax,
      rangeStep,
      scaleLowLabel,
      scaleMiddleLabel,
      scaleHighLabel,
      scaleTypeId,
      scaleUnitId,
    } = option;

    if (rangeMax === null || rangeMin === null || rangeStep === null) {
      // Handle error of undefined scale values
      optionParagraphs = [
        ...optionParagraphs,
        new Paragraph({
          children: [
            new TextRun(
              'SCALE DOES NOT HAVE MAX, MIN AND STEP DEFINED - PLEASE CHECK QUESTION',
            ),
          ],
        }),
      ];
    } else {
      const scaleType = SCALE_TYPES.find(({ value }) => value === scaleTypeId);
      const scaleUnit = SCALE_UNITS.find(({ value }) => value === scaleUnitId);

      const scaleBullets = [
        `Type: ${scaleType?.label}`,
        `Unit: ${scaleUnit?.label}`,
        `Labels: ${scaleLowLabel} /${
          scaleMiddleLabel ? ` ${scaleMiddleLabel}` : ''
        } / ${scaleHighLabel}`,
        `Range: ${rangeMin} - ${rangeMax} (Step: ${rangeStep})`,
      ];

      optionParagraphs = [
        ...optionParagraphs,
        ...scaleBullets.map((text) => {
          return new Paragraph({
            bullet: { level: 0 },
            children: [new TextRun(text)],
            indent: { left: convertInchesToTwip(1.25) },
          });
        }),
      ];
    }
  }

  return optionParagraphs;
}

function createOptionsHeader({
  question,
}: {
  question: Question;
}): Paragraph[] {
  let optionsHeader = '';
  if (question.questionTypeId === QUESTION_TYPE.SCALE) {
    optionsHeader = 'Scales';
  } else if (question.questionTypeId === QUESTION_TYPE.MATRIX) {
    optionsHeader = 'Statements';
  } else if (
    [
      QUESTION_TYPE.IDEA_PRESENTER,
      QUESTION_TYPE.MULTIPLE_CHOICE,
      QUESTION_TYPE.RANKING,
    ].includes(question.questionTypeId)
  ) {
    optionsHeader = 'Options';
  }

  if (!optionsHeader) {
    return [];
  }

  return [
    new Paragraph({}),
    new Paragraph({
      children: [
        new TextRun({
          italics: true,
          text: `${optionsHeader}:`,
        }),
      ],
      indent: {
        left: convertInchesToTwip(0.5),
      },
    }),
  ];
}

function unfortunateHardcodeCheck(
  question: Question,
  description: string,
  index: number,
) {
  if (question.surveyId === 2953) {
    return `Concept ${index + 1}`;
  }

  return description;
}

function createQuestionConcepts({
  question,
  questions,
}: {
  question: Question;
  questions: Question[];
}): Paragraph[] {
  const concepts = question.concepts ?? [];
  const pipedQuestion = questions.find(({ id }) => {
    return id === question.displayedConcept?.id;
  });
  if (concepts.length === 0 && !pipedQuestion) {
    return [];
  }

  let conceptParagraphs = compact([
    new Paragraph({}),
    new Paragraph({
      children: [
        new TextRun({
          italics: true,
          text: `Concept${concepts.length > 0 ? 's' : ''}:`,
        }),
      ],
      indent: { left: convertInchesToTwip(0.5) },
    }),

    pipedQuestion
      ? new Paragraph({
          children: [
            new TextRun({
              text: `Piped from Q${pipedQuestion.sort}"`,
            }),
          ],
          indent: { left: convertInchesToTwip(0.75) },
        })
      : null,
  ]);

  concepts.forEach((concept, index) => {
    conceptParagraphs = [
      ...conceptParagraphs,
      new Paragraph({
        bullet: { level: 0 },
        children: compact([
          new ExternalHyperlink({
            children: [
              new TextRun({
                text: unfortunateHardcodeCheck(
                  question,
                  concept.description,
                  index,
                ),
                style: 'Hyperlink',
              }),
            ],
            link: concept.media.secure_url,
          }),
          isFeatureEnabled(question, QUESTION_FEATURE.VIEW_CONCEPT)
            ? new TextRun({ style: 'questionFeature', text: ' [require view]' })
            : null,
        ]),
        indent: {
          left: convertInchesToTwip(1),
        },
      }),
    ];
  });

  return conceptParagraphs;
}

function createQuestionDisplayLogic({
  question,
  questions,
}: {
  question: Question;
  questions: Question[];
}): Paragraph[] {
  const questionAudiences = question.questionAudiences ?? [];
  if (questionAudiences.length === 0) {
    return [];
  }

  let displayLogicParagraphs = [
    new Paragraph({
      children: [new TextRun({ text: `Display Q${question.sort} if:` })],
      style: 'displayLogic',
    }),
  ];

  const audiences = questionAudiencesToAudiences(questionAudiences);
  const displayLogic = getFormDisplayLogic({
    audiences,
    questions,
  });
  displayLogic.forEach((group, groupIdx) => {
    if (groupIdx > 0) {
      displayLogicParagraphs = [
        ...displayLogicParagraphs,
        new Paragraph({
          children: [
            new TextRun({
              bold: true,
              text: 'or',
            }),
          ],
          style: 'displayLogic',
        }),
      ];
    }

    group.forEach((andGroup, andGroupIdx) => {
      const { constraints, modifier, question } = andGroup;

      if (andGroupIdx > 0) {
        displayLogicParagraphs = [
          ...displayLogicParagraphs,
          new Paragraph({
            children: [
              new TextRun({
                bold: true,
                text: 'and',
              }),
            ],
            indent: {
              left: convertInchesToTwip(0.25),
            },
            style: 'displayLogic',
          }),
        ];
      }

      displayLogicParagraphs = [
        ...displayLogicParagraphs,
        new Paragraph({
          children: [
            new TextRun(`"${question?.label}" is `),
            new TextRun({
              bold: true,
              text: modifier?.label,
            }),
            new TextRun(':'),
          ],
          indent: {
            left: convertInchesToTwip(0.25),
          },
          style: 'displayLogic',
        }),
      ];

      constraints.forEach((constraint) => {
        if (isConstraintWithRanges(constraint)) {
          displayLogicParagraphs = [
            ...displayLogicParagraphs,
            new Paragraph({
              children: [
                new TextRun({
                  style: 'smaller',
                  text: getOptionTitle({
                    index: getOptionTitleIndexFromSort(
                      constraint.option?.value.sort,
                    ),
                    option: constraint.option?.value,
                  }),
                }),
                new TextRun({
                  italics: true,
                  style: 'smaller',
                  text: ' with a value between ',
                }),
                new TextRun({
                  style: 'smaller',
                  text: `${constraint.range.start} and ${constraint.range.end}`,
                }),
              ],
              bullet: { level: 0 },
              indent: {
                left: convertInchesToTwip(0.75),
                hanging: convertInchesToTwip(0.18),
              },
              style: 'displayLogic',
            }),
          ];
        } else if (isConstraintWithStatement(constraint)) {
          displayLogicParagraphs = [
            ...displayLogicParagraphs,
            new Paragraph({
              children: [
                new TextRun({
                  style: 'smaller',
                  text: `${getOptionTitle({
                    index: getOptionTitleIndexFromSort(
                      constraint.statement?.value.sort,
                    ),
                    option: constraint.statement?.value,
                  })}: ${castArray(constraint.options)
                    .map((option) => {
                      return option.label;
                    })
                    .join(', ')}`,
                }),
              ],
              bullet: { level: 0 },
              indent: {
                left: convertInchesToTwip(0.75),
                hanging: convertInchesToTwip(0.18),
              },
              style: 'displayLogic',
            }),
          ];
        } else if (isConstraintWithConcepts(constraint)) {
          castArray(constraint.concepts).forEach((concept) => {
            displayLogicParagraphs = [
              ...displayLogicParagraphs,
              new Paragraph({
                children: [
                  new TextRun({
                    style: 'smaller',
                    text: getConceptTitle({ concept: concept.value }),
                  }),
                ],
                bullet: { level: 0 },
                indent: {
                  left: convertInchesToTwip(0.75),
                  hanging: convertInchesToTwip(0.18),
                },
                style: 'displayLogic',
              }),
            ];
          });
        } else if (!isConstraintWithNumber(constraint)) {
          castArray(constraint.options).forEach((option) => {
            displayLogicParagraphs = [
              ...displayLogicParagraphs,
              new Paragraph({
                children: [
                  new TextRun({
                    style: 'smaller',
                    text: getOptionTitle({
                      index: getOptionTitleIndexFromSort(option.value.sort),
                      option: option.value,
                    }),
                  }),
                ],
                bullet: { level: 0 },
                indent: {
                  left: convertInchesToTwip(0.75),
                  hanging: convertInchesToTwip(0.18),
                },
                style: 'displayLogic',
              }),
            ];
          });
        }
      });
    });
  });

  displayLogicParagraphs = [...displayLogicParagraphs, new Paragraph({})];

  return displayLogicParagraphs;
}

function createQuestionDescription({
  question,
}: {
  question: Question;
}): Paragraph {
  const questionFeaturesParagraph: TextRun[] = [
    new TextRun({
      style: 'smaller',
      text: `${question.description ?? ''}`,
    }),
  ];

  return new Paragraph({
    children: questionFeaturesParagraph,
  });
}

function createQuestionFeatures({
  question,
  questions,
  survey,
}: {
  question: Question;
  questions: Question[];
  survey: Survey;
}): Paragraph {
  const questionFeaturesParagraph: TextRun[] = [
    new TextRun({
      style: 'questionFeature',
      text: `[${
        isIdeaPresenterQuestion(question)
          ? 'idea presenter'
          : // TODO: Address compilation error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            QUESTION_TYPE_DISPLAY_NAMES[question.questionTypeId]
      }] `,
    }),
  ];

  const featureNames: (string | undefined)[] = [];
  (question.questionFeatures ?? []).forEach(({ feature, numberRange }) => {
    const featureFormatter = QUESTION_FEATURE_DISPLAY_NAMES[feature.code];
    if (featureFormatter) {
      featureNames.push(
        typeof featureFormatter === 'function'
          ? featureFormatter({
              question,
              questions,
              survey,
              total: question.options.length,
              value: numberRange?.start,
            })
          : featureFormatter,
      );
    }
  });

  if (
    question.contentTypeId === OPTION_TYPE.IMAGE &&
    question.displayOptionDescription
  ) {
    featureNames.push('display option description');
  }

  if (isFeatureEnabled(question, QUESTION_FEATURE.NUMBER_TYPE)) {
    featureNames.push('number type');
  }

  questionFeaturesParagraph.push(
    new TextRun({
      style: 'questionFeature',
      text: compact(featureNames)
        .map((feature) => {
          return `[${feature}]`;
        })
        .join(' '),
    }),
  );

  return new Paragraph({
    children: questionFeaturesParagraph,
  });
}

function createQuestions({
  questions,
  survey,
}: {
  questions: Question[];
  survey: Survey;
}): Paragraph[] {
  let questionParagraphs = [
    new Paragraph({
      children: [new TextRun({ bold: true, text: 'Questions' })],
    }),
  ];

  questions.forEach((question) => {
    questionParagraphs = [
      ...questionParagraphs,
      new Paragraph({}),

      ...createQuestionDisplayLogic({ question, questions }),

      new Paragraph({
        children: [
          new TextRun({
            bold: true,
            // Would love to use a numbered list here instead of manually inserting the numbering
            // but we have potentially inactive questions that won't be included which means we need
            // some way to restart the numbering. Unfortunately, the docx library currently has a bug
            // that doesn't make this possible (see: https://github.com/dolanmiu/docx/issues/925).
            text: `${
              question.label
                ? `${question.sort} (${question.label})`
                : question.sort
            }. ${question.title.trimEnd()}${
              question.isActive ? '' : ' [INACTIVE]'
            }`,
          }),
        ],
        shading: question.isActive
          ? undefined
          : {
              type: ShadingType.SOLID,
              color: 'DDDDDD',
              fill: 'DDDDDD',
            },
      }),
      createQuestionDescription({ question }),
      createQuestionFeatures({ question, questions, survey }),

      ...createQuestionConcepts({ question, questions }),

      ...createOptionsHeader({ question }),
    ];

    const quotasByOptionId: QuotasByOptionId = {};
    (question.questionQuotas ?? []).forEach(
      ({ logicalModifier, numberNeeded, questionOptions }) => {
        const quota = { logicalModifier, value: numberNeeded };

        questionOptions.forEach(({ id }) => {
          const option = question.options.find((o) => o.id === id);
          if (!option) {
            return;
          }

          const existingQuotas = quotasByOptionId[option.id];
          if (existingQuotas) {
            existingQuotas.push(quota);
          } else {
            quotasByOptionId[option.id] = [quota];
          }
        });
      },
    );

    const options = sortBy(question.options, (o) => o.sort);
    options.forEach((option) => {
      questionParagraphs = [
        ...questionParagraphs,

        ...createOptions({
          option,
          question,
          questions,
          quotasByOptionId,
        }),
      ];
    });

    questionParagraphs = [
      ...questionParagraphs,

      ...createMatrixOptions({ question }),

      new Paragraph({
        border: {
          bottom: {
            color: 'dddddd',
            space: 1,
            style: 'single',
            size: 6,
          },
        },
      }),
    ];
  });

  return questionParagraphs;
}

function createSurveyLinks({ survey }: { survey: Survey }): Paragraph[] {
  const surveyLinks = [
    new Paragraph({
      children: [
        new ExternalHyperlink({
          children: [
            new TextRun({
              style: 'Hyperlink',
              text: 'Click here to see survey',
            }),
          ],
          link:
            survey.status.name === 'draft'
              ? `${import.meta.env.VITE_PLATFORM_HOST}/surveys/${
                  survey.id
                }/build/overview`
              : `${import.meta.env.VITE_PLATFORM_HOST}/surveys/${survey.id}/analyze`,
        }),
      ],
    }),
  ];

  if (survey.status.name === 'draft') {
    surveyLinks.push(
      new Paragraph({
        children: [
          new ExternalHyperlink({
            children: [
              new TextRun({
                style: 'Hyperlink',
                text: 'Click here to test survey',
              }),
            ],
            link: getTestLinks({ survey }).testLink,
          }),
        ],
      }),
    );
  }

  return surveyLinks;
}

function createSurveyOverview({ survey }: { survey: Survey }): Paragraph[] {
  return [
    new Paragraph({
      children: [new TextRun({ bold: true, text: 'Survey Overview' })],
    }),
    new Paragraph({}),
    new Paragraph({
      bullet: { level: 0 },
      children: [new TextRun(`Title: ${survey.title}`)],
    }),
    new Paragraph({
      bullet: { level: 0 },
      children: [new TextRun(`Participants: ${survey.participants}`)],
    }),
    new Paragraph({
      bullet: { level: 0 },
      children: [
        new TextRun(`Estimated Incidence: ${survey.incidenceType.name}`),
      ],
    }),
  ];
}

export async function generateQuestionnaireDocx({
  isMaster = false,
  passedQuestions,
  survey,
}: {
  isMaster?: boolean;
  passedQuestions: Question[];
  survey: Survey;
}): Promise<void> {
  const questions = isMaster
    ? passedQuestions
    : passedQuestions.filter((q) => q.isActive);

  const doc = new Document({
    numbering: {
      config: [
        {
          reference: 'option-numbering',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(1),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
          ],
        },
        {
          reference: 'label-numbering',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(1.5),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
          ],
        },
      ],
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: await createHeader({ survey }),
          }),
        },
        children: [
          new Paragraph({}),
          ...createSurveyLinks({ survey }),

          new Paragraph({}),
          ...createSurveyOverview({ survey }),

          ...createAudience({ survey }),

          new Paragraph({}),
          ...createQuestions({ questions, survey }),
        ],
      },
    ],
    styles: {
      characterStyles: [
        {
          basedOn: 'Normal',
          id: 'disqualifyOption',
          name: 'Disqualify Option',
          run: {
            bold: true,
            color: 'bf0808',
          },
        },
        {
          id: 'optionFeature',
          name: 'Option Feature',
          basedOn: 'Normal',
          run: {
            color: '3f93ea',
          },
        },
        {
          basedOn: 'Normal',
          id: 'qualifyOption',
          name: 'Qualify Option',
          run: {
            bold: true,
            color: '008000',
          },
        },
        {
          basedOn: 'Normal',
          id: 'questionFeature',
          name: 'Question Feature',
          run: {
            color: '3f93ea',
          },
        },
        {
          basedOn: 'Normal',
          id: 'smaller',
          name: 'Smaller',
          run: {
            size: 22,
          },
        },
      ],
      paragraphStyles: [
        {
          basedOn: 'Normal',
          id: 'displayLogic',
          name: 'Display Logic',
          run: {
            size: 22,
          },
        },
      ],
      default: {
        document: {
          run: {
            font: 'Arial',
            size: 24,
          },
        },
      },
    },
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${survey.title} QNR.docx`);
  });
}

function getCarryForwardFeatureWording({
  feature,
  question,
  questions,
}: {
  feature:
    | QUESTION_FEATURE.CARRY_FORWARD_DISPLAYED
    | QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED
    | QUESTION_FEATURE.CARRY_FORWARD_SELECTED;
  question: Question;
  questions: Question[];
}) {
  let carryForwardTypeDisplay = '';
  if (feature === QUESTION_FEATURE.CARRY_FORWARD_DISPLAYED) {
    carryForwardTypeDisplay = 'displayed';
  } else if (feature === QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED) {
    carryForwardTypeDisplay = 'not selected';
  } else if (feature === QUESTION_FEATURE.CARRY_FORWARD_SELECTED) {
    carryForwardTypeDisplay = 'selected';
  }

  const { carryForwardFeature, carryForwardQuestion } = getCarryForwardQuestion(
    {
      question,
      questions,
    },
  );

  let matrixOption = '';
  if (
    carryForwardQuestion?.questionTypeId === QUESTION_TYPE.MATRIX &&
    (feature === QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED ||
      feature === QUESTION_FEATURE.CARRY_FORWARD_SELECTED)
  ) {
    matrixOption =
      carryForwardQuestion.matrixOptions.find(
        (option) => option.id === carryForwardFeature?.matrixOptionId,
      )?.title ?? '';
  }

  return `carry forward ${carryForwardTypeDisplay}${
    matrixOption ? ` "${matrixOption}"` : ''
  } from Q${carryForwardQuestion?.sort}`;
}
