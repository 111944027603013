import Icon from 'components/common/Icon';
import { Sidebar, SidebarPageLink } from 'components/layout/SurveyWithSidebar';

const SidebarNavBuild = ({ surveyId }: { surveyId: number }) => {
  return (
    <Sidebar
      links={
        <>
          <SidebarPageLink
            icon={<Icon id="dotpoints-01" />}
            to={`/surveys/${surveyId}/build/overview`}
          >
            Overview
          </SidebarPageLink>
          <SidebarPageLink
            icon={<Icon id="users-01" />}
            to={`/surveys/${surveyId}/build/audience`}
          >
            Audience
          </SidebarPageLink>
          <SidebarPageLink
            end={false}
            icon={<Icon id="file-question-02" />}
            to={`/surveys/${surveyId}/build/questions`}
          >
            Questions
          </SidebarPageLink>
          <SidebarPageLink
            icon={<Icon id="pencil" />}
            to={`/surveys/${surveyId}/build/customize`}
          >
            Customize
          </SidebarPageLink>
          <SidebarPageLink
            icon={<Icon id="check-square-broken" />}
            to={`/surveys/${surveyId}/build/review`}
          >
            Review
          </SidebarPageLink>
        </>
      }
    />
  );
};

export default SidebarNavBuild;
