import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import { clsx } from 'clsx';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Menu } from '@headlessui/react';
import { orderBy } from 'lodash-es';

import { Comment as IComment } from '../../types/domainModels';
import {
  DATE_FORMATS,
  formatDate,
  formatHumanReadableDurationFromNow,
} from '../../util/dates';
import { showErrorMessage } from '../../util/notifications';
import { useAuth } from 'contexts/auth';
import {
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from 'hooks/backend/comments';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import Checkbox from '../common/forms/Checkbox';
import CommentIcon from '../common/icons/CommentIcon';
import Icon from 'components/common/Icon';
import TextareaAutosize from '../common/forms/TextareaAutosize';
import Tooltip from '../common/Tooltip';
import UserBubble from '../common/UserBubble';

type ThreadComments = Partial<{ [threadId: number]: IComment[] }>;

const Comments = ({
  comments,
  questionId,
  surveyId,
}: {
  comments: IComment[];
  questionId: number | undefined;
  surveyId: number;
}): JSX.Element => {
  const threadComments: ThreadComments = {};
  const threads: IComment[] = [];

  comments
    .filter((comment) => {
      return (
        comment.surveyId || (questionId && comment.questionId === questionId)
      );
    })
    .forEach((comment) => {
      if (comment.parentId) {
        const existingComments = threadComments[comment.parentId];
        if (existingComments) {
          existingComments.push(comment);
        } else {
          threadComments[comment.parentId] = [comment];
        }
      } else {
        threads.push(comment);
      }
    });

  return (
    <CommentsContent
      questionId={questionId}
      surveyId={surveyId}
      threadComments={threadComments}
      threads={threads}
    />
  );
};

export default Comments;

const CommentsContent = ({
  questionId,
  surveyId,
  threadComments,
  threads,
}: {
  questionId: number | undefined;
  surveyId: number;
  threadComments: ThreadComments;
  threads: IComment[];
}): JSX.Element => {
  const [selectedThreadID, setSelectedThreadID] = useState<number | null>(null);

  const selectedThread = threads.find(({ id }) => id === selectedThreadID);

  function deselectThreadIfSelected(commentID: number) {
    if (commentID === selectedThread?.id) {
      setSelectedThreadID(null);
    }
  }

  return (
    <div className="flex flex-col h-full">
      <div className="px-6 border-b border-gray-300 flex">
        <a className="flex items-center h-full py-4 text-green border-b-2 border-green space-x-2">
          <span>Comments</span>
          {threads.length > 0 && (
            <div className="w-5 h-5 bg-green text-white rounded-full flex items-center justify-center text-xs">
              {threads.length}
            </div>
          )}
        </a>
      </div>

      {threads.length > 0 ? (
        <div className="grow overflow-auto overscroll-contain">
          {selectedThread ? (
            <div className="px-4">
              <button
                className="pt-2 flex items-center space-x-1 text-gray-600 hover:text-green"
                onClick={() => {
                  setSelectedThreadID(null);
                }}
                type="button"
              >
                <div className="w-4 h-4">
                  <Icon id="arrow-narrow-left" />
                </div>
                <span>All comments</span>
              </button>
              <Thread
                comment={selectedThread}
                onCommentDeleted={deselectThreadIfSelected}
                onCommentResolved={deselectThreadIfSelected}
                questionId={questionId}
                surveyId={surveyId}
                threadComments={threadComments}
              />
            </div>
          ) : (
            orderBy(threads, (thread) => thread.createdAt, 'desc').map(
              (comment) => {
                return (
                  <ThreadSummary
                    key={comment.id}
                    comment={comment}
                    onSelectThread={(threadID) => {
                      setSelectedThreadID(threadID);
                    }}
                    threadComments={threadComments}
                  />
                );
              },
            )
          )}
        </div>
      ) : (
        <div className="px-4 flex flex-col items-center mt-6 space-y-2">
          <div className="w-1/2 bg-white border border-gray-300 rounded-xl p-2 flex space-x-1 items-center shadow">
            <div className="rounded bg-faded-green p-1">
              <div className="w-6 h-6 text-primary-d-600">
                <CommentIcon />
              </div>
            </div>
            <div className="space-y-1 grow">
              <div className="w-full bg-gray-300 rounded-full h-2" />
              <div className="w-3/4 bg-gray-300 rounded-full h-2" />
            </div>
          </div>
          <p className="text-gray-900 text-center">
            This survey doesn't have any comments yet. Yours will be the first!
          </p>
        </div>
      )}

      {!selectedThreadID && (
        <div
          className={clsx('py-6 px-4', {
            'border-t border-gray-300': threads.length > 0,
          })}
        >
          <AddCommentCard questionId={questionId} surveyId={surveyId} />
        </div>
      )}
    </div>
  );
};

const AddCommentCard = ({
  questionId,
  surveyId,
}: {
  questionId: number | undefined;
  surveyId: number;
}): JSX.Element => {
  const [appliesToSurvey, setAppliesToSurvey] = useState(false);
  const [text, setText] = useState('');

  const { isPending: isAddingComment, mutate: addComment } = useCreateComment({
    onError: (err) => {
      showErrorMessage(
        `There was an error saving your comment. Error: ${err.message}`,
      );
    },
    onSuccess: () => {
      setText('');
    },
  });

  return (
    <div>
      <CommentEditing
        autofocus={true}
        isSubmitting={isAddingComment}
        onSubmit={() => {
          addComment({
            appliesToSurvey: appliesToSurvey || !questionId,
            data: {
              comment: text,
              parentId: null,
              questionId,
              resolved: false,
              surveyId,
            },
          });
        }}
        placeholder="Add a comment"
        preActionContent={
          // If there's not a current question being viewed, then this comment has to be
          // for the entire survey.
          questionId ? (
            <div className="mt-2 mb-4">
              <Checkbox
                checked={appliesToSurvey}
                label={
                  <div className="flex items-center">
                    <span className="mr-1 text-xs">
                      Applies to entire survey
                    </span>
                    <Tooltip>
                      <div className="w-80 max-w-full space-y-2">
                        <p>
                          By default, this comment will apply to this question
                          and will only be visible when this question is being
                          viewed.
                        </p>
                        <p>
                          Check this box to apply the comment to the entire
                          survey which will make this comment visible regardless
                          of the question being viewed.
                        </p>
                      </div>
                    </Tooltip>
                  </div>
                }
                name="comment-for-survey"
                onChange={(event) => {
                  setAppliesToSurvey(event.currentTarget.checked);
                }}
              />
            </div>
          ) : null
        }
        setText={setText}
        showActions={true}
        submitText="Comment"
        text={text}
      />
    </div>
  );
};

const ThreadSummary = ({
  comment,
  onSelectThread,
  threadComments,
}: {
  comment: IComment;
  onSelectThread(threadID: number): void;
  threadComments: ThreadComments;
}): JSX.Element => {
  const replies = threadComments[comment.id] ?? [];

  return (
    <div className="px-4 border-t border-gray-300 py-6 relative">
      <Comment
        canResolve={true}
        comment={comment}
        hasReplies={replies.length > 0}
      />

      <div className="mt-2 flex space-x-2 text-xs">
        <button
          className="text-green font-semibold hover:underline"
          onClick={() => {
            onSelectThread(comment.id);
          }}
          type="button"
        >
          {replies.length > 0 ? (
            <span>
              {replies.length} {replies.length === 1 ? 'Reply' : 'Replies'}
            </span>
          ) : (
            'Reply'
          )}
        </button>
        {replies.length > 0 && (
          <span className="text-gray-600">
            {formatHumanReadableDurationFromNow(
              replies[replies.length - 1].createdAt,
            )}
          </span>
        )}
      </div>
    </div>
  );
};

const Thread = ({
  comment,
  onCommentDeleted,
  onCommentResolved,
  questionId,
  surveyId,
  threadComments,
}: {
  comment: IComment;
  onCommentDeleted(commentID: number): void;
  onCommentResolved(commentID: number): void;
  questionId: number | undefined;
  surveyId: number;
  threadComments: ThreadComments;
}) => {
  const replies = threadComments[comment.id] ?? [];

  const [hasFocused, setHasFocused] = useState(false);
  const [replyText, setReplyText] = useState('');

  const { isPending: isCreatingComment, mutate: createComment } =
    useCreateComment({
      onError: (err) => {
        showErrorMessage(
          `There was an error saving your comment. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        setReplyText('');
        setHasFocused(false);
      },
    });

  return (
    <div className="py-6 space-y-6">
      <div className="relative">
        <Comment
          canResolve={true}
          comment={comment}
          hasReplies={replies.length > 0}
          onCommentDeleted={onCommentDeleted}
          onCommentResolved={onCommentResolved}
        />
      </div>

      {orderBy(replies, (reply) => reply.createdAt, 'asc').map((reply) => {
        return (
          <div key={reply.id} className="relative">
            <Comment comment={reply} />
          </div>
        );
      })}

      <CommentEditing
        isSubmitting={isCreatingComment}
        onFocus={() => {
          setHasFocused(true);
        }}
        onSubmit={() => {
          createComment({
            appliesToSurvey: !comment.questionId,
            data: {
              comment: replyText,
              parentId: comment.id,
              questionId,
              resolved: false,
              surveyId,
            },
          });
        }}
        placeholder="Add a reply"
        setText={setReplyText}
        showActions={hasFocused}
        submitText="Reply"
        text={replyText}
      />
    </div>
  );
};

const Comment = ({
  canResolve = false,
  comment,
  hasReplies,
  onCommentDeleted,
  onCommentResolved,
}: {
  canResolve?: boolean;
  comment: IComment;
  hasReplies?: boolean;
  onCommentDeleted?(commentID: number): void;
  onCommentResolved?(commentID: number): void;
}): JSX.Element => {
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [confirmResolve, setConfirmResolve] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(comment.comment);

  const { isPending: isResolvingComment, mutate: resolveComment } =
    useUpdateComment({
      onError: (err) => {
        showErrorMessage(
          `There was an error resolving the conversation. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        setConfirmResolve(false);
        onCommentResolved?.(comment.id);
      },
    });

  const { isPending: isEditingComment, mutate: editComment } = useUpdateComment(
    {
      onError: (err) => {
        showErrorMessage(
          `There was an error saving your edits. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        setIsEditing(false);
      },
    },
  );

  const { isPending: isDeletingComment, mutate: deleteComment } =
    useDeleteComment({
      onError: (err) => {
        showErrorMessage(
          `There was an error deleting your comment. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        setConfirmDeletion(false);
        onCommentDeleted?.(comment.id);
      },
    });

  return (
    <div>
      {confirmResolve && (
        <ConfirmResolveOverlay
          isResolving={isResolvingComment}
          onCancel={() => {
            setConfirmResolve(false);
          }}
          onResolve={() => {
            resolveComment({
              appliesToSurvey: !comment.questionId,
              commentId: comment.id,
              data: { resolved: true },
            });
          }}
        />
      )}
      {confirmDeletion && (
        <ConfirmDeletionOverlay
          isDeleting={isDeletingComment}
          onCancel={() => {
            setConfirmDeletion(false);
          }}
          onDelete={() => {
            deleteComment({
              appliesToSurvey: !comment.questionId,
              commentId: comment.id,
            });
          }}
        />
      )}

      <CommentHeader
        canResolve={canResolve}
        comment={comment}
        hasReplies={hasReplies}
        onClickDelete={() => {
          setConfirmDeletion(true);
        }}
        onClickEdit={() => {
          setIsEditing(true);
        }}
        onClickResolve={() => {
          setConfirmResolve(true);
        }}
      />
      <div className="mt-1">
        {isEditing ? (
          <CommentEditing
            isSubmitting={isEditingComment}
            onCancel={() => {
              setIsEditing(false);
            }}
            onSubmit={() => {
              editComment({
                appliesToSurvey: !comment.questionId,
                commentId: comment.id,
                data: { comment: text },
              });
            }}
            setText={setText}
            showActions={true}
            submitText="Edit"
            text={text}
          />
        ) : (
          <p className="text-sm text-gray-900 leading-4 break-word">
            {comment.comment}
          </p>
        )}
      </div>
    </div>
  );
};

const CommentHeader = ({
  canResolve = false,
  comment,
  hasReplies = false,
  onClickDelete,
  onClickEdit,
  onClickResolve,
}: {
  canResolve?: boolean;
  comment: IComment;
  hasReplies?: boolean;
  onClickDelete(): void;
  onClickEdit(): void;
  onClickResolve(): void;
}): JSX.Element => {
  const { user } = useAuth();
  const { id: userId } = user || {};
  const { createdAt, creator, creatorId } = comment;

  const { refs, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset({ mainAxis: 8 }), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const menuItems: ReactNode[] = [];
  if (canResolve) {
    menuItems.push(
      <Menu.Item
        key="0"
        as="a"
        className="cursor-pointer block py-2 px-4 ui-active:bg-gray-300 text-sm"
        onClick={onClickResolve}
      >
        Resolve
      </Menu.Item>,
    );
  }

  if (userId === creatorId) {
    menuItems.push(
      <Fragment key="1">
        <Menu.Item
          as="a"
          className="cursor-pointer block py-2 px-4 ui-active:bg-gray-300 text-sm border-t border-gray-300"
          onClick={onClickEdit}
        >
          Edit
        </Menu.Item>
        {!hasReplies && (
          <Menu.Item
            as="a"
            className="cursor-pointer block py-2 px-4 ui-active:bg-gray-300 text-sm border-t border-gray-300"
            onClick={onClickDelete}
          >
            Delete
          </Menu.Item>
        )}
      </Fragment>,
    );
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex space-x-2 items-center">
        <div className="shrink-0">
          <UserBubble user={{ ...creator, id: creatorId }} />
        </div>
        <div className="text-sm font-semibold">
          {creator.firstName} {creator.lastName}
        </div>
      </div>

      <div className="flex items-center space-x-2 shrink-0">
        <div className="text-dark-grey text-xs">
          {formatDate(createdAt, {
            format: DATE_FORMATS.DATETIME,
          })}
        </div>

        {menuItems.length > 0 && (
          <Menu>
            <Menu.Button
              ref={refs.setReference}
              className="flex items-center justify-center w-8 h-8 border-gray-400 border bg-white rounded-lg"
            >
              <div className="w-4 h-4">
                <Icon id="dots-vertical" />
              </div>
            </Menu.Button>
            <FloatingPortal>
              <Menu.Items
                ref={refs.setFloating}
                className="z-40 w-64 rounded bg-white overflow-hidden shadow-md"
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                {menuItems}
              </Menu.Items>
            </FloatingPortal>
          </Menu>
        )}
      </div>
    </div>
  );
};

const CommentEditing = ({
  autofocus = false,
  isSubmitting,
  onCancel,
  onFocus,
  onSubmit,
  placeholder = '',
  preActionContent = null,
  setText,
  showActions = false,
  submitText,
  text,
}: {
  autofocus?: boolean;
  isSubmitting: boolean;
  onCancel?(): void;
  onFocus?: () => void;
  onSubmit(): void;
  placeholder?: string;
  preActionContent?: ReactNode;
  setText(text: string): void;
  showActions?: boolean;
  submitText: string;
  text: string;
}): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (autofocus && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [autofocus]);

  return (
    <div>
      <TextareaAutosize
        ref={textareaRef}
        onChange={(event) => {
          setText(event.currentTarget.value);
        }}
        onFocus={onFocus}
        placeholder={placeholder}
        size="md"
        value={text}
      />
      {preActionContent}
      {showActions && (
        <div className="flex mt-2 gap-2 flex-row-reverse">
          <ButtonLoading
            hierarchy="primary"
            isLoading={isSubmitting}
            onClick={onSubmit}
            size="sm"
            type="button"
          >
            {submitText}
          </ButtonLoading>
          {onCancel && (
            <Button
              hierarchy="secondary-gray"
              onClick={onCancel}
              size="sm"
              type="button"
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const ConfirmOverlay = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div className="absolute inset-0 bottom-auto w-full min-h-full p-2 shadow-md z-10 flex flex-col items-center justify-center">
      <div className="absolute inset-0 w-full h-full bg-white opacity-95 -z-10" />

      {children}
    </div>
  );
};

const ConfirmDeletionOverlay = ({
  isDeleting,
  onCancel,
  onDelete,
}: {
  isDeleting: boolean;
  onCancel(): void;
  onDelete(): void;
}): JSX.Element => {
  return (
    <ConfirmOverlay>
      <p className="text-sm text-center">Are you sure?</p>
      <div className="flex flex-row-reverse justify-center mt-4 mx-4 gap-3">
        <ButtonLoading
          hierarchy="destructive"
          isLoading={isDeleting}
          onClick={onDelete}
          size="sm"
          type="button"
        >
          Delete
        </ButtonLoading>
        <Button
          hierarchy="secondary-gray"
          onClick={onCancel}
          size="sm"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </ConfirmOverlay>
  );
};

const ConfirmResolveOverlay = ({
  isResolving,
  onCancel,
  onResolve,
}: {
  isResolving: boolean;
  onCancel(): void;
  onResolve(): void;
}): JSX.Element => {
  return (
    <ConfirmOverlay>
      <p className="text-sm text-center">Are you sure?</p>
      <div className="flex flex-row-reverse justify-center mt-4 mx-4 gap-3">
        <ButtonLoading
          hierarchy="primary"
          isLoading={isResolving}
          onClick={onResolve}
          size="sm"
          type="button"
        >
          Resolve
        </ButtonLoading>
        <Button
          hierarchy="secondary-gray"
          onClick={onCancel}
          size="sm"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </ConfirmOverlay>
  );
};
