import { array, object, string } from 'yup';
import { clsx } from 'clsx';
import { ErrorMessage, Form, Formik, useField, useFormikContext } from 'formik';
import { isEqual } from 'lodash-es';
import { ReactNode, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { EndMessage, Survey } from '../../types/domainModels';
import { getNestedErrorMessages } from 'util/forms';
import { showErrorMessage } from '../../util/notifications';
import { surveyQueries, useUpdateSurvey } from 'hooks/backend/surveys';
import { useSubmitValidation } from '../../hooks/forms';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from '../common/forms/FormGroupNew';
import FormLabel from 'components/common/forms/FormLabel';
import InputFormik from 'components/common/forms/InputFormik';
import Select from 'components/common/forms/Select';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';
import TextareaFormik from 'components/common/forms/TextareaFormik';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

interface CustomizeFormData {
  buttonText: { advance: string; done: string };
  endMessages: EndMessage[];
}

const CustomizeSchema = object().shape({
  buttonText: object().shape({
    advance: string()
      .max(30, 'Can be at most 30 characters.')
      .required('Can not be empty.'),
    done: string()
      .max(30, 'Can be at most 30 characters.')
      .required('Can not be empty.'),
  }),
  endMessages: array(
    object().shape({
      title: string()
        .max(48, 'Title can be at most 48 characters.')
        .required('Title is required.'),
    }),
  ),
});

const CustomizeStep = ({
  isLoadingSurvey,
  isShowingUnsavedChanges,
  onCustomizeDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onSurveySaved,
  survey,
}: {
  isLoadingSurvey: boolean;
  isShowingUnsavedChanges: boolean;
  onCustomizeDirtyChanged(dirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onSurveySaved(): void;
  survey: Survey | undefined;
}) => {
  if (isLoadingSurvey) {
    return <SkeletonSurveyCard />;
  }

  return survey ? (
    <CustomizeStepLoaded
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onCustomizeDirtyChanged={onCustomizeDirtyChanged}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasError}
      onSurveySaved={onSurveySaved}
      survey={survey}
    />
  ) : (
    <p>Failed to load the survey.</p>
  );
};

export default CustomizeStep;

const CustomizeStepLoaded = ({
  isShowingUnsavedChanges,
  onCustomizeDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onSurveySaved,
  survey,
}: {
  isShowingUnsavedChanges: boolean;
  onCustomizeDirtyChanged(dirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onSurveySaved(): void;
  survey: Survey;
}) => {
  const queryClient = useQueryClient();

  const initialValues = {
    buttonText: {
      advance: survey.customizations?.buttonText?.advance || 'Next',
      done: survey.customizations?.buttonText?.done || 'Done',
    },
    endMessages: survey.customizations?.endMessages ?? [
      {
        subtitle:
          'We appreciate your interest in this survey. However, it is not currently accepting responses.',
        title: 'Thank you',
        type: 'closed',
      },
      {
        subtitle: 'Thanks for completing this survey.',
        title: "You're all done!",
        type: 'completed',
      },
      {
        subtitle: "You didn't qualify in the audience for this campaign.",
        title: "Sorry, you didn't qualify",
        type: 'disqualified',
      },
    ],
  } satisfies CustomizeFormData;

  const { isPending: isUpdatingSurvey, mutate: updateSurvey } = useUpdateSurvey(
    {
      onError: (err) => {
        showErrorMessage(
          `There was an error attempting to save your changes. Error: ${err.message}`,
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          surveyQueries.survey({ surveyId: survey.id }),
        );

        onSurveySaved();
      },
    },
  );

  return (
    <Formik<CustomizeFormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(formData) => {
        updateSurvey({
          data: {
            customizations: {
              buttonText: formData.buttonText,
              endMessages: formData.endMessages,
            },
          },
          surveyId: survey.id,
        });
      }}
      validateOnChange={false}
      validationSchema={CustomizeSchema}
    >
      <Form className="h-full">
        <CustomizeForm
          initialValues={initialValues}
          isLoading={isUpdatingSurvey}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onCustomizeDirtyChanged={onCustomizeDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasError}
        />
      </Form>
    </Formik>
  );
};

const CustomizeForm = ({
  initialValues,
  isLoading,
  isShowingUnsavedChanges,
  onCustomizeDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
}: {
  initialValues: CustomizeFormData;
  isLoading: boolean;
  isShowingUnsavedChanges: boolean;
  onCustomizeDirtyChanged(dirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
}) => {
  const { dirty, values } = useFormikContext<CustomizeFormData>();

  const { errors, onClickSubmit, validateAndSubmit } =
    useSubmitValidation<CustomizeFormData>({
      isSaving: isLoading,
      onHasError,
    });

  const hasDirtyCustomizations = dirty && !isEqual(initialValues, values);
  useEffect(() => {
    onCustomizeDirtyChanged(hasDirtyCustomizations);
  }, [hasDirtyCustomizations, onCustomizeDirtyChanged]);

  const nestedErrors = errors ? getNestedErrorMessages(errors) : [];

  return (
    <div>
      {nestedErrors.length > 0 && (
        <div className="mb-8">
          <FormErrorsAlert actionWord="launching" errors={nestedErrors} />
        </div>
      )}

      {isShowingUnsavedChanges && (
        <UnsavedChangesModal
          isSaving={isLoading}
          onClickDiscardChanges={onDiscardChanges}
          onClickSaveChanges={validateAndSubmit}
          onCloseModal={onDismissUnsavedChanges}
        />
      )}

      <SurveyStepStickyHeader>
        <h2 className="text-base font-semibold">Customize</h2>

        <ButtonLoading
          disabled={nestedErrors.length > 0}
          hierarchy="primary"
          isLoading={isLoading}
          onClick={onClickSubmit}
          size="sm"
          // This can't currently be a submit button since we handle the form submission
          // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
          type="button"
        >
          Save Changes
        </ButtonLoading>
      </SurveyStepStickyHeader>

      <Customizations />
    </div>
  );
};

const END_MESSAGE_TYPE_DISPLAYS: Record<EndMessage['type'], string> = {
  closed: 'Survey Closed',
  completed: 'Survey Completed',
  disqualified: 'Respondent Disqualified',
};

const ConstrainedLengthTextField = ({
  children,
  label,
  maxLength,
  name,
  value,
}: {
  children: ReactNode;
  label: string;
  maxLength: number;
  name: string;
  value: string;
}) => {
  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      {children}
      <div className="flex items-center justify-between">
        <FormFieldError error={<ErrorMessage name={name} />} />
        <div
          className={clsx('text-gray-d-500 text-right text-xs', {
            'text-red font-semibold': value.length > maxLength,
          })}
        >
          {value.length} / {maxLength} characters
        </div>
      </div>
    </FormGroup>
  );
};

const Customizations = () => {
  const [{ value: buttonText }] =
    useField<CustomizeFormData['buttonText']>('buttonText');
  const [{ value: endMessages }] =
    useField<CustomizeFormData['endMessages']>('endMessages');
  const [endMessageIndex, setEndMessageIndex] = useState(0);

  const currentEndMessage = endMessages[endMessageIndex];
  const endMessagesOptions = endMessages.map(({ type }) => {
    return {
      label: END_MESSAGE_TYPE_DISPLAYS[type],
      value: type,
    };
  });

  return (
    <div className="space-y-4 divide-y divide-gray-d-200">
      <div className="pb-6 space-y-6">
        <div className="space-y-1">
          <span className="text-gray-d-800 font-medium">
            Modify the message your audience gets to see.
          </span>
          <p className="text-gray-d-700">
            Customize your Messages for a personalized survey experience
          </p>
        </div>
        <div className="space-y-4 w-1/2">
          <FormGroup>
            <FormLabel>Type of Message</FormLabel>
            <Select
              onChange={(value) => {
                setEndMessageIndex(
                  endMessages.findIndex(({ type }) => type === value?.value),
                );
              }}
              options={endMessagesOptions}
              value={endMessagesOptions[endMessageIndex]}
            />
          </FormGroup>
          <ConstrainedLengthTextField
            key={`endMessages.${endMessageIndex}.title`}
            label="Title"
            maxLength={48}
            name={`endMessages.${endMessageIndex}.title`}
            value={currentEndMessage.title}
          >
            <InputFormik
              name={`endMessages.${endMessageIndex}.title`}
              size="md"
              type="text"
            />
          </ConstrainedLengthTextField>
          <FormGroup>
            <FormLabel>Subtitle (Optional)</FormLabel>
            <TextareaFormik
              name={`endMessages.${endMessageIndex}.subtitle`}
              rows={2}
              size="md"
            />
          </FormGroup>
        </div>
      </div>
      <div className="pt-6 space-y-6">
        <div className="space-y-1">
          <span className="text-gray-d-800 font-medium">
            Modify Button Text
          </span>
          <p className="text-gray-d-700">
            Customize the text on survey buttons.
          </p>
        </div>
        <div className="space-y-4 w-1/2">
          <ConstrainedLengthTextField
            key="buttonText.advance"
            label="Advance Survey Button"
            maxLength={30}
            name="buttonText.advance"
            value={buttonText.advance}
          >
            <InputFormik name="buttonText.advance" size="md" type="text" />
          </ConstrainedLengthTextField>
          <ConstrainedLengthTextField
            key="buttonText.done"
            label="Complete Survey Button"
            maxLength={30}
            name="buttonText.done"
            value={buttonText.done}
          >
            <InputFormik name="buttonText.done" size="md" type="text" />
          </ConstrainedLengthTextField>
        </div>
      </div>
    </div>
  );
};
