import { Form, Formik, useField, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  apiDataToFormData,
  formDataToApiData,
  getQuestionNumber,
  getValuesForOptionTypeChange,
  getValuesForQuestionTypeChange,
  shouldForceOptionTypeToText,
  validateQuestionFormData,
} from '../../util/questions';
import { getNestedErrorMessages } from 'util/forms';
import { getOptionTypes, QUESTION_TYPE_OPTIONS } from '../../util/formOptions';
import { getTestLinks } from 'util/surveys';
import { hasReferencesForResource } from 'util/references';
import {
  Question,
  QUESTION_TYPE,
  QuestionBlock,
  Survey,
  SurveyVariable,
} from '../../types/domainModels';
import { QuestionFormData, QuestionFormDataValidated } from '../../types/forms';
import {
  questionQueries,
  useDeleteQuestion,
  useSaveQuestion,
  useToggleQuestionActivation,
} from 'hooks/backend/questions';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { surveyQueries } from 'hooks/backend/surveys';
import { useHasRole } from 'hooks/users';
import { useModal } from '../../hooks/modals';
import { useSubmitValidation } from '../../hooks/forms';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import CreateQuestionTemplate from './CreateQuestionTemplate';
import DeleteQuestion from './DeleteQuestion';
import DuplicateQuestion from './DuplicateQuestion';
import ErrorDisplay from '../common/ErrorDisplay';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import FormTextarea from '../common/forms/FormTextarea';
import GaborGranger from './questionTypes/GaborGranger';
import Icon from 'components/common/Icon';
import IconBackground from 'components/common/icons/IconBackground';
import IdeaPresenter from './questionTypes/IdeaPresenter';
import Matrix from './questionTypes/Matrix';
import MultipleChoice from './questionTypes/MultipleChoice';
import OpenEnded from './questionTypes/OpenEnded';
import PipeResponsesPopover from './PipeResponsesPopover';
import PreventDeleteResourceModal from './PreventDeleteResourceModal';
import Ranking from './questionTypes/Ranking';
import ScaleQuestion from './questionTypes/ScaleQuestion';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SliderToggle from '../common/SliderToggle';
import SurveyEditRow from './SurveyEditRow';
import SurveyEditRowLeftSide from './SurveyEditRowLeftSide';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';

interface QuestionFormProps {
  isDeletingQuestion: boolean;
  isSavingQuestion: boolean;
  isShowingUnsavedChanges: boolean;
  onConfirmDelete(questionId: number): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onDuplicate(formData: QuestionFormData): void;
  onHasError(): void;
  onQuestionDirtyChanged(isDirty: boolean): void;
  onToggleQuestion(questionId: number): void;
  question: Question | undefined;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  survey: Survey;
  variables: SurveyVariable[];
}

type QuestionStepLoadedProps = Omit<
  QuestionFormProps,
  | 'isDeletingQuestion'
  | 'isSavingQuestion'
  | 'onConfirmDelete'
  | 'onToggleQuestion'
> & {
  initialFormData: QuestionFormData | null;
  onQuestionDeleted(): void;
  onQuestionSaved(question: Question): void;
};

const QuestionsStep = ({
  isLoadingSurvey,
  isLoadingQuestions,
  loadQuestionsError,
  survey,
  ...rest
}: Omit<QuestionStepLoadedProps, 'survey'> & {
  isLoadingSurvey: boolean;
  isLoadingQuestions: boolean;
  loadQuestionsError: Error | null;
  survey: Survey | undefined;
}) => {
  if (isLoadingSurvey || isLoadingQuestions) {
    return <SkeletonSurveyCard />;
  }

  return survey && !loadQuestionsError ? (
    <QuestionsStepLoaded survey={survey} {...rest} />
  ) : (
    <ErrorDisplay
      message={`Failed to load the survey. ${
        loadQuestionsError && ` Error: ${loadQuestionsError.message}`
      }`}
    />
  );
};

export default QuestionsStep;

const QuestionsStepLoaded = ({
  initialFormData,
  onHasError,
  onQuestionDeleted,
  onQuestionSaved,
  question,
  questions,
  survey,
  ...rest
}: QuestionStepLoadedProps): JSX.Element => {
  const queryClient = useQueryClient();

  const { isPending: isSavingQuestion, mutateAsync: saveQuestion } =
    useSaveQuestion({
      onError: (err) => {
        onHasError();

        showErrorMessage(
          `There was an error saving the question. Error: ${err.message}`,
        );
      },
      onSuccess: (data) => {
        showSuccessMessage('The question was saved successfully.');
        onQuestionSaved(data);
      },
    });

  const { mutate: toggleQuestionActivation } = useToggleQuestionActivation({
    onError: (err) => {
      showErrorMessage(`Failed to update question. Error: ${err.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );
      queryClient.invalidateQueries(
        questionQueries.forSurvey({ surveyId: survey.id }),
      );
    },
  });

  const { isPending: isDeletingQuestion, mutate: deleteQuestion } =
    useDeleteQuestion({
      onError: (err) => {
        showErrorMessage(
          `There was an error deleting the question. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        onQuestionDeleted();
      },
    });

  const questionFormData = apiDataToFormData({ question, questions });

  const numCustomQuestions = questions.filter((q) => !q.isDemographic).length;
  // If there's no active question, that means we're adding a new question, and that
  // new question will have the next question number.
  const lastQuestionNumber = question
    ? numCustomQuestions
    : numCustomQuestions + 1;

  // Currently, the initialFormData is only populated when we're duplicating a
  // question. If we actually have a saved question, we always want to use the
  // form data we construct from the API data.
  const initialValues = question
    ? questionFormData
    : (initialFormData ?? questionFormData);

  // If we're editing a question, we want to show any initial errors on load.
  const initialErrors = question
    ? validateQuestionFormData({
        formData: initialValues,
        isEdit: true,
        lastQuestionNumber,
        survey,
      })
    : {};

  return (
    <Formik<QuestionFormData>
      enableReinitialize={true}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onSubmit={(formData) => {
        const data = formDataToApiData({
          formData: formData as QuestionFormDataValidated,
          survey,
        });

        return question
          ? saveQuestion({ data, questionId: question.id })
          : saveQuestion({ data });
      }}
      validate={(formData) => {
        return validateQuestionFormData({
          formData,
          isEdit: !!question,
          lastQuestionNumber,
          survey,
        });
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className="h-full">
        <QuestionForm
          {...rest}
          isDeletingQuestion={isDeletingQuestion}
          isSavingQuestion={isSavingQuestion}
          onConfirmDelete={(questionId) => {
            deleteQuestion({ questionId, surveyId: survey.id });
          }}
          onHasError={onHasError}
          onToggleQuestion={(questionId) => {
            toggleQuestionActivation({ questionId });
          }}
          question={question}
          questions={questions}
          survey={survey}
        />
      </Form>
    </Formik>
  );
};

export interface QuestionReferenceForOption {
  question: string;
  references: {
    carryForward: boolean;
    displayLogic: boolean;
    displayLogicOptions: string[];
  };
}

const QuestionFormHeader = ({
  isActive,
  isDeletingQuestion,
  onConfirmDelete,
  onDuplicate,
  onToggleQuestion,
  question,
  questionBlocks,
  questions,
  sort,
  setIsPreventRemoveModalOpen,
  survey,
  variables,
}: {
  isActive: boolean;
  isDeletingQuestion: boolean;
  onConfirmDelete?(id: number): void;
  onDuplicate(formData: QuestionFormData): void;
  onToggleQuestion(questionId: number): void;
  question?: Question;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  setIsPreventRemoveModalOpen(val: boolean): void;
  sort: number;
  survey: Survey;
  variables: SurveyVariable[];
}) => {
  return (
    <div className="text-lg font-semibold flex space-x-6">
      <span>Question {sort}</span>

      {question && (
        <div className="flex items-center space-x-4">
          <div className="flex space-x-2">
            <a
              href={
                getTestLinks({
                  startingQuestionID: question.id,
                  survey,
                }).testLink
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconBackground tooltip="Preview Current Question">
                <div className="w-4 h-4">
                  <Icon id="eye" />
                </div>
              </IconBackground>
            </a>
            <DuplicateQuestion
              onDuplicate={onDuplicate}
              questions={questions}
            />
            <CreateQuestionTemplate question={question} questions={questions} />
            {onConfirmDelete && (
              <DeleteQuestion
                isDeleting={isDeletingQuestion}
                onConfirmDelete={onConfirmDelete}
                questionBlocks={questionBlocks}
                questionId={question.id}
                questions={questions}
                surveyVariables={variables}
              />
            )}
          </div>

          <SliderToggle
            isFluidWidth={false}
            labels={['Active', 'Inactive']}
            onChange={() => {
              // If we're activating a question, we don't want to prevent the action
              // if there are other references to this question.
              if (!isActive) {
                onToggleQuestion(question.id);
                return;
              }

              const resource = { id: question.id, type: 'question' as const };

              const hasOtherReferences = hasReferencesForResource({
                questionBlocks,
                questions,
                resource,
                variables,
              });
              if (hasOtherReferences) {
                setIsPreventRemoveModalOpen(true);
                return;
              }

              onToggleQuestion(question.id);
            }}
            value={isActive}
          />
        </div>
      )}
    </div>
  );
};

const QuestionForm = ({
  isDeletingQuestion,
  isSavingQuestion,
  isShowingUnsavedChanges,
  onConfirmDelete,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onDuplicate,
  onHasError,
  onQuestionDirtyChanged,
  onToggleQuestion,
  question,
  questionBlocks,
  questions,
  survey,
  variables,
}: QuestionFormProps) => {
  const [{ value: isActive }] =
    useField<QuestionFormData['isActive']>('isActive');
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');
  const [{ value: questionType }] =
    useField<QuestionFormData['questionType']>('questionType');
  const [{ value: features }] =
    useField<QuestionFormData['features']>('features');
  const [{ value: concepts }] =
    useField<QuestionFormData['concepts']>('concepts');

  const hidePipeTimeout = useRef<number | null>(null);
  const interactingWithPipe = useRef<boolean>(false);
  const titleRef = useRef<HTMLTextAreaElement | null>(null);

  const { dirty, values, setValues } = useFormikContext<QuestionFormData>();
  const [{ value: title }, , titleHelpers] =
    useField<QuestionFormData['title']>('title');

  const [isPipeResponsesOpen, setIsPipeResponsesOpen] = useState(false);
  const [isPipeResponsesVisible, setIsPipeResponsesVisible] = useState(false);

  function internalSetIsPipeOpen(isOpen: boolean) {
    // We keep track of this state separately in a ref so we can check this value
    // when the title textarea loses focus and hide the pipe responses option accordingly.
    interactingWithPipe.current = isOpen;

    setIsPipeResponsesOpen(isOpen);
  }

  useEffect(() => {
    return () => {
      if (hidePipeTimeout.current) {
        window.clearTimeout(hidePipeTimeout.current);
      }
    };
  }, []);

  const questionNumber = getQuestionNumber({
    isWaitingForUnlock: isSavingQuestion && !question,
    question,
    questions,
  });

  const {
    isOpen: isPreventRemoveModalOpen,
    onCloseModal,
    setIsOpen: setIsPreventRemoveModalOpen,
  } = useModal();

  const { errors, onClickSubmit, validateAndSubmit } = useSubmitValidation({
    isSaving: isSavingQuestion,
    onHasError,
  });

  useEffect(() => {
    onQuestionDirtyChanged(dirty);
  }, [dirty, onQuestionDirtyChanged]);

  const isAdmin = useHasRole('admin');

  return (
    <div>
      {isShowingUnsavedChanges && (
        <UnsavedChangesModal
          isSaving={isSavingQuestion}
          onClickDiscardChanges={onDiscardChanges}
          onClickSaveChanges={validateAndSubmit}
          onCloseModal={onDismissUnsavedChanges}
        />
      )}

      <SurveyStepStickyHeader>
        <QuestionFormHeader
          isActive={isActive}
          isDeletingQuestion={isDeletingQuestion}
          onConfirmDelete={onConfirmDelete}
          onDuplicate={onDuplicate}
          onToggleQuestion={onToggleQuestion}
          question={question}
          questionBlocks={questionBlocks}
          questions={questions}
          setIsPreventRemoveModalOpen={setIsPreventRemoveModalOpen}
          sort={questionNumber}
          survey={survey}
          variables={variables}
        />

        <ButtonLoading
          hierarchy="primary"
          isLoading={isSavingQuestion}
          onClick={onClickSubmit}
          size="sm"
          // This can't currently be a submit button since we handle the form submission
          // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
          type="button"
        >
          Save Question
        </ButtonLoading>
      </SurveyStepStickyHeader>

      {errors && (
        <div className="my-4">
          <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
        </div>
      )}

      <SurveyEditRow title="Title">
        <FormTextarea
          ref={titleRef}
          name="title"
          onBlur={() => {
            hidePipeTimeout.current = window.setTimeout(() => {
              if (!interactingWithPipe.current) {
                setIsPipeResponsesVisible(false);
              }
            }, 200);
          }}
          onFocus={() => {
            if (questionNumber > 1) {
              setIsPipeResponsesVisible(true);
            }
          }}
          size="md"
        />
        {isPipeResponsesVisible && (
          <div className="">
            <PipeResponsesPopover
              isOpen={isPipeResponsesOpen}
              onResponseChosen={(response) => {
                if (titleRef.current) {
                  const cursorPosition = titleRef.current.selectionStart;

                  titleHelpers.setValue(
                    `${title.substring(
                      0,
                      cursorPosition,
                    )}${response}${title.substring(cursorPosition)}`,
                  );

                  titleRef.current.focus();
                } else {
                  titleHelpers.setValue(`${title}${response}`);
                }

                internalSetIsPipeOpen(false);
              }}
              question={question}
              questions={questions}
              setIsOpen={internalSetIsPipeOpen}
            />
          </div>
        )}
      </SurveyEditRow>

      {!question?.isDemographic && (
        <SurveyEditRow subtitle="(optional)" title="Question Label">
          <FormInput name="label" size="md" type="text" />
        </SurveyEditRow>
      )}

      <SurveyEditRow subtitle="(optional)" title="Directions">
        <FormInput name="description" size="md" type="text" />
      </SurveyEditRow>

      <div className="flex space-x-5 py-6 border-b border-gray-300">
        <div className="flex flex-col flex-1">
          <SurveyEditRowLeftSide title="Question Type" />
          <FormSearchSelectInput
            name="questionType"
            onChange={(newQuestionType) => {
              if (
                newQuestionType &&
                newQuestionType.value !== values.questionType?.value
              ) {
                setValues(
                  getValuesForQuestionTypeChange({
                    currentValues: values,
                    isAdmin,
                    newQuestionType,
                    question,
                    questions,
                  }),
                );
              }
            }}
            options={QUESTION_TYPE_OPTIONS}
          />
        </div>
        <div className="flex flex-col flex-1">
          <SurveyEditRowLeftSide
            title={
              questionType?.value === QUESTION_TYPE.OPEN_ENDED
                ? 'Response Type'
                : 'Option Type'
            }
          />
          <FormSearchSelectInput
            isDisabled={shouldForceOptionTypeToText(questionType?.value)}
            name="optionType"
            onChange={(newOptionType) => {
              if (
                newOptionType &&
                newOptionType.value !== values.optionType?.value
              ) {
                setValues(
                  getValuesForOptionTypeChange({
                    currentValues: values,
                    newOptionType,
                  }),
                );
              }
            }}
            options={getOptionTypes({
              isAdmin,
              questionType: questionType?.value,
            })}
          />
        </div>
      </div>

      {optionType?.value && (
        <>
          {questionType?.value === QUESTION_TYPE.MULTIPLE_CHOICE && (
            <MultipleChoice
              concepts={concepts}
              pipeConcept={features?.pipeConcept?.enabled}
              question={question}
              questionBlocks={questionBlocks}
              questions={questions}
              variables={variables}
            />
          )}
          {questionType?.value === QUESTION_TYPE.OPEN_ENDED && (
            <OpenEnded
              concepts={concepts}
              pipeConcept={features?.pipeConcept?.enabled}
              question={question}
              questionBlocks={questionBlocks}
              questions={questions}
            />
          )}
          {questionType?.value === QUESTION_TYPE.SCALE && (
            <ScaleQuestion
              concepts={concepts}
              pipeConcept={features?.pipeConcept?.enabled}
              question={question}
              questionBlocks={questionBlocks}
              questions={questions}
              variables={variables}
            />
          )}
          {questionType?.value === QUESTION_TYPE.RANKING && (
            <Ranking
              concepts={concepts}
              pipeConcept={features?.pipeConcept?.enabled}
              question={question}
              questionBlocks={questionBlocks}
              questions={questions}
              variables={variables}
            />
          )}
          {questionType?.value === QUESTION_TYPE.MATRIX && (
            <Matrix
              concepts={concepts}
              pipeConcept={features?.pipeConcept?.enabled}
              question={question}
              questionBlocks={questionBlocks}
              questions={questions}
              variables={variables}
            />
          )}
          {questionType?.value === QUESTION_TYPE.GABOR_GRANGER && (
            <GaborGranger
              concepts={concepts}
              pipeConcept={features?.pipeConcept?.enabled}
              question={question}
              questionBlocks={questionBlocks}
              questions={questions}
              survey={survey}
            />
          )}
          {questionType?.value === QUESTION_TYPE.IDEA_PRESENTER && (
            <IdeaPresenter
              concepts={concepts}
              question={question}
              questionBlocks={questionBlocks}
              questions={questions}
              variables={variables}
            />
          )}
        </>
      )}

      {question && isPreventRemoveModalOpen && (
        <PreventDeleteResourceModal
          onCloseModal={onCloseModal}
          questionBlocks={questionBlocks}
          questions={questions}
          resource={{ id: question.id, type: 'question' }}
          resourceTitle="Question"
          variables={variables}
        />
      )}
    </div>
  );
};
