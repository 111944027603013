export interface Audience {
  audienceSlices: {
    audienceSliceCategories: {
      audienceSliceCategoryAttributes: QuestionAudienceSliceCategoryAttribute[];
      conceptId: number | null;
      logicalModifier: AudienceLogicalModifier;
      percentage: string | null;
      question: {
        concepts: { description: string; id: number }[];
        contentTypeId: number;
        description: string | null;
        id: number;
        isDemographic: boolean;
        isStandard: boolean;
        matrixOptions: { id: number; sort: number; title: string }[];
        monadicId: number | null;
        options: {
          description: string;
          id: number;
          isActive: boolean;
          sort: number;
          title: string;
        }[];
        questionTypeId: number;
        sort: number;
        title: string;
      };
      questionId: number;
    }[];
  }[];
  id: number;
  title: string;
}

export type AudienceLogicalModifier = 'is' | 'isnt' | 'should' | 'shouldnt';

export interface Banner {
  bottomBoxIds?: number[];
  bottomBoxValue?: number;
  confidenceLevel: number | null;
  excludeQuestionIds: number[];
  exposedToQuestionIds: number[];
  id: number;
  questionSpecifications?: BannerQuestionSpecification[];
  stackComplexQuestions: boolean;
  surveyBannerSlices: {
    base: boolean;
    surveyBannerSliceCategories: {
      concept: { id: number; description: string } | null;
      question: { id: number } | null;
      surveyBannerSliceCategoryAttributes: {
        logicalModifier: ExportFilterModifier;
        surveyBannerSliceCategoryAttributeOptions: BannerSliceOption[];
      }[];
      variableId: number | null;
      useWaves: boolean;
    }[];
    title: string;
  }[];
  title: string;
  topBoxIds?: number[];
  topBoxValue?: number;
}

export interface BannerCompact {
  id: number;
}

interface BannerQuestionSpecification {
  questionId: number;
  type: 'average' | 'bottom_x_box' | 'top_x_box';
  value?: number;
}

export interface BannerSliceOption {
  conceptId: number;
  highOption: number;
  lowOption: number;
  matrixOptionId: number | null;
  optionId: number;
  segmentId: number | null;
  waveId: number | null;
}

export interface Comment {
  comment: string;
  createdAt: string;
  creator: {
    firstName: string;
    image: DataUrl | null;
    lastName: string;
  };
  creatorId: number;
  id: number;
  parentId: number | null;
  questionId?: number;
  surveyId?: number;
}

export interface DashboardSurvey {
  createdAt: string;
  id: number;
  numberOfCompletes: number;
  organizationId: number;
  participants: number;
  project: Tag | null;
  status: { name: SurveyStatusName };
  surveyLength: { name: string };
  title: string;
  user: {
    firstName: string;
    id: number;
    image: DataUrl | null;
    lastName: string;
  };
}

export interface DataUrl {
  format: string;
  public_id: string;
  resource_type: 'image' | 'video';
  secure_url: string;
  url: string;
  version: number;
}

export interface DisplayLogicConstraint {
  conceptId?: number | null;
  id: number;
  matrixOptionIds?: number[];
  numberRange?: { start: number; end: number };
  optionId?: number | null;
}

export type DisplayLogicLogicalModifier = 'is' | 'isnt' | 'should';

export interface EndMessage {
  subtitle: string;
  title: string;
  type: 'completed' | 'closed' | 'disqualified';
}

export interface Export {
  createdAt: string;
  crosstabName: string;
  creator: {
    firstName: string;
    lastName: string;
  };
  displayStatus: 'ACTIVE' | 'ARCHIVED';
  exportType: 'CROSSTAB' | 'RAW_DATA' | 'SPSS';
  id: number;
  status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETE' | 'FAILED';
  storageLocation: string | null;
}

export type ExportFilterModifier = 'is' | 'isnt' | 'should';

export type GaborGrangerFormat = 'CUSTOM' | 'DOLLARS' | 'PERCENT';
export type GaborGrangerObjective = 'MAX_SEEKING' | 'MIN_SEEKING';
export interface GaborGrangerSettings {
  format: GaborGrangerFormat;
  formatCustomText: string | null;
  increment: string;
  max: string;
  min: string;
  objective: GaborGrangerObjective;
  unitDecimals: number;
}

export interface IncidenceType {
  id: number;
  name:
    | '100-75%'
    | '74-50%'
    | '49-30%'
    | '29-20%'
    | '19-10%'
    | '9-5%'
    | '4-3%'
    | '2-1%';
  lucidEstimate: string;
}

export interface JwtPayload {
  baseOrganizationId?: number;
  email: string;
  iat: number;
  id: number;
  organizationId: number;
  role: Role;
}

export type LOI = '1-20' | '21-40' | '41-60';

export interface MatrixOption {
  id: number;
  isActive: boolean;
  isAnchored: boolean;
  isExclusive: boolean;
  isFreeText: boolean;
  sort: number;
  title: string;
  weight: number | null;
}

export enum OPTION_TYPE {
  IMAGE = 1,
  TEXT = 2,
}

export interface Organization {
  id: number;
}

export interface OrganizationV2 {
  id: number;
  name: string;
  useInvoice: boolean;
}

export type PanelProvider =
  | 'LUCID'
  | 'MINDFORCE'
  | 'MFOUR'
  | 'PURE_SPECTRUM'
  | 'REP_DATA'
  | 'ROI'
  | 'SYMMETRIC';

export interface Project {
  title: string;
}

export enum QUESTION_FEATURE {
  CARRY_FORWARD_DISPLAYED = 'COD01',
  CARRY_FORWARD_NOT_SELECTED = 'CON01',
  CARRY_FORWARD_SELECTED = 'COS01',
  DISPLAY_X_OF_Y = 'OPBLCK01',
  MULTIPLE_OPTION_SELECTIONS = 'QMCM01',
  MULTIPLE_RESPONSE_LOWER_LIMIT = 'MCLL01',
  MULTIPLE_RESPONSE_UPPER_LIMIT = 'MCUL01',
  MULTIPLE_RESPONSE = 'MRS01',
  NUMBER_TYPE = 'Number',
  OPEN_MATRIX = 'OM01',
  OPEN_MATRIX_INVERTED = 'OMI01',
  OPEN_MATRIX_SCROLL = 'OMS01',
  PIPE_CONCEPT = 'QPC01',
  RANDOMIZE_MATRIX_OPTIONS = 'RDM02',
  REVERSE_OPTIONS = 'RMO',
  RANDOMIZE_OPTIONS = 'RDM01',
  REQUIRED_SUM = 'SUM01',
  SINGLE_RESPONSE = 'SRS01',
  VIEW_ALL_IMAGES = 'VAL01',
  VIEW_CONCEPT = 'VAL02',
  VOXPOPME_FEATURE_1 = 'VoxpopmeFeature01',
  VOXPOPME_FEATURE_2 = 'VoxpopmeFeature02',
}

export enum QUESTION_OPTION_FEATURE {
  ANCHOR = 'QOA01',
  EXCLUSIVE = 'QOE01',
  FREE_TEXT = 'QOFT01',
  PRESERVE = 'PRSVOP01',
  REQUIRE_VIEW = 'VAL03',
}

export enum QUESTION_TYPE {
  GABOR_GRANGER = 14,
  IDEA_PRESENTER = 20,
  MATRIX = 12,
  MULTIPLE_CHOICE = 2,
  NUMBER = 10,
  OPEN_ENDED = 3,
  RANKING = 7,
  SCALE = 6,
  DATE = 9,
}

export interface Question {
  blockId: number | null;
  concepts?: QuestionConcept[];
  constraint?: {
    errorMessage?: string;
    range?: { start?: number; end?: number };
  };
  contentTypeId: OPTION_TYPE;
  description: string | null;
  displayOptionDescription: boolean;
  displayedConcept?: { id: number };
  gaborGrangerSettings?: GaborGrangerSettings;
  id: number;
  isActive: boolean;
  isDemographic: boolean;
  isStandard: boolean;
  label: string | null;
  matrixOptions: MatrixOption[];
  monadicId?: number;
  monadicBlockSequences?: number;
  options: QuestionOption[];
  questionAudiences?: QuestionAudience[];
  questionFeatures?: QuestionFeature[];
  questionQuotas?: {
    disqualificationType: QuestionQuotaDisqualificationType;
    id: number;
    logicalModifier: QuestionQuotaLogicalModifiers;
    numberNeeded: number;
    questionOptions: { id: number }[];
  }[];
  questionTypeId: number;
  sort: number;
  surveyId: number;
  title: string;
}

export interface QuestionAudience {
  audience: Audience;
}

export interface QuestionAudienceSliceCategoryAttribute {
  audienceAttribute: {
    enumNumberRange?: { end: number; start: number };
    enumValue: { description: string | null; title: string } | null;
    enumValueId: number;
    matrixOptionId?: number | null;
    // numberRange appears to be present for demographic audiences while enumNumberRange
    // is present for scale / ranking question audiences in display logic.
    numberRange?: { end: number; start: number };
  };
}

export type QuestionBase = 'exposedToOption' | 'exposedToQuestion';

export interface QuestionBlock {
  amountViewed: number | null;
  id: number;
  isRandomized: boolean;
  questionBlocks: ChildBlock[];
  surveyId: number;
  title: string;
}

export interface ChildBlock {
  displayLogic: {
    andGrouping: number;
    constraints: DisplayLogicConstraint[];
    logicalModifier: DisplayLogicLogicalModifier;
    questionId: number;
  }[];
  countInX: boolean;
  end: number;
  id: number;
  sort: number;
  start: number;
  title: string;
}

export interface QuestionConcept {
  audience: Audience[];
  description: string;
  id: number;
  isActive: boolean;
  media: DataUrl;
  preserved: boolean;
}

export type QuestionContentTypes = 'Image' | 'Text';

export type QuestionCountsOption = {
  id: number;
  metrics: { exposures: number; selections: number };
  sort: number;
  title: string;
};
export type QuestionCountsStatement = {
  id: number;
  metrics: { exposures: number };
  options: {
    id: number;
    metrics: { selections: number };
    sort: number;
    title: string;
  }[];
  sort: number;
  title: string;
};
export type QuestionResultsOpenEnded = {
  freeTextResponses: { respondentId: string; response: string }[];
  questionType: 'number' | 'openEnded';
  sort: number;
  title: string;
};
export type QuestionResults =
  | {
      counts: {
        concept: string;
        exposures: number;
        options: QuestionCountsOption[];
      }[];
      id: number;
      questionType:
        | 'gaborGranger'
        | 'ideaPresenter'
        | 'multipleChoice'
        | 'unaidedAwareness';
      sort: number;
      title: string;
    }
  | {
      counts: {
        concept: string;
        exposures: number;
        statements: QuestionCountsStatement[];
      }[];
      id: number;
      questionType: 'matrix' | 'ranking' | 'scale';
      sort: number;
      title: string;
    }
  | QuestionResultsOpenEnded;

export interface QuestionFeature {
  enumValue: number | null;
  feature: {
    code: QUESTION_FEATURE;
  };
  matrixOptionId: number | null;
  numberRange?: { end: number; start: number };
  regex: string | null;
}

export interface QuestionOption {
  anchored?: boolean;
  carryOverParentId: number | null;
  dataUrl: DataUrl | null;
  description: string | null;
  exclusive?: boolean;
  id: number;
  isActive: boolean;
  isFreeTextOption?: boolean;
  preserved?: boolean;
  questionOptionAudiences: QuestionAudience[];
  questionOptionFeatures: {
    feature: {
      code: QUESTION_OPTION_FEATURE;
    };
  }[];
  questionOptionQuotas: {
    quotaId: number;
  }[];
  rangeMax: number | null;
  rangeMin: number | null;
  rangeStep: number | null;
  scaleHighLabel: string | null;
  scaleLowLabel: string | null;
  scaleMiddleLabel: string | null;
  scaleTypeId: number | null;
  scaleUnitId: number | null;
  sort: number;
  title: string;
  viewRequired?: boolean;
  weight: number | null;
}

export type QuestionQuotaDisqualificationType = 'NON_QUALITY' | 'QUALITY';

export interface QuestionQuotaElement {
  disqualificationType: QuestionQuotaDisqualificationType;
  logicalModifier: QuestionQuotaLogicalModifiers;
  numberNeeded: number | '';
  optionIndices: number[];
}

export type QuestionQuotaLogicalModifiers =
  | 'at_most'
  | 'at_least'
  | 'all'
  | 'none';

export interface QuestionTemplate {
  createdAt: string;
  id: number;
  templateDescription: string | null;
  templateTitle: string | null;
  title: string;
  user: {
    id: number;
    firstName: string;
    image: DataUrl | Record<string, unknown> | null;
    lastName: string;
  };
}

export interface Quote {
  total: number;
}
export type RemoveRespondentsReason = 'nonQuality' | 'quality';

export type Role = 'admin' | 'user';

export interface Survey {
  audience: Audience;
  audienceId: number;
  customizations: {
    buttonText: SurveyButtonText | null;
    endMessages: EndMessage[];
  };
  estimatedBalance: string;
  generateNewExports: boolean;
  hash: string;
  id: number;
  incidenceType: IncidenceType;
  incidenceTypeId: number;
  isBringYourOwnAudience: boolean;
  organizationId: number;
  paidFor: boolean;
  panelProvider: PanelProvider | null;
  participants: number;
  projectId: number | null;
  qualityChecks: SurveyQualityCheck[];
  questions?: Question[];
  status: { id: number; name: SurveyStatusName };
  statusId: number;
  title: string;
  useAgeCensus: boolean;
  useEthnicityCensus: boolean;
  useGenderCensus: boolean;
  useNewSurveyEndpoint: boolean;
  useRaceCensus: boolean;
  useRegionCensus: boolean;
  userId: number;
  wave: SurveyWave;
  waveId: number;
}

export interface SurveyAudienceSlice {
  audienceSliceCategory: {
    audienceSliceCategoryAttributes: {
      audienceAttribute: {
        enumValue: { title: string } | null;
        numberRange?: { end: number; start: number };
      };
    }[];
    logicalModifier: AudienceLogicalModifier;
    question: { id: number; title: string };
  };
  numberCompleted: number;
  numberNeeded: number;
}

export interface SurveyButtonText {
  advance?: string;
  done?: string;
}

export interface SurveyComplete {
  completes: number;
  time: string;
}

export interface SurveyIncidence {
  // "current" is null if there are not yet any respondents.
  current: {
    // "percent" is a formatted percentage rounded to the nearest whole number.
    percent: string;
    // "proportion" is an exact decimal proportion.
    proportion: string;
  } | null;
  // "estimated" is null for previous waves of a survey - we only store the current
  // wave's estimated incidence.
  estimated: string | null;
}

export interface SurveyLength {
  id: number;
  name: LOI;
}

export interface SurveyQualityCheck {
  enabled: boolean;
  type:
    | 'bad_respondent'
    | 'copy_paste'
    | 'duplicate'
    | 'presurvey_questionnaire';
}

export interface SurveyStatus {
  name: SurveyStatusName;
  id: number;
}

export type SurveyStatusName = 'approved' | 'completed' | 'draft';

export interface SurveyTemplate {
  createdAt: string;
  description: string;
  id: number;
  questionsAmount: number;
  title: string;
  user: {
    firstName: string;
    id: number;
    image: DataUrl | null;
    lastName: string;
  };
}

export interface SurveyVariable {
  id: number;
  quotas: SurveyVariableQuota[];
  segments: SurveyVariableSegment[];
  surveyId: number;
  title: string;
}

export type SurveyVariableModifier = 'is' | 'isnt' | 'should';

export interface SurveyVariableQuota {
  id: number;
  numberNeeded: number | null;
  type: SurveyVariableQuotaType;
}

export interface SurveyVariableQuotaResult {
  count: number;
  id: number;
  numberNeeded: number | null;
  segments: { title: string }[];
  type: SurveyVariableQuotaType;
  variable: { id: number };
}

export type SurveyVariableQuotaType = 'at_most' | 'all' | 'none';

export interface SurveyVariableSegment {
  id: number;
  isUserSpecified: boolean;
  questions: {
    andGrouping: number;
    constraints: SurveyVariableSegmentConstraint[];
    logicalModifier: 'is' | 'isnt' | 'should';
    questionId: number;
  }[];
  quotaId: number | null;
  title: string;
}

export interface SurveyVariableSegmentConstraint {
  conceptId?: number | null;
  dateRange?: { start: Date | null; end: Date | null };
  matrixOptionIds?: number[] | null;
  numberRange?: { start: number; end: number };
  optionId?: number | null;
}

export interface SurveyVariableSegmentResults {
  count: number;
  percent: number;
  title: string;
}

export interface SurveyWave {
  completes: number;
  description: string;
  endedAt: string;
  id: number;
  startedAt: string;
  surveyId: number;
  target: number;
  title: string;
  waveValue: number;
}

export interface QuestionQuotaResult {
  count: number;
  id: number;
  logicalModifier: QuestionQuotaLogicalModifiers;
  numberNeeded: number | null;
  options: { id: number; sort: number; title: string }[];
  question: { id: number; title: string };
}

export interface Tag {
  id: number;
  title: string;
}

export interface TeamMember {
  email: string;
  firstName: string;
  id: number;
  image: DataUrl | Record<string, unknown>;
  lastName: string;
}

export type UploadSignature = {
  apiKey: string;
  signature: string;
  timestamp: number;
};

export interface User {
  id: number;
  image: DataUrl | Record<string, unknown>;
}

export interface UserV2 {
  email: string;
  firstName: string;
  hashFront: string;
  id: number;
  image: DataUrl | Record<string, unknown>;
  lastName: string;
  mobile: string;
}
