import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DataUrl, UserV2 } from '../../types/domainModels';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { UpdatePasswordSchema } from '../../util/auth';
import { useAuth } from '../../contexts/auth';
import { useUpdateUser } from 'hooks/backend/users';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import DefaultLayout from '../layout/DefaultLayout';
import FileUploadWithCrop from '../common/forms/FileUploadWithCrop';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import FormLabel from 'components/common/forms/FormLabel';
import Icon from 'components/common/Icon';
import IndexCard from 'components/common/IndexCard';
import Input from 'components/common/forms/Input';
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/Tabs';

const AccountSettingsPage = () => {
  const { user } = useAuth();

  return (
    <DefaultLayout>
      <div className="min-h-full pt-6 pb-32 px-8 bg-gray-50">
        <div className="flex items-center justify-between mb-10">
          <span className="text-xl text-primary-d-600 font-medium">
            Account Settings
          </span>
        </div>
        <div className="max-w-[50%]">
          <IndexCard>
            <TabGroup>
              <div className="p-4 pb-0 mb-5">
                <TabList size="md">
                  <Tab>My Info</Tab>
                  <Tab>Change Password</Tab>
                </TabList>
              </div>
              <TabPanels>
                <TabPanel>{user ? <MyInfoForm user={user} /> : null}</TabPanel>
                <TabPanel>
                  <ChangePasswordForm user={user} />
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </IndexCard>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AccountSettingsPage;

interface UserFormData {
  email: string;
  firstName: string;
  image: DataUrl | string;
  lastName: string;
  mobile: string;
}

const MyInfoForm = ({ user }: { user: UserV2 | undefined }): JSX.Element => {
  const { isPending: isUpdatingUserInfo, mutate: updateUser } = useUpdateUser({
    onError: (error: Error) => {
      showErrorMessage(`Error updating information. Error: ${error.message}`);
    },
    onSuccess: () => {
      showSuccessMessage('Your information was saved successfully.');
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<UserFormData>({
    defaultValues: {
      email: user?.email ?? '',
      firstName: user?.firstName ?? '',
      image: user?.image ?? '',
      lastName: user?.lastName ?? '',
      mobile: user?.mobile ?? '',
    },
  });

  const onSubmit: SubmitHandler<UserFormData> = (data) => {
    if (!user) {
      showErrorMessage(
        'Could not load your information to save the updates. Please refresh your page.',
      );
      return;
    }

    return updateUser({
      data: {
        ...data,
        image:
          typeof data.image === 'string' ? { url: data.image } : data.image,
      },
      userID: user.id,
    });
  };

  const image = watch('image');

  return (
    <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <MyInfoImage
          image={image}
          onImageChanged={(newImage) => {
            setValue('image', newImage);
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <FormGroup>
          <FormLabel labelFor="firstName">First Name</FormLabel>
          <Input
            {...register('firstName', {
              required: 'Please provide your first name.',
            })}
            id="firstName"
            size="lg"
            type="text"
          />
          {errors.firstName?.message && (
            <FormFieldError error={errors.firstName.message} />
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel labelFor="lastName">Last Name</FormLabel>
          <Input
            {...register('lastName', {
              required: 'Please provide your last name.',
            })}
            id="lastName"
            size="lg"
            type="text"
          />
          {errors.lastName?.message && (
            <FormFieldError error={errors.lastName.message} />
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel labelFor="email">Email</FormLabel>
          <Input
            {...register('email', {
              required: 'Please provide a valid email.',
            })}
            id="email"
            size="lg"
            type="email"
          />
          {errors.email?.message && (
            <FormFieldError error={errors.email.message} />
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel labelFor="phoneNumber">Phone Number</FormLabel>
          <Input
            {...register('mobile')}
            id="phoneNumber"
            size="lg"
            type="text"
          />
        </FormGroup>
      </div>
      <div className="flex justify-end mt-8">
        <ButtonLoading
          hierarchy="primary"
          isLoading={isUpdatingUserInfo}
          size="md"
        >
          Update
        </ButtonLoading>
      </div>
    </form>
  );
};

const MyInfoImage = ({
  image,
  onImageChanged,
}: {
  image: UserFormData['image'];
  onImageChanged(newImage: string): void;
}): JSX.Element => {
  const imageUrl = typeof image === 'string' ? image : image.secure_url;

  if (imageUrl) {
    return (
      <FormGroup>
        <FormLabel>Profile Image</FormLabel>
        <div className="flex items-center space-x-4">
          <div
            className="w-20 h-20 rounded-full bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />
          <FileUploadWithCrop
            onDone={(image) => {
              onImageChanged(image);
            }}
          >
            <Button
              hierarchy="secondary-gray"
              icon={<Icon id="pencil" />}
              iconPlacement="leading"
              size="sm"
              type="button"
            >
              Edit
            </Button>
          </FileUploadWithCrop>
          <Button
            hierarchy="secondary-gray"
            icon={<Icon id="trash" />}
            iconPlacement="leading"
            onClick={() => {
              onImageChanged('');
            }}
            size="sm"
            type="button"
          >
            Remove
          </Button>
        </div>
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <FormLabel>Profile Image</FormLabel>
      <FileUploadWithCrop
        onDone={(image) => {
          onImageChanged(image);
        }}
      >
        <Button
          hierarchy="secondary-gray"
          icon={<Icon id="plus" />}
          iconPlacement="leading"
          size="sm"
          type="button"
        >
          Upload
        </Button>
      </FileUploadWithCrop>
    </FormGroup>
  );
};

interface ChangePasswordFormData {
  password: string;
  retypepassword: string;
}

const ChangePasswordForm = ({
  user,
}: {
  user: UserV2 | undefined;
}): JSX.Element => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<ChangePasswordFormData>({
    defaultValues: {
      password: '',
      retypepassword: '',
    },
    resolver: yupResolver(UpdatePasswordSchema),
  });

  const { isPending: isChangingPassword, mutateAsync: changePassword } =
    useUpdateUser({
      onError: (error: Error) => {
        showErrorMessage(`Error changing password. Error: ${error.message}`);
      },
      onSuccess: () => {
        reset();
        showSuccessMessage('Your password was updated successfully.');
      },
    });

  const onSubmit: SubmitHandler<ChangePasswordFormData> = (data) => {
    if (!user) {
      showErrorMessage(
        'Could not load your information to save the updates. Please refresh your page.',
      );
      return;
    }

    return changePassword({ data, userID: user.id });
  };

  return (
    <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <FormGroup>
          <FormLabel labelFor="password">New Password</FormLabel>
          <Input
            {...register('password')}
            autoComplete="new-password"
            id="password"
            size="lg"
            type="password"
          />
          {errors.password?.message && (
            <FormFieldError error={errors.password.message} />
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel labelFor="retypepassword">Confirm New Password</FormLabel>
          <Input
            {...register('retypepassword')}
            autoComplete="new-password"
            id="retypepassword"
            size="lg"
            type="password"
          />
          {errors.retypepassword?.message && (
            <FormFieldError error={errors.retypepassword.message} />
          )}
        </FormGroup>
      </div>
      <div className="flex justify-end mt-8">
        <ButtonLoading
          hierarchy="primary"
          isLoading={isChangingPassword}
          size="md"
        >
          Change Password
        </ButtonLoading>
      </div>
    </form>
  );
};
