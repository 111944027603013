import {
  cloneDeep,
  compact,
  findLast,
  isArray,
  isEmpty,
  orderBy,
  partition,
  some,
} from 'lodash-es';
import { FormikErrors } from 'formik';

import {
  Audience as CreateQuestionAudience,
  CreateQuestionBody,
} from '../services/backend/questions';
import {
  Audience,
  GaborGrangerFormat,
  GaborGrangerObjective,
  MatrixOption,
  OPTION_TYPE,
  QUESTION_FEATURE,
  QUESTION_TYPE,
  Question,
  QuestionFeature,
  QuestionOption,
  Survey,
} from '../types/domainModels';
import {
  CARRY_FORWARD_MATRIX_ANY_OPTION,
  getCarryForwardTypeOptions,
  getOptionOption,
  getOptionTypes,
  getQuestionOption,
  QUESTION_QUOTA_LOGICAL_MODIFIER_OPTIONS,
  QUESTION_TYPE_OPTIONS,
} from './formOptions';
import {
  getApiDataForDisplayLogic,
  getFormDisplayLogic,
  validateDisplayLogic,
} from './displayLogic';
import { getMonadicBlockPromptQuestion } from './questionBlocks';
import { getOptionTitle, OPTION_FEATURES } from './options';
import { MediaWithPreview } from './media';
import { OptionType, QuestionGroup } from '../types/internal';
import { questionAudiencesToAudiences } from './audience';
import {
  QuestionFormData,
  QuestionFormDataValidated,
  QuestionFormOption,
  QuestionOptionValidated,
  QuestionQuota,
  Quota,
  ReactSelectValue,
} from '../types/forms';
import { SCALE_TYPES, SCALE_UNITS } from '../constants/scaleComponents';

const CARRY_FORWARD_CODES = [
  QUESTION_FEATURE.CARRY_FORWARD_DISPLAYED,
  QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED,
  QUESTION_FEATURE.CARRY_FORWARD_SELECTED,
];

const GABOR_GRANGER_FORMAT_DOLLARS_OPTION = {
  label: '$1,234.56',
  value: 'DOLLARS',
} satisfies ReactSelectValue<GaborGrangerFormat>;
export const GABOR_GRANGER_FORMAT_OPTIONS: ReactSelectValue<GaborGrangerFormat>[] =
  [
    GABOR_GRANGER_FORMAT_DOLLARS_OPTION,
    { label: '1,234.56%', value: 'PERCENT' },
    { label: '1234.56 {custom_text}', value: 'CUSTOM' },
  ];

const GABOR_GRANGER_OBJECTIVE_MAX_OPTION = {
  label: 'Seek Maximum Value',
  value: 'MAX_SEEKING',
} satisfies ReactSelectValue<GaborGrangerObjective>;
export const GABOR_GRANGER_OBJECTIVE_OPTIONS: ReactSelectValue<GaborGrangerObjective>[] =
  [
    GABOR_GRANGER_OBJECTIVE_MAX_OPTION,
    { label: 'Seek Minimum Value', value: 'MIN_SEEKING' },
  ];

type QuestionOptionModifiedAudience = Partial<
  Omit<QuestionOption, 'questionOptionAudiences'> & {
    questionOptionAudiences: { audience: Audience }[];
  }
>;

export const QUESTION_TYPE_DISPLAY_NAMES: {
  [type in QUESTION_TYPE]: string;
} = {
  [QUESTION_TYPE.GABOR_GRANGER]: 'Gabor-Granger',
  [QUESTION_TYPE.IDEA_PRESENTER]: 'Idea Presenter',
  [QUESTION_TYPE.MATRIX]: 'Matrix',
  [QUESTION_TYPE.MULTIPLE_CHOICE]: 'Multiple Choice',
  [QUESTION_TYPE.NUMBER]: 'Number',
  [QUESTION_TYPE.OPEN_ENDED]: 'Open Ended',
  [QUESTION_TYPE.RANKING]: 'Ranking',
  [QUESTION_TYPE.SCALE]: 'Scale',
  [QUESTION_TYPE.DATE]: 'Date',
};

export function apiDataToFormData({
  question,
  questions,
}: {
  question?: Question;
  questions: Question[];
}): QuestionFormData {
  const isMatrix = question?.questionTypeId === QUESTION_TYPE.MATRIX;

  const options = question
    ? question.options.map((option) => {
        return apiOptionToFormOption({ option, questions });
      })
    : [
        apiOptionToFormOption(),
        apiOptionToFormOption(),
        apiOptionToFormOption(),
        apiOptionToFormOption(),
      ];

  const matrixOptions =
    question?.matrixOptions.map((matrixOption) => {
      return apiMatrixOptionToFormOption({ matrixOption, questions });
    }) ?? [];

  const concepts =
    question?.concepts?.map(
      ({
        audience: conceptAudiences,
        description,
        id,
        isActive,
        media,
        preserved,
      }) => {
        return apiOptionToFormOption({
          option: {
            dataUrl: media,
            id,
            isActive,
            preserved,
            questionOptionAudiences: conceptAudiences.map((audience) => {
              return { audience };
            }),
            title: description,
          },
          questions,
        });
      },
    ) ?? [];

  let questionTypeId = question?.questionTypeId ?? null;
  if (isIdeaPresenterQuestion(question)) {
    questionTypeId = QUESTION_TYPE.IDEA_PRESENTER;
  } else if (questionTypeId === QUESTION_TYPE.NUMBER) {
    // We currently display "Number" question types as "Open Ended" questions in the survey builder.
    // If we get any "Number" questions we need to force them to open end so they display properly.
    questionTypeId = QUESTION_TYPE.OPEN_ENDED;
  }

  const voxpopmeProjectIdFeature = question?.questionFeatures?.find((qf) => {
    return qf.feature.code === QUESTION_FEATURE.VOXPOPME_FEATURE_1;
  });

  let optionType: ReactSelectValue<OptionType> = {
    label: 'Text',
    value: 'text',
  };
  if (question?.contentTypeId === OPTION_TYPE.IMAGE) {
    optionType = { label: 'Image', value: 'image' };
  } else if (voxpopmeProjectIdFeature) {
    optionType = { label: 'Audio / Video', value: 'audio-video' };
  } else if (
    question?.questionTypeId === QUESTION_TYPE.NUMBER ||
    isFeatureEnabled(question, QUESTION_FEATURE.NUMBER_TYPE)
  ) {
    optionType = { label: 'Number', value: 'number' };
  } else if (question?.constraint?.errorMessage === 'Must be a valid email.') {
    optionType = { label: 'Email', value: 'email' };
  } else if (
    question?.constraint?.errorMessage === 'Must be a valid zip code.'
  ) {
    optionType = { label: 'Zip code', value: 'zip' };
  }

  return {
    concepts,
    constraint: apiConstraintToFormConstraint({ question }),
    description: question?.description ?? null,
    features: apiFeaturesToFormFeatures({ question, questions }),
    gaborGrangerSettings: getGaborGrangerSettingsFormData(question),
    isActive: question?.isActive ?? true,
    label: question?.label ?? null,
    optionType,
    options: isMatrix ? matrixOptions : options,
    questionType: question
      ? QUESTION_TYPE_OPTIONS.find(({ value }) => {
          return value === questionTypeId;
        }) || null
      : null,
    // The API options are what we display under the "Statements" heading for a matrix.
    statements: isMatrix ? options : [],
    title: question?.title ?? '',
    voxpopmeConfig: {
      projectId: voxpopmeProjectIdFeature?.regex ?? '',
      responseRequired: !!question?.questionFeatures?.find((qf) => {
        return qf.feature.code === QUESTION_FEATURE.VOXPOPME_FEATURE_2;
      }),
    },
  };
}

function apiConstraintToFormConstraint({
  question,
}: {
  question: Question | undefined;
}) {
  const constraint: QuestionFormData['constraint'] = {
    range: {
      enabled: false,
      end: '',
      start: '',
    },
  };

  if (
    question?.constraint?.range &&
    question.constraint.errorMessage !== 'Must be a valid email.' &&
    question.constraint.errorMessage !== 'Must be a valid zip code.'
  ) {
    constraint.range.enabled = true;
    constraint.range.end = question.constraint.range.end ?? '';
    constraint.range.start = question.constraint.range.start ?? '';
  }

  return constraint;
}

function apiFeaturesToFormFeatures({
  question,
  questions = [],
}: {
  question?: Question;
  questions?: Question[];
}): QuestionFormData['features'] {
  const isMatrix = question?.questionTypeId === QUESTION_TYPE.MATRIX;

  const audiences = questionAudiencesToAudiences(question?.questionAudiences);

  const { carryForwardFeature, carryForwardQuestion } = getCarryForwardQuestion(
    { question, questions },
  );
  const carryForwardConfig: QuestionFormData['features']['carryForward'] = {
    enabled: isFeatureEnabled(question, CARRY_FORWARD_CODES),
    matrixOption: null,
    question: carryForwardQuestion
      ? getQuestionOption({ question: carryForwardQuestion })
      : null,
    type:
      getCarryForwardTypeOptions(carryForwardQuestion).find(({ value }) => {
        return value === carryForwardFeature?.feature.code;
      }) ?? null,
  };

  if (requiresCarryForwardMatrixOption(carryForwardConfig)) {
    const matrixOptions = carryForwardQuestion?.matrixOptions ?? [];
    const matchingMatrixOption = matrixOptions.find((option) => {
      return option.id === carryForwardFeature?.matrixOptionId;
    });
    carryForwardConfig.matrixOption = matchingMatrixOption
      ? {
          label: matchingMatrixOption.title,
          value: {
            id: matchingMatrixOption.id,
            title: matchingMatrixOption.title,
          },
        }
      : CARRY_FORWARD_MATRIX_ANY_OPTION;
  }

  const pipeConceptQuestion = questions.find(({ id }) => {
    return id === question?.displayedConcept?.id;
  });

  return {
    carryForward: carryForwardConfig,
    displayLogic: {
      enabled: audiences.length > 0,
      values: getFormDisplayLogic({ audiences, questions }),
    },
    displayOptionDescription: question?.displayOptionDescription ?? true,
    displayXOfY: {
      enabled: isFeatureEnabled(question, QUESTION_FEATURE.DISPLAY_X_OF_Y),
      value: getFeatureStartValue(question, QUESTION_FEATURE.DISPLAY_X_OF_Y),
    },
    monadicBlock: getFormMonadicBlock({ question, questions }),
    multipleOptionSelections: isFeatureEnabled(
      question,
      QUESTION_FEATURE.MULTIPLE_OPTION_SELECTIONS,
    ),
    multipleResponse: {
      enabled: isFeatureEnabled(question, QUESTION_FEATURE.MULTIPLE_RESPONSE),
      max: isFeatureEnabled(
        question,
        QUESTION_FEATURE.MULTIPLE_RESPONSE_UPPER_LIMIT,
      )
        ? getFeatureStartValue(
            question,
            QUESTION_FEATURE.MULTIPLE_RESPONSE_UPPER_LIMIT,
          )
        : '',
      min: isFeatureEnabled(
        question,
        QUESTION_FEATURE.MULTIPLE_RESPONSE_LOWER_LIMIT,
      )
        ? getFeatureStartValue(
            question,
            QUESTION_FEATURE.MULTIPLE_RESPONSE_LOWER_LIMIT,
          )
        : '',
    },
    openMatrix: {
      enabled: isFeatureEnabled(question, QUESTION_FEATURE.OPEN_MATRIX),
      inverted: isFeatureEnabled(
        question,
        QUESTION_FEATURE.OPEN_MATRIX_INVERTED,
      ),
      scroll: isFeatureEnabled(question, QUESTION_FEATURE.OPEN_MATRIX_SCROLL),
    },
    pipeConcept: {
      enabled: isFeatureEnabled(question, QUESTION_FEATURE.PIPE_CONCEPT),
      question: pipeConceptQuestion
        ? getQuestionOption({ question: pipeConceptQuestion })
        : null,
    },
    quotas: {
      enabled: !!(
        question?.questionQuotas && question.questionQuotas.length > 0
      ),
      values: question?.questionQuotas?.map((quota) => {
        const logicalModifier =
          QUESTION_QUOTA_LOGICAL_MODIFIER_OPTIONS.find(({ value }) => {
            return value === quota.logicalModifier;
          }) || null;
        const options = compact(
          quota.questionOptions.map(({ id }) => {
            const option = question.options.find((option) => option.id === id);
            if (!option) {
              return;
            }

            const optionOption = getOptionOption({
              index: option.sort,
              option,
            });

            return {
              ...optionOption,
              value: option.sort,
            };
          }),
        );

        return {
          isQualityDisqualification: quota.disqualificationType === 'QUALITY',
          logicalModifier,
          optionQuota: quota.numberNeeded,
          options,
          quotaId: quota.id,
        };
      }) ?? [getEmptyQuota()],
    },
    randomizeStatements:
      isMatrix &&
      isFeatureEnabled(question, QUESTION_FEATURE.RANDOMIZE_OPTIONS),
    randomizeMatrixOptions:
      isMatrix &&
      isFeatureEnabled(question, QUESTION_FEATURE.RANDOMIZE_MATRIX_OPTIONS),
    reverseOptions: isFeatureEnabled(
      question,
      QUESTION_FEATURE.REVERSE_OPTIONS,
    ),
    randomizeOptions:
      !isMatrix &&
      isFeatureEnabled(question, QUESTION_FEATURE.RANDOMIZE_OPTIONS),
    requiredSum: {
      enabled: isFeatureEnabled(question, QUESTION_FEATURE.REQUIRED_SUM),
      value: getFeatureStartValue(question, QUESTION_FEATURE.REQUIRED_SUM),
    },
    viewAllImages: isFeatureEnabled(question, QUESTION_FEATURE.VIEW_ALL_IMAGES),
    viewConcept: isFeatureEnabled(question, QUESTION_FEATURE.VIEW_CONCEPT),
  };
}

function getFormMonadicBlock({
  question,
  questions,
}: {
  question: Question | undefined;
  questions: Question[];
}) {
  const finalMonadicQuestion = findLast(questions, (q) => !!q.monadicId);
  const monadicPromptQuestion = getMonadicBlockPromptQuestion({ questions });

  // We only support one monadic block per survey right now, so if we found a question
  // with a monadic ID (indicating that a monadic block is present) AND we have a defined
  // current question (i.e. we're not adding a new question), we can enable the feature.
  if (
    monadicPromptQuestion &&
    question &&
    question.id === monadicPromptQuestion.id &&
    finalMonadicQuestion
  ) {
    const monadicStartQNumber = monadicPromptQuestion.sort;
    const monadicEndQNumber = finalMonadicQuestion.sort;

    if (
      monadicPromptQuestion &&
      question.sort <= monadicEndQNumber &&
      question.sort >= monadicPromptQuestion.sort
    ) {
      return {
        sequences: question.monadicBlockSequences ?? 1,
        enabled: true,
        end: monadicEndQNumber,
        start: monadicStartQNumber,
      };
    }
  }

  const questionNumber = getQuestionNumber({ question, questions });

  return {
    enabled: false,
    end: questionNumber,
    start: questionNumber,
    sequences: 1,
  };
}

export function apiMatrixOptionToFormOption({
  matrixOption,
  questions = [],
}: {
  matrixOption: MatrixOption;
  questions?: Question[];
}): QuestionFormOption {
  return apiOptionToFormOption({
    option: {
      anchored: matrixOption.isAnchored,
      exclusive: matrixOption.isExclusive,
      id: matrixOption.id,
      isActive: matrixOption.isActive,
      isFreeTextOption: matrixOption.isFreeText,
      title: matrixOption.title,
      weight: matrixOption.weight,
    },
    questions,
  });
}

function apiOptionFeaturesToFormOptionFeatures({
  option,
  questions = [],
}: {
  option?: QuestionOptionModifiedAudience;
  questions?: Question[];
} = {}): QuestionFormOption['features'] {
  const audiences = questionAudiencesToAudiences(
    option?.questionOptionAudiences,
  );

  return {
    anchored: option?.anchored ?? false,
    displayLogic: {
      enabled: audiences.length > 0,
      values: getFormDisplayLogic({ audiences, questions }),
    },
    exclusive: option?.exclusive ?? false,
    freeText: option?.isFreeTextOption ?? false,
    isActive: option?.isActive ?? true,
    preserved: option?.preserved ?? false,
    requireView: option?.viewRequired ?? false,
    useWeight:
      (option && option.weight !== null && option.weight !== undefined) ??
      false,
  };
}

export function apiOptionToFormOption({
  option,
  questions = [],
}: {
  option?: QuestionOptionModifiedAudience;
  questions?: Question[];
} = {}): QuestionFormOption {
  return {
    carryOverParentId: option?.carryOverParentId ?? null,
    features: apiOptionFeaturesToFormOptionFeatures({
      option,
      questions,
    }),
    id: option?.id ?? null,
    isActive: option?.isActive ?? true,
    image: { data: option?.dataUrl ?? null, preview: null },
    preserved: option?.preserved ?? false,
    scale: {
      labelHigh: option?.scaleHighLabel ?? '',
      labelLow: option?.scaleLowLabel ?? '',
      labelMiddle: option?.scaleMiddleLabel ?? '',
      rangeMax: option?.rangeMax ?? '',
      rangeMin: option?.rangeMin ?? '',
      rangeStep: option?.rangeStep ?? '',
      type:
        SCALE_TYPES.find(({ value }) => {
          return value === option?.scaleTypeId;
        }) || null,
      unit:
        SCALE_UNITS.find(({ value }) => {
          return value === option?.scaleUnitId;
        }) || null,
    },
    title: getOptionTitle({ option, withFallback: false }),
    weight: option?.weight ?? null,
  };
}

function copyAndAddAdditionalOptions({
  min,
  options,
}: {
  min: number;
  options: QuestionFormOption[];
}): QuestionFormOption[] {
  let newOptions: QuestionFormOption[] = [];

  if (options.length > 0) {
    newOptions = [...options];
  }

  if (newOptions.length >= min) {
    return newOptions;
  }

  const numOptionsToAdd = min - newOptions.length;
  for (let i = 0; i < numOptionsToAdd; i++) {
    newOptions.push(apiOptionToFormOption());
  }

  return newOptions;
}

export function formDataToApiData({
  formData,
  survey,
}: {
  formData: QuestionFormDataValidated;
  survey: Survey;
}): CreateQuestionBody {
  const {
    concepts,
    constraint,
    description,
    features,
    gaborGrangerSettings,
    isActive,
    label,
    options,
    questionType,
    statements,
    title,
    voxpopmeConfig,
  } = formData;
  const optionType = formData.optionType.value;
  const questionTypeId = questionType.value;
  // "Idea Presenter" is not a valid API question type. We cast these to "Multiple Choice".
  const questionTypeIdToSave =
    questionTypeId === QUESTION_TYPE.IDEA_PRESENTER
      ? QUESTION_TYPE.MULTIPLE_CHOICE
      : questionTypeId;
  const isMatrix = questionTypeId === QUESTION_TYPE.MATRIX;
  const { apiFeatures, topLevelAttributes } = formFeaturesToApiFeatures({
    features,
    hasConcepts: concepts.length > 0,
    optionType,
    questionTypeId,
    voxpopmeConfig,
  });

  // The API stores the statements for matrices in the "options" field.
  const apiMatrixOptions = isMatrix ? options : [];
  const apiOptions = isMatrix ? statements : options;

  // If a concept doesn't have a description, we'll add one for the user in the
  // format: Concept {conceptNumber}
  let conceptNumber = 0;

  return {
    concepts: compact(
      concepts.map((concept) => {
        if (!concept.image) {
          return null;
        }

        conceptNumber = conceptNumber + 1;

        const conceptToSave: CreateQuestionBody['concepts'][0] = {
          audience: concept.features.displayLogic.enabled
            ? getApiDataForDisplayLogic({
                displayLogic: concept.features.displayLogic.values,
              })
            : [],
          description: concept.title || `Concept ${conceptNumber}`,
          isActive: concept.features.isActive,
          media: concept.image.data,
          preserved: concept.preserved,
        };
        if (concept.id) {
          conceptToSave.id = concept.id;
        }

        return conceptToSave;
      }),
    ),
    constraint: formConstraintToApiConstraint({ constraint, optionType }),
    contentTypeId: internalOptionTypeToAPI(optionType),
    displayedConcept: null,
    description,
    features: apiFeatures,
    gaborGrangerSettings:
      questionTypeId === QUESTION_TYPE.GABOR_GRANGER &&
      canUseGaborGrangerSettings(survey)
        ? {
            format: gaborGrangerSettings.format.value,
            formatCustomText:
              gaborGrangerSettings.format.value === 'CUSTOM'
                ? gaborGrangerSettings.formatCustomText || null
                : null,
            increment: gaborGrangerSettings.increment,
            max: gaborGrangerSettings.max,
            min: gaborGrangerSettings.min,
            objective: gaborGrangerSettings.objective.value,
            unitDecimals: gaborGrangerSettings.unitDecimals,
          }
        : undefined,
    isActive,
    label,
    matrixOptions: compact(
      apiMatrixOptions.map((matrixOption) => {
        if (!matrixOption.title) {
          return null;
        }

        const apiFeatures = formOptionFeaturesToApiFeatures({
          features,
          isMatrixOption: true,
          option: matrixOption,
          optionType,
          questionType,
        });

        return {
          id: matrixOption.id,
          isActive: apiFeatures.isActive,
          isAnchored: apiFeatures.anchored,
          isExclusive: apiFeatures.exclusive,
          isFreeText: apiFeatures.isFreeTextOption,
          title: matrixOption.title,
          weight: matrixOption.weight,
        };
      }),
    ),
    multipleChoiceLimit: null,
    options: compact(
      apiOptions.map((option) => {
        if (!isOptionPopulated(option)) {
          return null;
        }

        return {
          ...formOptionFeaturesToApiFeatures({
            features,
            isStatement: questionType.value === QUESTION_TYPE.MATRIX,
            option,
            optionType,
            questionType,
          }),
          ...formOptionScalesToApiScales({ option, questionType }),
          carryOverParentId: option.carryOverParentId,
          dataUrl: option.image.data,
          description: optionType === 'image' ? option.title : '',
          id: option.id,
          isMatrixMultipleChoice: false,
          title: option.title,
          weight: option.weight,
        };
      }),
    ),
    questionTypeId: questionTypeIdToSave,
    surveyId: `${survey.id}`,
    title,
    ...topLevelAttributes,
  };
}

function formConstraintToApiConstraint({
  constraint,
  optionType,
}: {
  constraint: QuestionFormDataValidated['constraint'];
  optionType: OptionType;
}) {
  if (optionType === 'email') {
    return {
      errorMessage: 'Must be a valid email.',
      regex:
        '^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$',
    };
  }

  if (optionType === 'zip') {
    return {
      errorMessage: 'Must be a valid zip code.',
      regex: '\\b\\d{5}\\b',
    };
  }

  if (constraint.range.enabled) {
    return {
      range: { end: constraint.range.end, start: constraint.range.start },
    };
  }

  return null;
}

function formFeaturesToApiFeatures({
  features,
  hasConcepts,
  optionType,
  questionTypeId,
  voxpopmeConfig,
}: {
  features: QuestionFormDataValidated['features'];
  hasConcepts: boolean;
  optionType: OptionType;
  questionTypeId: QUESTION_TYPE;
  voxpopmeConfig: QuestionFormDataValidated['voxpopmeConfig'];
}): {
  apiFeatures: CreateQuestionBody['features'];
  topLevelAttributes: Partial<CreateQuestionBody>;
} {
  const apiFeatures: CreateQuestionBody['features'] = [];
  const topLevelAttributes: Partial<CreateQuestionBody> = {};

  if (optionType === 'image') {
    topLevelAttributes.displayOptionDescription =
      features.displayOptionDescription;

    if (features.viewAllImages) {
      apiFeatures.push({ code: QUESTION_FEATURE.VIEW_ALL_IMAGES });
    }
  }

  if (features.carryForward.enabled) {
    const carryForwardApiFeature: CreateQuestionBody['features'][number] = {
      code: features.carryForward.type.value,
      enumValue: features.carryForward.question.value.id,
    };

    if (requiresCarryForwardMatrixOption(features.carryForward)) {
      carryForwardApiFeature.enumRegex = 'required text for carry forward';
      carryForwardApiFeature.matrixOptionId =
        features.carryForward.matrixOption.value.id;
    }

    apiFeatures.push(carryForwardApiFeature);
  }

  if (features.displayLogic.enabled) {
    topLevelAttributes.questionAudiences = getApiDataForDisplayLogic({
      displayLogic: features.displayLogic.values,
    });
  }

  if (features.multipleOptionSelections) {
    apiFeatures.push({ code: QUESTION_FEATURE.MULTIPLE_OPTION_SELECTIONS });
  }

  if (features.monadicBlock.enabled) {
    apiFeatures.push({ code: 'MONADIC' });
    topLevelAttributes.monadicBlockEnd = features.monadicBlock.end;
    topLevelAttributes.monadicBlockStart = features.monadicBlock.start;
    topLevelAttributes.monadicBlockSequences = features.monadicBlock.sequences;
  }

  if (
    features.multipleResponse.enabled ||
    questionTypeId === QUESTION_TYPE.MATRIX ||
    questionTypeId === QUESTION_TYPE.RANKING ||
    questionTypeId === QUESTION_TYPE.SCALE
  ) {
    apiFeatures.push({ code: QUESTION_FEATURE.MULTIPLE_RESPONSE });

    if (features.multipleResponse.min !== '') {
      apiFeatures.push({
        code: QUESTION_FEATURE.MULTIPLE_RESPONSE_LOWER_LIMIT,
      });
      topLevelAttributes.multipleChoiceLowerLimit =
        features.multipleResponse.min;
    }

    if (features.multipleResponse.max !== '') {
      apiFeatures.push({
        code: QUESTION_FEATURE.MULTIPLE_RESPONSE_UPPER_LIMIT,
      });
      topLevelAttributes.multipleChoiceUpperLimit =
        features.multipleResponse.max;
    }
  } else {
    apiFeatures.push({ code: QUESTION_FEATURE.SINGLE_RESPONSE });
  }

  if (optionType === 'number') {
    topLevelAttributes.questionTypeId = QUESTION_TYPE.NUMBER;
  }

  if (features.openMatrix.enabled) {
    apiFeatures.push({ code: QUESTION_FEATURE.OPEN_MATRIX });

    if (features.openMatrix.inverted) {
      apiFeatures.push({ code: QUESTION_FEATURE.OPEN_MATRIX_INVERTED });
    }

    if (features.openMatrix.scroll) {
      apiFeatures.push({ code: QUESTION_FEATURE.OPEN_MATRIX_SCROLL });
    }
  }

  if (features.pipeConcept.enabled) {
    apiFeatures.push({ code: QUESTION_FEATURE.PIPE_CONCEPT });
    topLevelAttributes.displayedConcept =
      features.pipeConcept.question.value.id;
  }

  if (features.quotas.enabled) {
    topLevelAttributes.quotas = features.quotas.values.map((quota) => {
      return {
        disqualificationType: quota.isQualityDisqualification
          ? 'QUALITY'
          : 'NON_QUALITY',
        logicalModifier: quota.logicalModifier.value,
        optionQuota: quota.optionQuota === '' ? null : quota.optionQuota,
        options: quota.options.map(({ value }) => value),
        quotaId: quota.quotaId,
        quotaRelationId: null,
      };
    });
  }

  if (features.randomizeMatrixOptions) {
    apiFeatures.push({ code: QUESTION_FEATURE.RANDOMIZE_MATRIX_OPTIONS });
  }
  if (features.reverseOptions) {
    apiFeatures.push({ code: QUESTION_FEATURE.REVERSE_OPTIONS });
  }

  if (
    features.randomizeOptions ||
    (questionTypeId === QUESTION_TYPE.MATRIX && features.randomizeStatements)
  ) {
    apiFeatures.push({ code: QUESTION_FEATURE.RANDOMIZE_OPTIONS });
  }

  if (features.requiredSum.enabled) {
    apiFeatures.push({ code: QUESTION_FEATURE.REQUIRED_SUM });
    topLevelAttributes.requiredSum = features.requiredSum.value;
  }

  if (features.displayXOfY.enabled) {
    apiFeatures.push({ code: QUESTION_FEATURE.DISPLAY_X_OF_Y });
    topLevelAttributes.optionBlockSize = features.displayXOfY.value;
  }

  if (hasConcepts && features.viewConcept) {
    apiFeatures.push({ code: QUESTION_FEATURE.VIEW_CONCEPT });
  }

  if (optionType === 'audio-video') {
    apiFeatures.push({
      code: QUESTION_FEATURE.VOXPOPME_FEATURE_1,
      regex: voxpopmeConfig.projectId,
    });

    if (voxpopmeConfig.responseRequired) {
      apiFeatures.push({ code: QUESTION_FEATURE.VOXPOPME_FEATURE_2 });
    }
  }

  return { apiFeatures, topLevelAttributes };
}

function formOptionFeaturesToApiFeatures({
  features,
  isMatrixOption = false,
  isStatement = false,
  option,
  optionType,
  questionType,
}: {
  features: QuestionFormDataValidated['features'];
  isMatrixOption?: boolean;
  isStatement?: boolean;
  option: QuestionOptionValidated;
  optionType: OptionType;
  questionType: QuestionFormDataValidated['questionType'];
}): {
  anchored: boolean;
  exclusive: boolean;
  isActive: boolean;
  isFreeTextOption: boolean;
  preserved: boolean;
  questionOptionAudiences: CreateQuestionAudience[];
  viewRequired: boolean;
} {
  const { displayLogic } = option.features;
  const apiFeatures = {
    anchored: false,
    exclusive: false,
    isActive: true,
    isFreeTextOption: false,
    preserved: false,
    questionOptionAudiences: displayLogic.enabled
      ? getApiDataForDisplayLogic({ displayLogic: displayLogic.values })
      : [],
    viewRequired: false,
  };

  OPTION_FEATURES.forEach(({ apiName, available, featureName }) => {
    const isOptionAvailable = available({
      features,
      isMatrixOption,
      isStatement,
      optionType,
      questionType,
    });

    if (isOptionAvailable) {
      // TODO: Address compilation error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      apiFeatures[apiName] = option.features[featureName];
    }
  });

  return apiFeatures;
}

function formOptionScalesToApiScales({
  option,
  questionType,
}: {
  option: QuestionOptionValidated;
  questionType: QuestionFormDataValidated['questionType'];
}): Partial<{
  rangeMax: number;
  rangeMin: number;
  rangeStep: number;
  scaleHighLabel: string;
  scaleLowLabel: string;
  scaleMiddleLabel: string;
  scaleTypeId: number;
  scaleUnitId: number;
}> {
  const {
    labelHigh: scaleHighLabel,
    labelLow: scaleLowLabel,
    labelMiddle: scaleMiddleLabel,
    rangeMax,
    rangeMin,
    rangeStep,
    type,
    unit,
  } = option.scale;

  if (questionType.value === QUESTION_TYPE.SCALE) {
    return {
      rangeMax,
      rangeMin,
      rangeStep,
      scaleHighLabel,
      scaleLowLabel,
      scaleMiddleLabel,
      scaleTypeId: type.value,
      scaleUnitId: unit.value,
    };
  }

  if (questionType.value === QUESTION_TYPE.GABOR_GRANGER) {
    return {
      rangeMax,
      rangeMin,
      rangeStep,
    };
  }

  return {};
}

/**
 * Used to generate a list of questions to be used in an "optgroup" like construct in React-Select.
 */
export function generateQuestionsSection<QuestionType extends Question>({
  questions,
  title,
}: {
  questions: ReactSelectValue<QuestionType>[];
  title: string;
}): QuestionGroup<QuestionType> {
  return {
    label: title,
    options: orderBy(questions, (q) => q.value.sort).map((question) => {
      return getQuestionOption({ question: question.value });
    }),
  };
}

export function getCarryForwardQuestion({
  question,
  questions,
}: {
  question: Question | undefined;
  questions: Question[];
}): {
  carryForwardFeature: QuestionFeature | undefined;
  carryForwardQuestion: Question | undefined;
} {
  const carryForwardFeature = question?.questionFeatures?.find(
    ({ feature }) => {
      return CARRY_FORWARD_CODES.includes(feature.code);
    },
  );

  return {
    carryForwardFeature,
    carryForwardQuestion: questions.find(({ id }) => {
      return id === carryForwardFeature?.enumValue;
    }),
  };
}

export function getConceptTitle({
  concept,
  index,
}: {
  concept: { description: string } | undefined;
  index?: number;
}): string {
  return (
    concept?.description ||
    `${index !== undefined ? `${index + 1}) ` : ''}(unlabeled)`
  );
}

export function getEmptyQuota(): Quota {
  return {
    isQualityDisqualification: false,
    logicalModifier: QUESTION_QUOTA_LOGICAL_MODIFIER_OPTIONS[0],
    optionQuota: '',
    options: [],
    quotaId: null,
  };
}

export function getErrorDisplayMapping(
  formData: QuestionFormData,
): Partial<Record<keyof QuestionFormData, string>> {
  if (formData.questionType?.value === QUESTION_TYPE.SCALE) {
    return { options: 'Scales' };
  }

  if (formData.questionType?.value === QUESTION_TYPE.GABOR_GRANGER) {
    return { options: 'Parameters' };
  }

  return { options: 'Options' };
}

export function getFeatureStartValue(
  question: Question | undefined,
  feature: QUESTION_FEATURE,
): number | '' {
  const questionFeature = question?.questionFeatures?.find(
    ({ feature: qFeature }) => qFeature.code === feature,
  );

  return questionFeature?.numberRange?.start ?? '';
}

export function getFormDataToDuplicate({
  formData,
  questions,
}: {
  formData: QuestionFormData;
  questions: Question[];
}): QuestionFormData {
  const newFormData = cloneDeep(formData);

  newFormData.concepts = newFormData.concepts.map((concept) => {
    return stripFormOptionOfIDs(concept);
  });

  newFormData.statements = newFormData.statements.map((statement) => {
    return stripFormOptionOfIDs(statement);
  });

  newFormData.features.monadicBlock = getFormMonadicBlock({
    // This represents the "current question" which will be the new, unsaved question
    // once we're done getting form data to duplicate.
    question: undefined,
    questions,
  });

  newFormData.options = newFormData.options.map((option) => {
    return stripFormOptionOfIDs(option);
  });

  return newFormData;
}

export function canUseGaborGrangerSettings(survey: Survey): boolean {
  return survey.generateNewExports;
}

function getGaborGrangerSettingsFormData(question?: Question) {
  return question?.gaborGrangerSettings
    ? {
        format:
          GABOR_GRANGER_FORMAT_OPTIONS.find(
            (option) => option.value === question?.gaborGrangerSettings?.format,
          ) ?? GABOR_GRANGER_FORMAT_DOLLARS_OPTION,
        formatCustomText: question.gaborGrangerSettings.formatCustomText ?? '',
        increment: Number(question.gaborGrangerSettings.increment),
        max: Number(question.gaborGrangerSettings.max),
        min: Number(question.gaborGrangerSettings.min),
        objective:
          GABOR_GRANGER_OBJECTIVE_OPTIONS.find(
            (option) =>
              option.value === question?.gaborGrangerSettings?.objective,
          ) ?? GABOR_GRANGER_OBJECTIVE_MAX_OPTION,
        unitDecimals: question.gaborGrangerSettings.unitDecimals,
      }
    : ({
        format: GABOR_GRANGER_FORMAT_DOLLARS_OPTION,
        formatCustomText: '',
        increment: '',
        max: '',
        min: '',
        objective: GABOR_GRANGER_OBJECTIVE_MAX_OPTION,
        unitDecimals: 2,
      } satisfies QuestionFormData['gaborGrangerSettings']);
}

function getMediaOptionValidationError(media: MediaWithPreview) {
  return media.preview
    ? 'Please wait for the upload to finish.'
    : 'Please upload an image.';
}

export function getPreviousQuestions({
  question,
  questions,
}: {
  question: Question | undefined;
  questions: Question[];
}): Question[] {
  const questionNumber = getQuestionNumber({ question, questions });

  return questions.filter((potentialQuestion) => {
    return (
      potentialQuestion.id !== question?.id &&
      (potentialQuestion.sort < questionNumber ||
        potentialQuestion.isDemographic)
    );
  });
}

export function getQuestionNumber({
  isWaitingForUnlock = false,
  question,
  questions,
}: {
  isWaitingForUnlock?: boolean;
  question: Question | undefined;
  questions: Question[];
}): number {
  const nonDemographicQuestions = questions.filter((q) => !q.isDemographic);

  // If we're waiting for an unlock to happen, that means we're at the end stage of
  // saving a question. Returning "questions.length" here prevents a flash of an
  // incremented question number while we're waiting to unblock navigation and update
  // the route with the ID of the newly saved question.
  if (isWaitingForUnlock || !question) {
    return nonDemographicQuestions.length + 1;
  }

  return question.sort;
}

export function getValuesForOptionTypeChange({
  currentValues,
  newOptionType,
}: {
  currentValues: QuestionFormData;
  newOptionType: ReactSelectValue<OptionType>;
}): QuestionFormData {
  const newValues = cloneDeep(currentValues);

  newValues.optionType = newOptionType;

  newValues.options.forEach((option) => {
    // We reset the features on an option type change to avoid the issue of trying to figure
    // out which features from the previous option type apply to the new option type. If
    // this experience is a problematic UX for our customers, we could revisit but we should
    // gather data to support that increased complexity before implementing.
    option.features = apiOptionFeaturesToFormOptionFeatures();
  });

  return newValues;
}

export function getValuesForQuestionTypeChange({
  currentValues,
  isAdmin,
  newQuestionType,
  question,
  questions,
}: {
  currentValues: QuestionFormData;
  isAdmin: boolean;
  newQuestionType: ReactSelectValue<QUESTION_TYPE>;
  question: Question | undefined;
  questions: Question[];
}): QuestionFormData {
  const newValues = cloneDeep(currentValues);

  // We use the statements for Matrix questions when copying current
  // options during a question type switch.
  const existingOptions =
    currentValues.questionType?.value === QUESTION_TYPE.MATRIX
      ? currentValues.statements
      : currentValues.options;

  newValues.constraint = apiConstraintToFormConstraint({ question: undefined });
  newValues.questionType = newQuestionType;
  newValues.concepts = [];
  newValues.statements = [];
  newValues.options = [];

  // We reset the features on a question type change to avoid the issue of trying to figure
  // out which features from the previous question type apply to the new question type. If
  // this experience is a problematic UX for our customers, we could revisit but we should
  // gather data to support that increased complexity before implementing.
  newValues.features = apiFeaturesToFormFeatures({
    question,
    questions,
  });

  const nextOptionTypes = getOptionTypes({
    isAdmin,
    questionType: newQuestionType.value,
  });

  if (
    shouldForceOptionTypeToText(newQuestionType.value) ||
    // If the user previously changed the option type, we don't want to change it back to
    // text here. The user may have went to create a Multiple Choice Image question and then
    // decided to make it a Ranking Image question. We don't want to change the option type
    // back to text and then the user has to update it again.
    (!newValues.optionType &&
      shouldDefaultOptionTypeToText(newQuestionType.value)) ||
    // The current option type might not be valid for the new question type. For instance, "Audio / Video"
    // is an open-ended option type, but it doesn't exist for Multiple Choice questions.
    !nextOptionTypes.find(({ value }) => newValues.optionType?.value === value)
  ) {
    newValues.optionType = { label: 'Text', value: 'text' };
  }

  if (newQuestionType.value === QUESTION_TYPE.IDEA_PRESENTER) {
    newValues.concepts = [apiOptionToFormOption(), apiOptionToFormOption()];
    newValues.options = [
      apiOptionToFormOption({ option: { title: 'Ready to continue' } }),
    ];
  }

  if (newQuestionType.value === QUESTION_TYPE.MATRIX) {
    newValues.statements = copyAndAddAdditionalOptions({
      min: 4,
      options: existingOptions,
    });

    // We always start with 4 empty options for Matrix questions.
    newValues.options = copyAndAddAdditionalOptions({
      min: 4,
      options: [],
    });
  }

  if (newQuestionType.value === QUESTION_TYPE.RANKING) {
    newValues.features.multipleResponse.enabled = true;
  }

  if (
    newQuestionType.value === QUESTION_TYPE.MULTIPLE_CHOICE ||
    newQuestionType.value === QUESTION_TYPE.RANKING
  ) {
    newValues.options = copyAndAddAdditionalOptions({
      min: 4,
      options: existingOptions,
    });
  }

  // Gabor-Granger questions should only ever have one option.
  if (newQuestionType.value === QUESTION_TYPE.GABOR_GRANGER) {
    newValues.options = [apiOptionToFormOption()];
  }

  // We start with one scale for scale questions because scales take up a lot of vertical space.
  if (newQuestionType.value === QUESTION_TYPE.SCALE) {
    newValues.options = copyAndAddAdditionalOptions({
      min: 1,
      options: existingOptions,
    });
  }

  return newValues;
}

export function hasConfiguredQuotas(
  quotas: QuestionFormData['features']['quotas']['values'],
): boolean {
  return quotas.some((quota) => {
    const hasNeededQuotaValue =
      quota.logicalModifier?.value === 'at_least' ||
      quota.logicalModifier?.value === 'at_most'
        ? !!quota.optionQuota
        : true;

    return (
      quota.logicalModifier && hasNeededQuotaValue && quota.options.length > 0
    );
  });
}

export function internalOptionTypeToAPI(optionType: OptionType | undefined) {
  return optionType === 'image' ? OPTION_TYPE.IMAGE : OPTION_TYPE.TEXT;
}

export function isDemographicQuestion(question: {
  isDemographic: boolean;
  isStandard: boolean;
}): boolean {
  return question.isStandard || question.isDemographic;
}

export function isFeatureEnabled(
  question: Question | undefined,
  feature: QUESTION_FEATURE | QUESTION_FEATURE[],
): boolean {
  const features = isArray(feature) ? feature : [feature];

  return question
    ? !!question.questionFeatures?.find(({ feature }) =>
        features.includes(feature.code),
      )
    : false;
}

export function isIdeaPresenterQuestion(
  question:
    | {
        concepts?: unknown[];
        conceptTestMedia?: unknown[];
        questionTypeId: number;
      }
    | undefined,
): boolean {
  return question
    ? question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE &&
        (question.concepts ?? question.conceptTestMedia ?? []).length > 1
    : false;
}

export function isOptionPopulated(
  option: Pick<QuestionFormOption, 'image' | 'scale' | 'title'>,
): boolean {
  return !!(
    option.title ||
    option.image.data ||
    some(option.scale, (scaleProperty) => !!scaleProperty)
  );
}

/**
 * Partitions the provided array of questions into demographic questions and custom survey questions.
 */
export function partitionQuestionsForDemographics(
  questions: ReactSelectValue<Question>[],
): [ReactSelectValue<Question>[], ReactSelectValue<Question>[]] {
  return partition(questions, ({ value }) => {
    return isDemographicQuestion(value);
  });
}

export function requiresCarryForwardMatrixOption(
  carryForward: QuestionFormData['features']['carryForward'],
): boolean {
  return (
    carryForward.question?.value.questionTypeId === QUESTION_TYPE.MATRIX &&
    (carryForward.type?.value === QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED ||
      carryForward.type?.value === QUESTION_FEATURE.CARRY_FORWARD_SELECTED)
  );
}

export function shouldDefaultOptionTypeToText(
  questionType: QUESTION_TYPE | undefined,
): boolean {
  return (
    questionType === QUESTION_TYPE.MATRIX ||
    questionType === QUESTION_TYPE.MULTIPLE_CHOICE ||
    questionType === QUESTION_TYPE.RANKING ||
    questionType === QUESTION_TYPE.SCALE ||
    questionType === QUESTION_TYPE.OPEN_ENDED
  );
}

export function shouldForceOptionTypeToText(
  questionType: QUESTION_TYPE | undefined,
): boolean {
  return (
    questionType === QUESTION_TYPE.GABOR_GRANGER ||
    questionType === QUESTION_TYPE.IDEA_PRESENTER
  );
}

function stripFormOptionOfIDs(option: QuestionFormOption): QuestionFormOption {
  const newOption = cloneDeep(option);

  newOption.id = null;

  return newOption;
}

export function supportsRangeConstraint({
  formData,
}: {
  formData: QuestionFormData;
}) {
  const questionType = formData.questionType?.value;

  if (
    questionType === QUESTION_TYPE.MATRIX ||
    questionType === QUESTION_TYPE.MULTIPLE_CHOICE
  ) {
    return formData.options.some((option) => option.features.freeText);
  }

  if (questionType === QUESTION_TYPE.OPEN_ENDED) {
    return formData.optionType?.value === 'number';
  }

  return false;
}

function validateCarryForward(
  carryForward: QuestionFormData['features']['carryForward'],
): FormikErrors<QuestionFormData['features']['carryForward']> | undefined {
  const { matrixOption, question, type } = carryForward;
  const errors: FormikErrors<QuestionFormData['features']['carryForward']> = {};

  if (!question) {
    errors.question = 'Please select a question.';
  } else if (requiresCarryForwardMatrixOption(carryForward) && !matrixOption) {
    errors.matrixOption = 'Required';
  }

  if (!type) {
    errors.type = 'Please select a type.';
  }

  return isEmpty(errors) ? undefined : errors;
}

function validateConcepts({
  formData,
  isEdit,
}: {
  formData: QuestionFormData;
  isEdit: boolean;
}): FormikErrors<QuestionFormData['options'][0]>[] | undefined {
  if (!formData.questionType) {
    return;
  }

  const conceptErrors: FormikErrors<QuestionFormData['options']['values']>[] =
    [];
  formData.concepts.forEach((concept, i) => {
    // Only two concepts are required (on creation) so we'll only show an error for the first
    // two concepts if they don't yet have an image. However, if the user entered in text
    // for a concept, we'll warn them that they need an image as well.
    if (!concept.image.data && (isEdit || i < 2 || concept.title)) {
      conceptErrors.push({
        title: getMediaOptionValidationError(concept.image),
      });
    } else {
      conceptErrors.push({});
    }
  });

  const hasErrors = conceptErrors.some((errors) => !isEmpty(errors));

  return hasErrors ? conceptErrors : undefined;
}

function validateConstraint({ formData }: { formData: QuestionFormData }) {
  if (
    !formData.constraint.range.enabled ||
    !supportsRangeConstraint({ formData })
  ) {
    return;
  }

  const rangeErrors: FormikErrors<QuestionFormData['constraint']['range']> = {};

  const end = formData.constraint.range.end;
  const start = formData.constraint.range.start;

  if (start === '' || end === '') {
    rangeErrors.start = start === '' ? 'Required' : '';
    rangeErrors.end = end === '' ? 'Required' : '';
  } else if (start > end) {
    rangeErrors.start = 'Min cannot be greater than max';
  }

  const hasFeatureError = some(rangeErrors, (error) => !!error);

  return hasFeatureError ? { range: rangeErrors } : undefined;
}

function validateDisplayXOfY({
  displayXOfY,
  options,
  questionType,
  statements,
}: {
  displayXOfY: QuestionFormData['features']['displayXOfY'];
  options: QuestionFormData['options'];
  questionType: QUESTION_TYPE | undefined;
  statements: QuestionFormData['statements'];
}): FormikErrors<QuestionFormData['features']['displayXOfY']> | undefined {
  const { value } = displayXOfY;
  const errors: FormikErrors<QuestionFormData['features']['displayXOfY']> = {};

  if (value === '') {
    errors.value = 'Please provide a value.';
  } else {
    const checkStatements = questionType === QUESTION_TYPE.MATRIX;
    const arrToCheck = checkStatements ? statements : options;

    if (value < 1) {
      errors.value = 'Must be at least 1.';
    } else if (value > arrToCheck.length) {
      errors.value = `Cannot be greater than number of ${
        checkStatements ? 'statements' : 'options'
      }.`;
    }
  }

  return isEmpty(errors) ? undefined : errors;
}

function validateFeatures({
  formData,
  lastQuestionNumber,
}: {
  formData: QuestionFormData;
  lastQuestionNumber: number;
}): FormikErrors<QuestionFormData['features']> | undefined {
  const questionType = formData.questionType?.value;
  const {
    carryForward,
    displayLogic,
    displayXOfY,
    monadicBlock,
    multipleResponse,
    pipeConcept,
    quotas,
    requiredSum,
  } = formData.features;
  const featureErrors: FormikErrors<QuestionFormData>['features'] = {};

  if (carryForward.enabled) {
    featureErrors.carryForward = validateCarryForward(carryForward);
  }

  if (displayLogic.enabled) {
    const displayLogicErrors = validateDisplayLogic(displayLogic.values);
    featureErrors.displayLogic = displayLogicErrors
      ? { values: displayLogicErrors }
      : undefined;
  }

  if (monadicBlock.enabled) {
    featureErrors.monadicBlock = validateMonadicBlock({
      monadicBlock,
      lastQuestionNumber,
    });
  }

  if (multipleResponse.enabled) {
    featureErrors.multipleResponse = validateMultipleResponse({
      multipleResponse,
      options: formData.options,
    });
  }

  if (pipeConcept.enabled) {
    featureErrors.pipeConcept = validatePipeConcept(pipeConcept);
  }

  if (
    questionType &&
    [QUESTION_TYPE.MULTIPLE_CHOICE, QUESTION_TYPE.RANKING].includes(
      questionType,
    ) &&
    quotas.enabled
  ) {
    const quotasErrors = validateQuotas(quotas.values);
    featureErrors.quotas = quotasErrors ? { values: quotasErrors } : undefined;
  }

  if (requiredSum.enabled) {
    featureErrors.requiredSum = validateRequiredSum({ requiredSum });
  }

  if (displayXOfY.enabled) {
    featureErrors.displayXOfY = validateDisplayXOfY({
      displayXOfY,
      options: formData.options,
      questionType,
      statements: formData.statements,
    });
  }

  const hasFeatureError = some(featureErrors, (error) => !!error);

  return hasFeatureError ? featureErrors : undefined;
}

function validateGaborGrangerSettings({
  formData,
}: {
  formData: QuestionFormData;
}): FormikErrors<QuestionFormData['gaborGrangerSettings']> | undefined {
  const { format, formatCustomText, increment, min, max, unitDecimals } =
    formData.gaborGrangerSettings;
  const gaborGrangerSettingsErrors: FormikErrors<QuestionFormData>['gaborGrangerSettings'] =
    {};

  if (min !== '' && max !== '' && max <= min) {
    gaborGrangerSettingsErrors.max = 'Must be greater than min';
  }

  if (min === '') {
    gaborGrangerSettingsErrors.min = 'Required';
  }

  if (max === '') {
    gaborGrangerSettingsErrors.max = 'Required';
  }

  if (increment === '') {
    gaborGrangerSettingsErrors.increment = 'Required';
  } else if (increment <= 0) {
    gaborGrangerSettingsErrors.increment = 'Must be greater than 0';
  }

  if (format.value === 'CUSTOM' && !formatCustomText) {
    gaborGrangerSettingsErrors.formatCustomText = 'Required';
  }

  if (unitDecimals === '') {
    gaborGrangerSettingsErrors.unitDecimals = 'Required';
  } else if (unitDecimals < 0) {
    gaborGrangerSettingsErrors.unitDecimals =
      'Must be greater than or equal to 0';
  }

  const hasError = some(gaborGrangerSettingsErrors, (error) => !!error);

  return hasError ? gaborGrangerSettingsErrors : undefined;
}

function validateStatements({
  formData,
  isEdit,
}: {
  formData: QuestionFormData;
  isEdit: boolean;
}): FormikErrors<QuestionFormData['statements'][0]>[] | undefined {
  const requiresImage = formData.optionType?.value === 'image';

  const allStatementsEmpty = formData.statements.every(({ image, title }) => {
    return requiresImage ? !image.data : !title;
  });
  if (
    formData.questionType?.value === QUESTION_TYPE.MATRIX &&
    allStatementsEmpty
  ) {
    return [
      {
        title: requiresImage
          ? getMediaOptionValidationError(formData.statements[0].image)
          : 'Please provide a statement.',
      },
    ];
  }

  const statementErrors: FormikErrors<QuestionFormData['statements'][0]>[] = [];
  formData.statements.forEach((statement) => {
    const statementError: FormikErrors<QuestionFormData['statements'][0]> = {};
    const hasError = requiresImage ? !statement.image.data : !statement.title;

    if (statement.weight !== undefined && statement.weight !== null) {
      statementError.weight = 'Please do not leave weight undefined.';
    }

    if (isEdit) {
      if (hasError) {
        statementError.title = requiresImage
          ? getMediaOptionValidationError(statement.image)
          : 'Please provide a statement.';
      }
    } else if (requiresImage && !statement.image.data && statement.title) {
      statementError.title = getMediaOptionValidationError(statement.image);
    }

    statementErrors.push(statementError);
  });

  const hasError = statementErrors.some((errors) => !isEmpty(errors));

  return hasError ? statementErrors : undefined;
}

function validateMonadicBlock({
  monadicBlock,
  lastQuestionNumber,
}: {
  monadicBlock: QuestionFormData['features']['monadicBlock'];
  lastQuestionNumber: number;
}): FormikErrors<QuestionFormData['features']['monadicBlock']> | undefined {
  const { end, start, sequences } = monadicBlock;
  const errors: FormikErrors<QuestionFormData['features']['monadicBlock']> = {};

  if (end === '') {
    errors.end = 'Required';
  } else if (end < 1) {
    errors.end = 'Min: 1';
  } else if (end > lastQuestionNumber) {
    errors.end = `Max: ${lastQuestionNumber}`;
  } else if (end < start) {
    errors.end = `Min: ${start}`;
  }

  if (sequences === '') {
    errors.sequences = 'Required';
  } else if (sequences < 1) {
    errors.sequences = 'Min: 1';
  }

  return isEmpty(errors) ? undefined : errors;
}

function validateMultipleResponse({
  multipleResponse,
  options,
}: {
  multipleResponse: QuestionFormData['features']['multipleResponse'];
  options: QuestionFormData['options'];
}): FormikErrors<QuestionFormData['features']['multipleResponse']> | undefined {
  const { max, min } = multipleResponse;
  const errors: FormikErrors<QuestionFormData['features']['multipleResponse']> =
    {};

  if (min !== '') {
    if (min < 1) {
      errors.min = 'Must be at least 1.';
    } else if (min > options.length) {
      errors.min = 'Cannot be greater than number of options.';
    }
  }

  if (max !== '') {
    if (max < 1) {
      errors.max = 'Must be at least 1.';
    } else if (max > options.length) {
      errors.max = 'Cannot be greater than number of options.';
    }
  }

  if (min !== '' && max !== '' && min > max) {
    errors.min = 'Cannot be greater than max.';
  }

  return isEmpty(errors) ? undefined : errors;
}

function validateOptions({
  formData,
  isEdit,
  survey,
}: {
  formData: QuestionFormData;
  isEdit: boolean;
  survey: Survey;
}): FormikErrors<QuestionFormData['options'][number]>[] | undefined {
  if (!formData.questionType || !formData.optionType) {
    return;
  }

  const requiresOptions = [
    QUESTION_TYPE.GABOR_GRANGER,
    QUESTION_TYPE.MATRIX,
    QUESTION_TYPE.MULTIPLE_CHOICE,
    QUESTION_TYPE.RANKING,
    QUESTION_TYPE.SCALE,
  ].includes(formData.questionType.value);
  if (
    !requiresOptions ||
    (formData.questionType.value === QUESTION_TYPE.GABOR_GRANGER &&
      canUseGaborGrangerSettings(survey))
  ) {
    return;
  }

  // Matrix questions only use images on statements, not options.
  const requiresImage =
    formData.optionType?.value === 'image' &&
    formData.questionType.value !== QUESTION_TYPE.MATRIX;

  if (formData.questionType.value === QUESTION_TYPE.SCALE) {
    const scaleOptionErrors: FormikErrors<QuestionFormOption>[] = [];
    formData.options.forEach((option) => {
      const errors: FormikErrors<QuestionFormOption> = {};
      const scaleError: FormikErrors<QuestionFormOption>['scale'] = {};

      if (requiresImage && !option.image.data) {
        errors.title = getMediaOptionValidationError(option.image);
      }

      if (!option.scale.type) {
        scaleError.type = 'Please choose a type.';
      }

      if (!option.scale.unit) {
        scaleError.unit = 'Please choose a unit.';
      }

      if (!option.scale.labelLow) {
        scaleError.labelLow = 'Please enter a label.';
      }

      if (!option.scale.labelHigh) {
        scaleError.labelHigh = 'Please enter a label.';
      }

      if (
        option.scale.rangeMax === '' ||
        Number.isNaN(Number(option.scale.rangeMax))
      ) {
        scaleError.rangeMax = 'Please enter a numeric max.';
      }

      if (
        option.scale.rangeMin === '' ||
        Number.isNaN(Number(option.scale.rangeMin))
      ) {
        scaleError.rangeMin = 'Please enter a numeric min.';
      }

      if (
        option.scale.rangeStep === '' ||
        Number.isNaN(Number(option.scale.rangeStep))
      ) {
        scaleError.rangeStep = 'Please enter a numeric step.';
      }

      if (!isEmpty(scaleError)) {
        errors.scale = scaleError;
      }

      scaleOptionErrors.push(errors);
    });

    const hasScaleOptionError = scaleOptionErrors.some(
      (errors) => !isEmpty(errors),
    );
    if (hasScaleOptionError) {
      return scaleOptionErrors;
    }
  } else if (formData.questionType.value === QUESTION_TYPE.GABOR_GRANGER) {
    const gaborErrors: FormikErrors<QuestionFormOption>[] = [];
    formData.options.forEach((option) => {
      const errors: FormikErrors<QuestionFormOption> = {};
      const gaborError: FormikErrors<QuestionFormOption>['scale'] = {};

      if (option.scale.rangeMax === '') {
        gaborError.rangeMax = 'Please enter a max.';
      }

      if (option.scale.rangeMin === '') {
        gaborError.rangeMin = 'Please enter a min.';
      }

      if (option.scale.rangeStep === '') {
        gaborError.rangeStep = 'Please enter a step.';
      }

      if (!isEmpty(gaborError)) {
        errors.scale = gaborError;
      }

      gaborErrors.push(errors);
    });

    const hasGaborError = gaborErrors.some((errors) => !isEmpty(errors));
    if (hasGaborError) {
      return gaborErrors;
    }
  } else {
    const allOptionsEmpty = formData.options.every(({ image, title }) => {
      return requiresImage ? !image.data : !title;
    });
    if (allOptionsEmpty) {
      return [
        {
          title: requiresImage
            ? getMediaOptionValidationError(formData.options[0].image)
            : 'Please provide an option.',
        },
      ];
    }

    const optionErrors: FormikErrors<QuestionFormData['options'][number]>[] =
      [];
    formData.options.forEach((option) => {
      const optionError: FormikErrors<QuestionFormData['options'][number]> = {};
      const hasError = requiresImage ? !option.image.data : !option.title;

      if (isEdit) {
        if (hasError) {
          optionError.title = requiresImage
            ? getMediaOptionValidationError(option.image)
            : 'Please provide an option.';
        }
      } else if (requiresImage && !option.image.data) {
        optionError.title = getMediaOptionValidationError(option.image);
      }

      optionErrors.push(optionError);
    });

    const hasOptionsError = optionErrors.some((errors) => !isEmpty(errors));

    return hasOptionsError ? optionErrors : undefined;
  }
}

function validatePipeConcept(
  pipeConcept: QuestionFormData['features']['pipeConcept'],
): FormikErrors<QuestionFormData['features']['pipeConcept']> | undefined {
  const errors: FormikErrors<QuestionFormData['features']['pipeConcept']> = {};

  if (!pipeConcept.question) {
    errors.question = 'Please select a question.';
  }

  return isEmpty(errors) ? undefined : errors;
}

export function validateQuestionFormData({
  formData,
  isEdit,
  lastQuestionNumber,
  survey,
}: {
  formData: QuestionFormData;
  isEdit: boolean;
  lastQuestionNumber: number;
  survey: Survey;
}): FormikErrors<QuestionFormData> {
  const errors: FormikErrors<QuestionFormData> = {};

  if (!formData.title) {
    errors.title = 'Please provide a title.';
  }

  if (!formData.optionType) {
    errors.optionType = 'Please select an option type.';
  }

  if (!formData.questionType) {
    errors.questionType = 'Please select an question type.';
  }

  errors.concepts = validateConcepts({ formData, isEdit });
  errors.statements = validateStatements({ formData, isEdit });
  errors.options = validateOptions({ formData, isEdit, survey });
  errors.features = validateFeatures({ formData, lastQuestionNumber });
  if (
    formData.questionType?.value === QUESTION_TYPE.GABOR_GRANGER &&
    canUseGaborGrangerSettings(survey)
  ) {
    errors.gaborGrangerSettings = validateGaborGrangerSettings({ formData });
  }
  errors.constraint = validateConstraint({ formData });
  errors.voxpopmeConfig = validateVoxpopmeConfig({ formData });

  const hasErrors = some(errors, (error) => !!error);

  return hasErrors ? errors : {};
}

export function validateQuotas(
  quotas: QuestionFormData['features']['quotas']['values'],
): FormikErrors<QuestionQuota>[] | undefined {
  const quotasErrors: FormikErrors<QuestionQuota>[] = [];

  quotas.forEach((quota) => {
    const quotaErrors: FormikErrors<QuestionQuota> = {};

    if (
      (quota.logicalModifier?.value === 'at_most' ||
        quota.logicalModifier?.value === 'at_least') &&
      quota.optionQuota === ''
    ) {
      quotaErrors.optionQuota = 'Please provide a number.';
    }

    if (quota.options.length === 0) {
      quotaErrors.options = 'Please select options.';
    }

    quotasErrors.push(quotaErrors);
  });

  const hasQuotasErrors = quotasErrors.some((error) => !isEmpty(error));

  return hasQuotasErrors ? quotasErrors : undefined;
}

function validateRequiredSum({
  requiredSum,
}: {
  requiredSum: QuestionFormData['features']['requiredSum'];
}): FormikErrors<QuestionFormData['features']['requiredSum']> | undefined {
  const { value } = requiredSum;
  const errors: FormikErrors<QuestionFormData['features']['requiredSum']> = {};

  if (value === '') {
    errors.value = 'Please provide a value.';
  } else if (value < 1) {
    errors.value = 'Must be greater than 0.';
  }

  return isEmpty(errors) ? undefined : errors;
}

function validateVoxpopmeConfig({ formData }: { formData: QuestionFormData }) {
  const errors: FormikErrors<QuestionFormData['voxpopmeConfig']> = {};

  if (
    formData.questionType?.value === QUESTION_TYPE.OPEN_ENDED &&
    formData.optionType?.value === 'audio-video' &&
    !formData.voxpopmeConfig.projectId
  ) {
    errors.projectId = 'Please enter a value';
  }

  return isEmpty(errors) ? undefined : errors;
}
